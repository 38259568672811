import gql from "graphql-tag";

export const GET_MANAGEMENT_HOSTS_LIGHT = gql`
  query GET_MANAGEMENT_HOSTS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $orderBy: ManagementHostOrderBy,
    $direction: Direction,
    $networkId: UUID,
    $deviceId: String,
  ){
  	management{
	    hosts(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        orderBy: $orderBy,
        direction: $direction,
        networkId: $networkId,
        deviceId: $deviceId,
    	){
    		pageInfo{
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
    		results{
    			id
					macAddr
					displayName
    		}
	    }
  	}
  }`;

export const GET_MANAGEMENT_HOSTS = gql`
  query GET_MANAGEMENT_HOSTS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $online: Boolean,
    $lastSeenFrom: DateTime,
		$lastSeenTo: DateTime,
    $vlanId: UUID,
		$category: [HostCategoryType],
    $orderBy: ManagementHostOrderBy,
    $direction: Direction,
    $networkId: UUID,
    $organizationSlug: ShortString,
    $medias: [ConnectionMediaType!],
    $deviceId: String,
    $badConnection: Boolean,
    $hasPromptAuth: Boolean,
    $hasOpenPorts: Boolean
    $hasIpEvents: Boolean
    $isBlocked: Boolean,
		$hostTypes: [HostTypeEnum!]
  ){
  	management{
	    hosts(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        online: $online,
        lastSeenFrom: $lastSeenFrom,
				lastSeenTo: $lastSeenTo,
				category: $category,
        orderBy: $orderBy,
        direction: $direction,
        vlanId: $vlanId,
        networkId: $networkId,
        medias: $medias,
        organizationSlug: $organizationSlug,
        deviceId: $deviceId,
        badConnection: $badConnection,
        hasPromptAuth:$hasPromptAuth,
        hasOpenPorts: $hasOpenPorts,
        hasIpEvents: $hasIpEvents,
        isBlocked: $isBlocked,
				hostTypes: $hostTypes,
    	){
    		pageInfo{
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
    		results{
    			id
					macAddr
					displayName
					organization {
						name
						slug
						id
					}
          countOpenPorts
          countMonitoredPorts
          device{
            id
            deviceId
            location
          }
          hostType
          podNetwork{
            id
            pod{
              id
              currentVersion{
              recipe {
                id
                displayName
                }
              }
            }
          }
          latestPortScan {
            id
            portCount
            hostIsUp
          }
          countPacketLogs
					created
					deviceType
					isBlocked
          hasPromptAuth
          authorizations{
            network{
              id
              location
            }
            vlans{
              lastConnection {
                lastSeen
                badConnection
              }
              status
              vlan {
                id
                vid
                name
                accessPolicy
                management
              }
            }
          }
					lastConnection {
            id
					  isOnline
					  lastSeen
					  media
					  band
					  badConnection
					  network {
					  	id
					    location
					  }
					  ssid {
              id
					    name
					  }
		        authorizationStatus
		        authenticationStatus
		        trustedPairingStatus
		        trustedPairingDetails
		        context {
	            deviceLocation
	            deviceId
	            hostIsDeviceSameNetwork
	            hostType
		        	portLabel
              ip
		        }
					  vlanAccess {
					    status
					    vlan {
					    	id
					    	vid
					      name
					      accessPolicy
					      management
					    }
					  }
					}
    		}
	    }
  	}
  }`;

export const GET_MANAGEMENT_HOST = gql`
  query GET_MANAGEMENT_HOST(
    $hostId: UUID!,
    $askForAuthorizations: Boolean!,
  ){
    management{
      host(hostId: $hostId) {
      	id
	      hostname
	      displayName
	      category
        categoryOther
	      macAddr
	      provider
	      usage
        isBlocked
        lastBlocked
        blockedReason
	      authFailures{
	        count
	      }
        countOpenPorts
        countMonitoredPorts
        device{
          id
          deviceId
          location
        }
        portForwards {
          id
          vlanAccess{
            ip
            vlan{
              id
              vid
              name
              accessPolicy
              network{
                id
                location
                router{
                  id
                }
              }
            }
          }
          enabled
          internalPort
          externalPort
          protocol
          memo
          allowFrom
          restrictAccess
          warning
          lastChange{
            updatedOn
            updatedBy{
              id
              firstname
              lastname
              email
            }
          }
        }
	      created
        latestPortScan {portCount}
        countPacketLogs
	      countPacketCaptures(status: COMPLETED)
	      countPortScans(status: COMPLETED)
	      countAllIncidents: countIncidents
	      countOpenIncidents: countIncidents(status: OPEN)
	      organization {
	      	id
	      	name
	      	slug
	        networks {
	          id
	          countAllConnections: countConnections(hostId: $hostId)
	          location
	        }
	      }
        hostType
        podNetwork{
          id
          pod{
            id
            currentVersion{
            recipe {
              id
              displayName
              }
            }
          }
        }
	      lastConnection{
          id
	        startTime
	        endTime
          lastSeen
	        downloadKilobytes
	        uploadKilobytes
	        authenticationStatus
	        isOnline
          media
	        device{
	        	id
	        	deviceId
	        	location
	        }
	        context{
	        	deviceId
            deviceLocation
            hostType
	        }
					network{
						id
            location
            organization{
              id
              slug
              name
            }
            router{
              id
            }
					}
	        vlanAccess{
	          ip
            status
	          vlan{
	          	id
	          	vid
	          	name
	          	management
	          }
	        }
	      }	      
        authorizations @include(if: $askForAuthorizations){
          network {
            location
            id
            countAllConnections: countConnections(hostId: $hostId)
            organization{
              id
              slug
              name
              host(hostId: $hostId) {
                id
                alias
                hostname
                displayName
                category
                categoryOther
                provider
                usage
                isBlocked
                lastBlocked
                blockedReason
              }
            }
          }
          vlans {
            vlan {
              id
              vid
              name
              description
              isolatePorts
              type
              accessPolicy
              isReservedVid
            }
            ip
            changedByName
            changedOn
            status
            lastConnection{
              startTime
              endTime
              isOnline
              band
              media
              lastSeen
              authenticationStatus
              attemptNumber
              ssid{
                id
                name
              }
              context{
                deviceLocation
                deviceId
                portLabel
              }
              device{
                ports{
                  id
                  portNumber
                  usage
                }
              }
              vlanAccess{
                status
                ip
                vlan {
                  id
                  vid
                  name
                  accessPolicy
                  management
                }
              }
              vlan{
                id
                vid
                name
                description
                isolatePorts
                accessPolicy
              }
              uploadKilobytes
              downloadKilobytes
            }
          }
        }
	   	}
	  }
  }`;

export const GET_MANAGEMENT_HOST_STATS = gql`
  query GET_MANAGEMENT_HOST_STATS(
    $slug: String,
    $hostId: UUID!,
    $fromDateMonth: Date!,
    $fromDateWeek: Date!,
    $toDate: Date!,
  ){
    organization(slug: $slug) {
      id
      totalStats(
        fromDate: $fromDateMonth,
        toDate: $toDate,
        hostId: $hostId,
      ){
        uploadKilobytes
        downloadKilobytes
      }
      dailyStats(
        hostId: $hostId,
        fromDate: $fromDateWeek,
        toDate: $toDate,
      ) {
        day
        hosts
        uploadKilobytes
        downloadKilobytes
      }
    }
  }
`;