module.exports = {
  "generic.access": `Access`,
  "generic.account": `Account`,
  "generic.accounts": `Accounts`,
  "generic.accounts.filterBy": `By account`,
  "generic.accountsLocation.filterBy": `By account & location`,
  "generic.action": `Action`,
  "generic.action.filterBy": `By action`,
  "generic.action.CREATE": `Create profile`,
  "generic.action.DISABLE": `Disable profile`,
  "generic.action.UPDATE": `Update profile`,

  "generic.actions": `Actions`,
  "generic.actions.filterBy": `By actions`,
  "generic.actions.ADD_TO_INVENTORY": `Add to inventory...`,
  "generic.actions.ADD_TO_INVENTORY.disabled.reason": `Please select only appliances with status "new", "retourned" or "defective".`,
  "generic.actions.ADD_TO_PROJECT": `Add to project`,
  "generic.actions.ALLOW": `Allow`,
  "generic.actions.BACKUP": `Backup`,  
  "generic.actions.BLOCK": `Block`,
  "generic.actions.CANCEL": `Cancel`,
  get "generic.actions.CLAIM"() {return this["generic.claim"]},
  "generic.actions.CLEAR": `Clear`,
  "generic.actions.CLOSE": `Close`,
  "generic.actions.CLOSE.warningAlready": `All selected items are already closed`,
  "generic.actions.DELETE": `Delete`,
  "generic.actions.DISABLE": `Disable`,
  "generic.actions.DISCARD": `Discard...`,
  "generic.actions.DISCARD.disabled.reason": `Please select only appliances without location.`,
  "generic.actions.DISCARD_DEFINITIVELY": `Discard...`,
  "generic.actions.ENABLE": `Enable`,
  "generic.actions.ERASE": `Erase`,
  "generic.actions.ERASE.desc": `The selected disks will be erased, all data will be lost.{linebreak}Are you sure to do this operation?`,
  "generic.actions.FACTORY_RESET": `Factory reset...`,
  get "generic.actions.FIRMWARE_UPDATE"() {return this["generic.type.FIRMWARE_UPDATE"]},
  "generic.actions.FORMAT": `Format`,
  "generic.actions.FORMAT.FULL": `Full format`,
  "generic.actions.FORMAT.QUICK": `Quick format`,
  "generic.actions.FORMAT_FOR_SERVICES": `Format for services`,
  "generic.actions.FORMAT_FOR_SERVICES.desc": `The selected disks will be formatted for services.{linebreak}Are you sure to do this operation?`,
  "generic.actions.IMMEDIATE_UPDATE": `Immediate update`,
  "generic.actions.INIT_FOR_SERVICES": `Init for services`,
  "generic.actions.INVENTORY_TRANSFER_TO": `Edit location...`,
  "generic.actions.INVENTORY_TRANSFER_TO.disabled.reason": `Please select only appliances with "In stock" status.`,
  "generic.actions.LOG": `Log`,
  "generic.actions.LOG_AND_ALLOW": `Log & allow`,
  "generic.actions.LOG_AND_BLOCK": `Log & block`,
  "generic.actions.TRANSFER_TOinventory.disabled.reason": `Please select only appliances with "In stock" status.`,
  get "generic.actions.NEW"() {return this["generic.inventoryStatus.NEW"]},
  "generic.actions.OPEN": `Open`,
  "generic.actions.OPEN.warningAlready": `All selected items are already opened`,
  "generic.actions.PORT_SCAN_QUICK": `Quick port scan`,
  "generic.actions.PORT_SCAN_FULL": `Full port scan`,
  "generic.actions.REBOOT": `Reboot`,
  "generic.actions.REINIT": `Reinitialize`,
  "generic.actions.REMOVE_FROM_PROJECT": `Removed from project`,
  "generic.actions.REMOVE_FROM_NETWORK": `Removed from network`,
  "generic.actions.REPLACE_ROUTER": `Replace router`,
  "generic.actions.RESCAN_PORTS": `Rescan ports`,
  "generic.actions.RESOLVE": `Resolve`,
  "generic.actions.RESOLVE.warningAlready": `All selected items are already resolved`,
  "generic.actions.RETURN_TO_OPERATOR": `Return to operator`,
  "generic.actions.SCHEDULE_UPDATE": `Schedule update`,
  "generic.actions.START": `Start`,
  "generic.actions.STOP": `Stop`,
  "generic.actions.SWITCH_ENVIRONMENT": `Switch API endpoint`,
  "generic.actions.SWITCH_ENVIRONMENT.disabled.reason": `Please select only appliances with "In stock" status.`,
  "generic.actions.TRANSFER_TO": `Transfer to...`,
  "generic.actions.TRANSFER_TO.disabled.reason": `Please select only appliances without location.`,
  "generic.actions.UNINSTALL": `Uninstall`,
  "generic.actions.UNMOUNT": `Unmount`,
  "generic.actions.WHITELIST_ADD": `Whitelist Wi-Fi`,
  "generic.actions.WHITELIST_REMOVE": `Mark as rogue`,
  "generic.actions.WIFI_SURVEY": `Start Wi-Fi survey`,

  get "generic.actionsProfile.ACCESSES"() {return this["generic.action.UPDATE"]},
  get "generic.actionsProfile.CREATE"() {return this["generic.action.CREATE"]},
  get "generic.actionsProfile.DISABLE"() {return this["generic.action.DISABLE"]},
  get "generic.actionsProfile.INFO"() {return this["generic.action.UPDATE"]},
  get "generic.actionsProfile.INFO_AND_ACCESSES"() {return this["generic.action.UPDATE"]},
  "generic.activated": `Activated`,
  "generic.deactivated": `Deactivated`,
  "generic.active": `Active`,
  "generic.inactive": `Inactive`,
  "generic.activity": `Activity`,
  "generic.added": `Added`,
  "generic.add": `Add`,
  "generic.addDescription": `Add a description`,
  "generic.all": `All`,
  "generic.all.fem": `All`,
  "generic.any": `Any`,
  "generic.any.fem": `Any`,
  "generic.apiEndpoint": `API endpoint`,
  "generic.appliance": `Appliance`,
  "generic.appliances": `Appliances`,
  "generic.appliances.count": `{count, plural, =0 {No appliance} one {1 appliance} other {{count} appliances}}`,
  "generic.appliances.count.error": `{count, plural, =0 {No appliance concerned} one {1 appliance concerned} other{{count} appliances concerned}}`,
  "generic.appliances.count.success": `{count, plural, =0 {No appliance involved} one {Applies to an appliance} other{Applies to {count} appliances}}`,
  "generic.appliances.connected.count": `{count, plural, =0 {No connected appliance} one {1 connected appliance} other {{count} connected appliances}}`,
  "generic.appliances.offline": `Offline appliance(s)`,
  "generic.devices.connected.count": `{count, plural, =0 {No connected device} one {1 connected device} other {{count} connected devices}}`,
  "generic.architecture": `Architecture`,
  "generic.archived": `Archived`,
  "generic.areYouSure": `Are you sure ?`,
  "generic.areYouSure.cancelForm": `The form has been modified, are you sure you want to cancel?`,
  "generic.assignOn": `Assign on`,
  "generic.assignTo": `Assign to`,
  "generic.noAssignee": `No assignee`,
  "generic.attention": `Attention`,
  "generic.authentication": `Authentication`,
  "generic.authentication.AUTHENTICATED": `Authenticated`,
  "generic.authentication.NOT_AUTHENTICATED": `Authentication failure`,
  "generic.authentication.NOT_REQUIRED": `Not required`,
  get "generic.authentication.undefined"() {return this["generic.na"]},

  "generic.authentication.subtitle": `Indicates whether a device has been authenticated or not with legitimate credentials. SentinelC appliances required to be authenticated to be fully operational.`,
  "generic.authorization": `Permission`,
  "generic.authorization.PENDING": `Pending permission`,
  "generic.authorization.ENABLED": `Enabled`,
  "generic.authorization.DISABLED": `Disabled`,
  "generic.authorization.UPDATED": `Updated`,
  "generic.authorization.NO_AUTH": `No permission`,
  "generic.authorized": `{count, plural, =0 {0 authorized} one {1 authorized}  other {{count} authorized}}`,
  "generic.authorized.simple": `Authorized`,
  "generic.authorized.by": `Authorized by`,
  "generic.auto": `Auto`,
  "generic.automaticAuthorization": `Automatic authorization`,
  "generic.automaticAuthorization.notRequired": `No authorization required`,
  "generic.automaticDeny": `Automatic deny`,
  "generic.automaticIgnore": `Automatic ignore`,
  "generic.available": `Available`,
  "generic.back": `Back`,
  "generic.backHome": `Back home`,
  "generic.backOffice": `Admin console`,
  "generic.backOffice.action": `Go to Admin console`,
  "generic.backOffice.home": `Admin console`,
  "generic.badConnection": `Bad connection`,
  "generic.badConnection.FALSE": `No`,
  "generic.badConnection.TRUE": `Yes`,
  "generic.badConnection.filterBy": `Is bad connection`,
  "generic.badConnection.long": `Bad connection - This connection is not allowed, please verify your port setup.`,
  "generic.block": `Block`,
  "generic.blocked": `Blocked`,
  "generic.blockedSingle": `Blocked`,
  "generic.blockedSingle.FALSE": `No`,
  "generic.blockedSingle.TRUE": `Yes`,
  "generic.blockedSingle.filterBy": `Blocked`,
  "generic.blockedSince.date": `Blocked since {date}`,
  "generic.blockedSince.date.on": `Blocked since {date} on {location}`,
  "generic.by": `by`,
  "generic.By": `By`,
  "generic.By.name": `By name`,
  "generic.chart": `Chart`,
  "generic.claim": `Claim`,
  "generic.claimAppliances": `Claim appliances`,
  "generic.clients": `Clients`,
  "generic.clients.explanation": `Including clients devices, SentinelC appliances and Services (app.).`,
  "generic.close": `Close`,
  "generic.closed": `Closed`,
  "generic.completion": `Completion`,
  "generic.configuration": `Configuration`,
  "generic.configure": `Configure`,
  "generic.configuring": `Configuring`,
  "generic.connected": `Connected`,
  "generic.connectedCount": `{count, plural, =0 {None connected} one {1 connected}  other {{count} connected}}`,
  "generic.connectedPlural": `Connected`,
  "generic.connectedSince": `Connected since`,
  "generic.connectedTo": `Connected to`,
  "generic.connection": `Connection`,
  "generic.connection.ONLINE": `Online`,
  "generic.connection.OFFLINE": `Offline`,
  "generic.connections": `{count, plural, =0 {Connection} one {Connection} other {Connections}}`,
  "generic.connection.none.withFilter": `No connection with this combination of filters.`,
  "generic.connectionType": `Connection type`,
  "generic.connectionType.WIFI": `Wi-Fi`,
  "generic.connectionType.ETHERNET": `Ethernet`,
  "generic.connectionType.VIRTUAL": `Virtual`,
  "generic.containers": `Containers`,
  "generic.copied": `Copied in the clipboard`,
  "generic.createdOn.male": `Created on`,
  "generic.createdOn.female": `Created on`,
  "generic.createdBy.male": `Created by`,
  "generic.current": `(current)`,
  "generic.custom": `Custom`,
  "generic.customParameters": `Custom parameters`,
  "generic.dashboard": `Application`,
  "generic.date": `Date`,
  "generic.date.today": `Today`,
  "generic.date.yesterday": `Yesterday`,
  "generic.day.mon": `Monday`,
  "generic.day.tue": `Tuesday`,
  "generic.day.wed": `Wednesday`,
  "generic.day.thu": `Thursday`,
  "generic.day.fri": `Friday`,
  "generic.day.sat": `Staturday`,
  "generic.day.sun": `Sunday`,
  "generic.day.fromBeginning": `From the beginning`,
  "generic.days": `{count, plural, =0 {0 day} one {1 day} other {{count} days}}`,
  "generic.default": `Default`,
  "generic.defective": `Defective`,
  "generic.deleted": `Deleted`,
  "generic.deletedWifi": `Deleted Wi-Fi`,
  "generic.denied": `{count, plural, =0 {None denied} one {1 denied} other {{count} denied}}`,
  "generic.denied.simple": `Denied`,
  "generic.description": `Description`,
  "generic.details": `Details`,
  "generic.detectedSince": `Detected since`,
  "generic.devices": `{count, plural, =0 {No device} one {1 device} other {{count} devices}}`,
  "generic.devicesState": `Appliances state`,
  "generic.direction": `Direction`,
  "generic.direction.filterBy": `By direction`,
  "generic.direction.INGRESS": `Inbound`,
  "generic.direction.EGRESS": `Outbound`,
  "generic.disabled": `Disabled`,
  get "generic.disabled.fem"() {return this["generic.disabled"]},
  "generic.disks.count": `{count, plural, =0 {No disk} one {1 disk} other{{count} disks}}`,
  "generic.disks.count.error": `{count, plural, =0 {No disk concerned} one {1 disk concerned} other{{count} disks concerned}}`,
  "generic.disks.count.success": `{count, plural, =0 {No disk involved} one {Applies to a disk} other{Applies to {count} disks}}`,
  "generic.dnsRequest": `{count, plural, =0 {No DNS request} one {1 DNS request} other {{count} DNS requests}}`,
  "generic.dnsRequest.plural": `DNS requests`,
  "generic.documentation": `Documentation`,
  "generic.down": `Down`,
  "generic.accounts.down": `Down`,
  "generic.accounts.down.explanation": `All appliances of the account are offline`,
  "generic.locations.down": `Down`,
  "generic.locations.down.explanation": `All appliances of the location are offline`,
  "generic.devices.down": `Offline`,
  "generic.duration": `Duration`,
  "generic.edit": `Edit`,
  "generic.empty": `empty`,
  "generic.enabled": `Enabled`,
  get "generic.enabled.fem"() {return this["generic.enabled"]},
  "generic.endtime": `End time`,
  "generic.error": `Error`,
  "generic.errors": `{count, plural, =0 {no error} one {an error} other {{count} errors}}`,  
  "generic.errors.occured": `{count, plural, =0 {No error occured} one {An error occured} other {{count} errors occured}}`,  
  "generic.events": `Events`,
  "generic.existingNetwork": `Existing Wi-Fi`,
  "generic.feedback": `Send us feedback`,
  "generic.file.name": `File name`,
  "generic.file.size": `File size`,
  "generic.filters": `Filter(s)`,
  "generic.firmware": `Firmware`,
  "generic.firmwareInstalled": `Firmware installed`,
  "generic.firmwareStatus": `Firmware status`,
  "generic.firmwareUpdateSchedule": `Firmware update schedule for appliances`,
  "generic.firmwareUpdateType": `Firmware update schedule`,
  "generic.firmwareUpdateTime": `Firmware update schedule time`,
  "generic.firmwareRelease": `Release channel`,
  "generic.firmwareRelease.filterBy": `By release channel`,
  "generic.firstseen": `First seen`,
  "generic.hardware": `Hardware`,
  "generic.hostTypes": `Device type`,
  "generic.hostTypes.filterBy": `By type`,
  "generic.hostTypes.DEVICE": `SentinelC appliances`,
  "generic.hostTypes.DEFAULT": `Client devices`,
  "generic.hostTypes.POD": `SentinelC services`,
  "generic.lastseen": `Last seen`,
  "generic.functional": `Functionnal`,
  "generic.filterBy": `Filter by`,
  "generic.flags": `Flags`,
  "generic.authentication.filterBy": `By authentication`,
  "generic.authorization.filterBy": `By authorization`,
  "generic.connection.filterBy": `By connection`,
  "generic.connectionType.filterBy": `By connection type`,
  "generic.firmware.filterBy": `By firmware`,
  "generic.firmwareStatus.filterBy": `By firmware`,
  "generic.period.filterBy": `By period`,
  "generic.potentialRogue.filterBy": `Potential rogue detected`,
  "generic.category.filterBy": `By category`,
  "generic.incident.filterBy": `By incident`,
  "generic.instant": `Instant`,
  "generic.state.filterBy": `By state`,
  "generic.status.filterBy": `By status`,
  "generic.permission.filterBy": `By permission`,
  "generic.level.filterBy": `By level`,
  "generic.operatingMode.filterBy": `By mode`,
  "generic.priority.filterBy": `By incident priority`,
  "generic.wifi.filterBy": `By Wi-Fi`,
  "generic.zone.filterBy": `By zone`,
  "generic.zoneType.filterBy": `By zone type`,
  "generic.type.filterBy": `By type`,
  "generic.period": `Period`,
  "generic.period.startTime.filterBy": `Period (scheduled time)`,
  "generic.potentialRogue": `Potential rogue`,
  get "generic.potentialRogue.FALSE"() {return this["generic.no"]},
  get "generic.potentialRogue.TRUE"() {return this["generic.yes"]},
  "generic.categories": `Categories`,
  "generic.category": `Category`,
  "generic.categoryType": `Category/Type`,
  "generic.incident": `Incident`,
  "generic.hostPorts.count.error": `{count, plural, =0 {No port concerned} one {1 port concerned} other{{count} ports concerned}}`,
  "generic.hostPorts.count.success": `{count, plural, =0 {No port involved} one {Applies to a port} other{Applies to {count} ports}}`,
  "generic.incidents": `{count, plural, =0 {No incident} one {incident} other{incidents}}`,
  "generic.incidents.count": `{count, plural, =0 {No incident} one {1 incident} other{{count} incidents}}`,
  "generic.incidents.count.error": `{count, plural, =0 {No incident concerned} one {1 incident concerned} other{{count} incidents concerned}}`,
  "generic.incidents.count.success": `{count, plural, =0 {No incident involved} one {Applies to an incident} other{Applies to {count} incidents}}`,
  "generic.incidents.byState": `Incidents by state`,
  "generic.incidents.byCategory": `Incidents by category`,
  "generic.incidents.viewAll": `View all incidents`,
  "generic.incidents.viewAll.open": `View all open incidents`,
  "generic.incidents.viewAll.openCount": `{count, plural, =0 {No incident} one {See the opened incident} other{View all {count} opened incidents}}`,
  "generic.incidents.none": `No incident`,
  "generic.incidents.none.withFilter": `No incident matches this combination of filters.`,
  "generic.status": `Status`,
  "generic.statusDate": `Status date`,
  "generic.permission": `Permission`,
  "generic.permissions": `Permissions on zones/vlans`,
  "generic.permission.subtitle": `Indicates if the mac address of the device is authorized in this zone. Only applicable if the zone is restricted.`,
  "generic.permission.subtitle.connections": `Status of a device, when he tried the connection, indicating wheter or not it is authorized to connect to the network in which it wishes to connect.`,
  "generic.category.BOT": `Bot`,
  "generic.category.THREAT": `Threat`,
  "generic.category.NETWORK": `Network`,
  "generic.category.FIRMWARE": `Firmware`,
  "generic.category.SERVICE": `Service`,
  "generic.category.OPERATION": `Operation`,
  "generic.category.SURVEILLANCE": `Surveillance`,
  "generic.incident.BAD_CONNECTION": `Bad connection`,
  "generic.incident.MAX_AUTH_FAILURE": `Authentication failure`,
  "generic.category.MALWARE": `Malware`,
  "generic.incident.ROGUE_SSID": `Rogue Wi-Fi`,
  "generic.incident.POD_FAILURE": `Service failure`,
  "generic.incident.POD_SYSTEM_FAILURE": `Service system failure`,
  "generic.incident.LOW_DISK_SPACE": `Low space disk`,
  "generic.incident.NO_RADIO_SSID": `Wi-Fi network without radio`,
  "generic.incident.NETWORK_CONFLICT": `Network conflict`,
  "generic.incident.IP_INTERCEPTION": `IP interception`,
  "generic.incident.FAILED_UPDATE": `Firmware update failure`,
  "generic.incident.DEVICE_OFFLINE": `Offline appliance`,
  "generic.incident.DEVICE_VPN_OFFLINE": `Offline VPN`,
  "generic.incident.reportedon": `Reported on {date}`,
  "generic.incident.resolvedon": `Resolved on {date}`,
  "generic.incident.firstseenon": `First seen on {date}`,

  "generic.state.ACTIVE": `Active`,
  "generic.state.ALLOW": `Authorized`,
  "generic.state.AVAILABLE": `Available`,
  "generic.state.BAD_CONNECTION": `Bad connection`,
  "generic.state.BLOCKED": `Blocked`,
  "generic.state.CANCELLED": `Cancelled`,
  "generic.state.CLEAR_DISK": `Clear disk`,
  "generic.state.CLEAR_PENDING": `Clear pending`,
  "generic.state.CLEAR_IN_PROGRESS": `Clear in progress`,
  "generic.state.CLOSED": `Closed`,
  "generic.state.COMPLETED": `Completed`,
  "generic.state.CRITICAL": `Critical`,
  "generic.state.CRITICAL.plural": `Critical`,
  "generic.state.DEACTIVATED": `Deactivated`,
  "generic.state.DELETING": `Deleting`,
  "generic.state.DENY": `Denied`,
  "generic.state.DEPRECATED": `Deprecated`,
  "generic.state.DISABLED": `Disabled`,
  "generic.state.DUPLICATE": `Duplicate`,
  "generic.state.ENABLED": `Enabled`,
  "generic.state.ERASE_PENDING": `Erase pending`,
  "generic.state.ERASE_IN_PROGRESS": `Erase in progress`,
  "generic.state.ERROR": `Error`,
  "generic.state.FAILED": `Failed`,
  "generic.state.FORMAT_PENDING": `Format pending`,
  "generic.state.FORMAT_IN_PROGRESS": `Format in progress`,
  "generic.state.GOOD": `Good`,
  "generic.state.HIGH": `High`,
  "generic.state.HIGH.plural": `High`,
  "generic.state.IGNORE": `Ignored`,
  "generic.state.INACTIVE": `Inactive`,
  "generic.state.INSTALL_FAILED": `Install failed`,
  "generic.state.INSTALLING": `Installing`,
  "generic.state.INSTALLED": `Installed`,
  "generic.state.MISSING": `Missing`,
  "generic.state.MODERATE": `Moderate`,
  "generic.state.MODERATE.plural": `Moderate`,
  "generic.state.NONE": `Unknown`,
  "generic.state.NOT_AVAILABLE": `Not available`,
  "generic.state.OPEN": `Open`,
  "generic.state.PENDING": `Pending`,
  "generic.state.POD_FAILED": `Service failed`,
  "generic.state.POD_DEGRADED": `Degraded service`,
  "generic.state.PROMPT": `Waiting for permission`,
  "generic.state.READY": `Ready`,
  "generic.state.RESOLVED": `Resolved`,
  "generic.state.RUNNING": `Running`,
  "generic.state.STANDBY": `Standby`,
  "generic.state.STARTED": `Started`,
  "generic.state.STARTING": `Starting`,
  "generic.state.STOPPED": `Stopped`,
  "generic.state.STOPPING": `Stopping`,
  "generic.state.TASK_FAILED": `Task failed`,
  get "generic.state.UNKNOWN"() {return this["generic.unknown"]},
  "generic.state.UNFORMATTED": `Unformatted`,
  "generic.state.UNINSTALL_FAILED": `Uninstall failed`,
  "generic.state.UPDATING_PARAMETERS": `Updating parameters`,

  "generic.permission.ALLOW": `Authorized`,
  "generic.permission.BLOCKED": `Blocked`,
  "generic.permission.DENY": `Denied`,
  "generic.permission.IGNORE": `Ignored`,
  "generic.permission.PROMPT": `Waiting for permission`,

  get "generic.status.null"() {return this["generic.unknown"]},
  "generic.status.ACTIVE": `Active`,
  "generic.status.ALLOW": `Authorized`,
  "generic.status.ALLOWED": `Allowed`,
  "generic.status.AVAILABLE": `Available`,
  "generic.status.BAD_CONNECTION": `Bad connection`,
  "generic.status.BLOCKED": `Blocked`,
  "generic.status.CANCELLED": `Cancelled`,
  "generic.status.CLEAR_DISK": `Clear disk`,
  "generic.status.CLEAR_PENDING": `Clear pending`,
  "generic.status.CLEAR_IN_PROGRESS": `Clear in progress`,
  "generic.status.CLOSED": `Closed`,
  "generic.status.COMPLETED": `Completed`,
  "generic.status.CRITICAL": `Critical`,
  "generic.status.DEACTIVATED": `Deactivated`,
  "generic.status.DELETING": `Deleting`,
  "generic.status.DENY": `Denied`,
  "generic.status.DEPRECATED": `Deprecated`,
  "generic.status.DISABLED": `Disabled`,
  "generic.status.DUPLICATE": `Duplicate`,
  "generic.status.ENABLED": `Enabled`,
  "generic.status.ERASED": `Erased`,
  "generic.status.ERASED.desc": `The disk has been erased with zeros entirely.`,
  "generic.status.ERASE_PENDING": `Erase pending`,
  "generic.status.ERASE_IN_PROGRESS": `Erase in progress`,
  "generic.status.ERROR": `Error`,
  "generic.status.FAILED": `Failed`,
  "generic.status.FORMAT_PENDING": `Format pending`,
  "generic.status.FORMAT_IN_PROGRESS": `Format in progress`,
  "generic.status.GOOD": `Good`,
  "generic.status.HIGH": `High`,
  "generic.status.IGNORE": `Ignored`,
  "generic.status.IN_USE": `In use`,
  "generic.status.INACTIVE": `Inactive`,
  "generic.status.INSTALL_FAILED": `Install failed`,
  "generic.status.INSTALLING": `Installing`,
  "generic.status.MISSING": `Missing`,
  "generic.status.MODERATE": `Moderate`,
  "generic.status.MODIFICATION_PENDING": `Modification pending`,
  "generic.status.MODIFICATION_IN_PROGRESS": `Modification in progress`,
  "generic.status.NOT_AVAILABLE": `Not available`,
  "generic.status.NOT_IN_USE": `Not in use`,
  "generic.status.OPEN": `Open`,
  "generic.status.PENDING": `Pending`,
  "generic.status.POD_FAILED": `Service failed`,
  "generic.status.POD_DEGRADED": `Degraded service`,
  "generic.status.PROMPT": `Waiting for permission`,
  "generic.status.READY": `Ready`,
  "generic.status.RESOLVED": `Resolved`,
  "generic.status.RUNNING": `Running`,
  "generic.status.STANDBY": `Standby`,
  "generic.status.STARTED": `In progress`,
  "generic.status.STARTING": `Starting`,
  "generic.status.STOPPED": `Stopped`,
  "generic.status.STOPPING": `Stopping`,
  "generic.status.SUSPICIOUS": `Suspicious`,
  "generic.status.TASK_FAILED": `Task failed`,
  "generic.status.UNFORMATTED": `Unformatted`,
  "generic.status.UNINSTALL_FAILED": `Uninstall failed`,
  "generic.status.UNINSTALLED": `Uninstalled`,
  "generic.status.UNKNOWN": `Unknown`,
  "generic.status.UPDATING_PARAMETERS": `Updating parameters`,
  "generic.status.UPDATING_PARAMETERS.long": `A task is currently updating parameters...`,

  "generic.status.undefined": `Undefined`,

  "generic.usage": `Usage`,
  "generic.usage.filterBy": `By usage`,
  "generic.usage.OS_BOOT": `OS (boot)`,
  "generic.usage.OS_ACTIVE": `OS (active firmware)`,
  "generic.usage.OS_PASSIVE": `OS (passive firmware)`,
  "generic.usage.OS_DATA": `OS (data)`,
  "generic.usage.OS_DATA_AND_CONTAINER_STORAGE": `OS (data) and Services`,
  "generic.usage.CONTAINER_STORAGE": `Services`,
  "generic.usage.NONE": `None`,
  "generic.usage.STORAGE": `Storage`,

  "generic.inventoryStatus": `Status`,
  "generic.inventoryStatus.filterBy": `By status`,
  "generic.inventoryStatus.IN_USE": `In use`,
  "generic.inventoryStatus.NOT_IN_USE": `Not in use`,
  "generic.inventoryStatus.UNAVAILABLE": `Unavailable`,
  "generic.inventoryStatus.IN_STOCK": `In stock`,
  "generic.inventoryStatus.DISCARDED": `Discarded`,
  "generic.inventoryStatus.DEFECTIVE": `Defective`,
  "generic.inventoryStatus.DEFECTIVE.explanation": `This appliance has been identified defective by a client. Verify it and test it. If it is working well, you can add this appliance to inventory, otherwise you may discard definitively this appliance.`,
  "generic.inventoryStatus.RETURNED": `Returned`,
  "generic.inventoryStatus.RETURNED.explanation": `This appliance has been returned by a client. Verify it and test it. If it is working well, you can add this appliance to inventory, otherwise you may discard definitively this appliance.`,
  "generic.inventoryStatus.NEW": `New`,
  "generic.inventoryStatus.NEW.explanation": `This appliance is unknown to the inventory. Verify it if necessary and next you may add this appliance to inventory.`,
  "generic.inventoryStatus.notValid.explanation": `This appliance did not complete his request. It may have gone offline or down for some reason.{linebreak}Verify it if necessary and next you may add this appliance to inventory. The next time it tries to connect will complete the registration request.`,

  "generic.kernelVersion": `Kernel version`,

  "generic.level.CRITICAL": `Critical`,
  "generic.level.HIGH": `High`,
  "generic.level.MODERATE": `Moderate`,
  "generic.level.LOW": `Low`,
  "generic.level.OK": `Low`,
  "generic.level.WARNING": `High`,
  "generic.level.NA": `Not applicable`,
  get "generic.level.UNKNOWN"() {return this["generic.unknown.masc"]},
  "generic.operatingMode.ROUTER": `Router`,
  "generic.operatingMode.DRONE": `Surveillance`,
  "generic.operatingMode.DRONE.long": `Surveillance appliance`,
  "generic.operatingMode.BRIDGE": `Access point`,
  "generic.firmwareStatus.UP_TO_DATE": `Up to date`,
  "generic.firmwareStatus.SCHEDULED": `Scheduled`,
  "generic.firmwareStatus.DEPRECATED": `Deprecated`,
  "generic.firmwareStatus.PENDING": `Pending`,
  "generic.firmwareStatus.UNAVAILABLE": `Unavailable`,
  "generic.firmwareStatus.AVAILABLE": `Available`,
  "generic.firmwareStatus.STARTED": `Started`,
  "generic.firmwareStatus.DOWNLOADING": `Downloading`,
  "generic.firmwareStatus.REBOOTING": `Rebooting`,
  "generic.hostIsUp": `Device status`,
  "generic.hostIsUp.filterBy": `By device status`,
  "generic.hostIsUp.YES": `Responding`,
  "generic.hostIsUp.NO": `Not responding`,
  "generic.hasResult": `Results`,
  "generic.hasResult.filterBy": `Has results`,
  "generic.hasResult.YES": `Yes`,
  "generic.hasResult.NO": `No`,
  "generic.pending": `Pending`,
  "generic.pending.long": `Pending update`,
  "generic.started.long": `Update in progress`,
  "generic.priority": `Priority`,
  "generic.priority.CRITICAL": `Critical`,
  "generic.priority.HIGH": `High`,
  "generic.priority.MODERATE": `Moderate`,
  "generic.priority.NONE": `None`,
  "generic.priority.CRITICAL.masc": `Critical`,
  "generic.priority.HIGH.masc": `High`,
  "generic.priority.MODERATE.masc": `Moderate`,
  "generic.priority.NONE.masc": `None`,
  
  "generic.type.CLEAR_DISK": `Clear disk`,
  "generic.type.DISK": `Disk`,
  "generic.type.PACKET_CAPTURE": `Packet capture`,
  "generic.type.DELETE_POD_VOLUME": `Deleting volume`,
  "generic.type.FACTORY_RESET": `Factory reset`,
  "generic.type.PORT_SCAN": `Port scan`,
  "generic.type.WIFI_SURVEY": `Wi-Fi scan`,
  "generic.type.PARTITION": `Partition`,
  "generic.type.REBOOT": `Reboot`,
  "generic.type.SWITCH_API_ENDPOINT": `Switch API Endpoint`,
  "generic.type.SWITCH_ENVIRONMENT": `Switch API Endpoint`,
  "generic.type.SLEEP": `Sleep`,
  "generic.type.FIRMWARE_UPDATE": `Firmware update`,
  "generic.type.CREATE_POD": `Installing service`,
  "generic.type.REMOVE_POD": `Removing service`,
  "generic.type.START_POD": `Starting service`,
  "generic.type.STOP_POD": `Stopping service`,
  "generic.type.FORMAT_DISK": `Configuring disk`,
  "generic.type.STRESS_WIFI_AUTH": `Stress Wi-Fi auth.`,
  "generic.type.UPDATE_POD_PARAMETERS": `Updating service parameters`,
  "generic.usersadmin.none": `No administrator.`,
  "generic.usersadmin.none.withFilter": `No administrator matches this combination of filters.`,
  "generic.usershistory.none.withFilter": `No history entry matches this combination of filters.`,

  "generic.downloading": `Downloading`,
  "generic.downloadFile": `Download file`,
  "generic.downloadedOnBy": `Downloaded on {date} by {name}`,

  "generic.frequency.filterBy": `By frequency`,
  "generic.frequency": `Frequency`,
  "generic.frequency.BAND_5GHZ": `5 GHZ`,
  "generic.frequency.BAND_24GHZ": `2.4 GHz`,
  "generic.fullName": `Full name`,
  "generic.fullScreen": `Full screen`,
  "generic.galleryPicture": `Photo gallery`,
  "generic.galleryPicture.count": `{count, plural, =0 {No photo} one {1 photo} other {{count} photos}}`,
  "generic.galleryPicture.add.count": `{count, plural, =0 {No photo added} one {1 photo added} other {{count} photos added}}`,
  "generic.galleryPicture.add.badFormat": `This format is not supported`,
  "generic.galleryPicture.delete.count": `{count, plural, =0 {No photo will be deleted} one {1 photo will be deleted} other {{count} photos will be deleted}}`,
  "generic.galleryPicture.edit": `Edit gallery`,
  "generic.galleryPicture.bulk.message.update.all": `Photos have been successfully updated/deleted.`,
  "generic.galleryPicture.bulk.message.update.singleDeletion": `The photo has been successfully deleted.`,
  "generic.galleryPicture.upload": `Upload photos`,
  "generic.galleryPicture.tags.devices": `Visible appliances`,
  "generic.galleryPicture.tags.devicesremoved": `This appliance is no more in this location`,
  "generic.galleryPicture.tags.hosts": `Visible devices`,
  "generic.goto.admin": `See on Admin console`,
  "generic.goto.admin.connections": `See all connections`,
  "generic.goto.bottom": `Go to bottom`,
  "generic.goto.dashboard": `View on the Application`,
  "generic.goto.documentation": `See the documentation`,
  "generic.goto.top": `Go to top`,
  "generic.history": `History`,
  "generic.homepage": `Home`,
  "generic.host": `Device`,
  "generic.host.target": `Target device`,
  "generic.hosts": `Devices`,
  "generic.hosts.authorized": `Authorized devices`,
  "generic.hosts.count": `{count, plural, =0 {No devices} one {1 device} other {{count} devices}}`,
  "generic.hosts.count.error": `{count, plural, =0 {No device concerned} one {1 device concerned} other{{count} devices concerned}}`,
  "generic.hosts.count.success": `{count, plural, =0 {No device involved} one {Applies to an device} other{Applies to {count} devices}}`,
  "generic.id": `ID`,
  "generic.ignored": `{count, plural, =0 {Ignored} one {Ignored} other {Ignored}}`,
  "generic.ignoredCount": `{count, plural, =0 {0 ignored} one {1 ignored} other {{count} ignored}}`,
  "generic.in": `in`,
  "generic.incidentHistory": `Incident history`,
  "generic.incidentHistory.info": `Most recent incident always appears first.`,
  "generic.incidentId": `Incident ID`,
  "generic.inPort": `In`,
  "generic.information": `Information`,
  "generic.informations": `Informations`,
  "generic.installations": `{count, plural, =0 {No installation} one {1 installation} other {{count} installations}}`,
  "generic.installedNb": `# installed`,
  "generic.installedOn": `Installed on`,
  "generic.invalid": `Invalid`,
  "generic.isWhitelisted.filterBy": `Whitelisted`,
  "generic.isWhitelisted": `Whitelist`,
  "generic.isWhitelisted.WHITELISTED": `Yes`,
  "generic.isWhitelisted.NOT_WHITELISTED": `No`,
  "generic.lastConnection": `Last connection`,
  "generic.lastIncident": `Last incident`,
  "generic.lastDataUpdate": `Last data update`,
  "generic.lastUpdate": `Last update`,
  "generic.latestUpdate": `Latest update`,
  "generic.latestVersion": `Latest version`,
  "generic.legend": `Legend`,
  "generic.loading": `Loading`,
  "generic.localTime": `Local time`,
  "generic.logs": `Logs`,
  "generic.logout": `Logout`,
  "generic.manage": `Manage`,
  "generic.management": `Management`,
  "generic.matchTypes.filterBy": `By rule`,
  "generic.matchTypes": `Rules match type`,
  "generic.matchTypes.EXACT":`Exact`,
  "generic.matchTypes.CONTAINS":`Contains`,
  "generic.matchTypes.explanation.CONTAINS":`Contains <strong>{ssid}</strong>`,
  "generic.matchTypes.REGEXP":`Regular expression`,
  "generic.matchTypes.explanation.REGEXP":`Trigger the regexp: {value}`,
  "generic.matchTypes.PROXIMITY":`Proximity`,
  "generic.matchTypes.explanation.PROXIMITY":`Proximity with your Wi-Fi <strong>{ssid}</strong>`,
  
  "generic.maxCaracters": `{count} max. caracters.`,
  "generic.maxLimitReached": `Excessive failures`,
  "generic.modifiedOn": `Modified on`,
  "generic.monitoring": `Monitoring`,
  "generic.moreDetails": `More details`,
  "generic.name": `Name`,
  "generic.neverConnected": `Never connected`,
  "generic.notes": `{count, plural, =0 {Note} one {Note} other {Notes}}`,
  "generic.notes.count": `{count, plural, =0 {No note} one {a note} other {{count} notes}}`,
  "generic.notRespondingState": `Not responding`,
  "generic.wifiNetworks": `{count, plural, =0 {Wi-Fi network} one {Wi-Fi network} other {Wi-Fi networks}}`,
  "generic.networkRessource": `Network resource`,
  "generic.na": `N/A`,
  "generic.nbTimes": `# times`,
  "generic.nextOccurenceOn": `Next occurence on`,
  "generic.no": `No`,
  "generic.noModificationToApply": `Not modification to apply`,
  "generic.none": `None`,
  "generic.notAssigned": `Not assigned`,
  "generic.notConfigured": `Not configured`,  
  "generic.notConfigured.plural": `{count, plural, =0 {None not configured} one {1 not configured} other {{count} not configured}}`,
  "generic.notSupported": `Not supported`,
  "generic.noneFem": `None`,
  "generic.obsolete": `Obsolete`,
  "generic.offline": `Offline`,
  "generic.offlineSince": `Offline since`,
  "generic.on": `on {date}`,
  "generic.onATotalOf": `on a total of {count}`,
  "generic.online": `Online`,
  "generic.onlineSince": `Online since`,
  "generic.onlineBySchedule": `Online by schedule`,
  "generic.open": `Open`,
  "generic.operatingMode": `Operating mode`,
  "generic.or": `or`,
  "generic.organization": `{count, plural, =0 {No SentinelC account} one {My SentinelC account}  other {My SentinelC accounts}}`,
  "generic.other": `Other`,
  "generic.others": `Others`,
  "generic.overview": `Overview`,
  "generic.backToOrganizations": `Back to SentinelC accounts`,
  "generic.backToAppliances": `Back to appliances`,
  "generic.packets": `{count, plural, =0 {0 packet} one {1 packet} other {{count} packets}}`,
  "generic.pages.submenu": `Submenu`,
  "generic.pages.subpages": `Subpages`,
  "generic.pagination.XOfY": `{x} - {y} on {z} results`,
  "generic.partial": `Partial`,
  "generic.accounts.partial": `Partial`,
  "generic.accounts.partial.explanation": `Some of the appliances in account are offline`,
  "generic.locations.partial": `Partial`,
  "generic.locations.partial.explanation": `Some of the appliances in locations are offline`,
  "generic.partials": `Partials`,
  "generic.pause": `Pause`,
  "generic.port": `Port`,
  "generic.ports": `Ports`,
  "generic.ports.count": `{count, plural, =0 {0 port} one {1 port} other {{count} ports}}`,
  "generic.portState": `Port state`,
  "generic.portForwards.count.error": `{count, plural, =0 {No port forward concerned} one {1 port forward concerned} other{{count} port forwards concerned}}`,
  "generic.portForwards.count.success": `{count, plural, =0 {No port forward involved} one {Applies to an port forward} other{Applies to {count} port forwards}}`,
  "generic.preparingUpdate": `Preparation of the update`,
  "generic.product": `Product`,
  "generic.project": `Project`,
  "generic.projects": `Projects`,
  "generic.projects.count": `{count, plural, =0 {0 project} one {1 project} other {{count} projects}}`,
  "generic.projects.count.error": `{count, plural, =0 {No project concerned} one {1 project concerned} other{{count} projects concerned}}`,
  "generic.projects.count.success": `{count, plural, =0 {No project involved} one {Applies to an project} other{Applies to {count} projects}}`,
  "generic.projects.none.withFilter": `No projects matches this combination of filters.`,
  "generic.prompt": `{count, plural, =0 {0 unauthorized} one {1 unauthorized} other {{count} unauthorized}}`,
  "generic.prompt.simple": `Standby`,
  "generic.properties": `Properties`,
  "generic.protocol": `Protocol`,
  "generic.protocols": `Protocols`,
  "generic.protocol.filterBy": `By protocol`,
  "generic.protocol.UDP": `UDP`,
  "generic.protocol.TCP": `TCP`,
  "generic.scanTypes": `Scan type`,
  "generic.scanTypes.filterBy": `By scan type`,
  "generic.scanTypes.UDP": `UDP ports`,
  "generic.scanTypes.TCP": `TCP ports`,
  "generic.scanTypes.OS_DETECTION": `OS detection`,
  "generic.publishDate": `Published date`,
  "generic.radio": `Radio`,
  "generic.select.appliance": `Select an appliance`,
  "generic.selected.XOfY": `{x} of {y} selected`,
  "generic.service": `Service`,
  "generic.services": `Services`,
  "generic.services.actions": `Understand actions on services`,
  "generic.services.containers": `Understand containers`,
  "generic.services.volumes": `Understand volumes`,
  "generic.services.count": `{count, plural, =0 {0 service} one {1 service} other {{count} services}}`,
  "generic.services.count.error": `{count, plural, =0 {No service concerned} one {1 service concerned} other{{count} services concerned}}`,
  "generic.services.count.success": `{count, plural, =0 {No service involved} one {Applies to an service} other{Applies to {count} services}}`,
  "generic.services.project": `Services (for this project only)`,
  "generic.reaction": `Reaction`,
  "generic.reaction.filterBy": `By reaction`,
  "generic.reaction.ALLOW": `Allow`,
  "generic.reaction.BLOCK": `Block`,
  "generic.reaction.DNAT": `Accepted`,
  "generic.reaction.LOG_AND_ALLOW": `Log & allow`,
  "generic.reaction.LOG_AND_BLOCK": `Log & block`,
  "generic.reaction.LOG": `Log`,
  "generic.reaction.NONE": `None`,
  "generic.reason": `Reason`,
  "generic.eventType": `Event Type`,
  "generic.eventType.filterBy": `By event type`,
  "generic.eventType.IP_LIST": `Blacklist`,
  "generic.eventType.PORT_FORWARD": `Port forward`,
  "generic.rebooting": `Rebooting`,
  "generic.recurrence": `Recurrence`,
  "generic.recent": `Recent`,
  "generic.recordedTime": `Recorded time`,
  "generic.refreshNow": `Refresh now`,
  "generic.refreshNow.requestSent": `Your request has been sent. Data will be refreshed shortly.`,
  "generic.report": `Report`,
  "generic.reserved": `Reserved`,
  "generic.responding": `Responding`,
  "generic.restricted": `Restricted`,
  "generic.restriction": `Restriction`,
  "generic.results": `{count, plural, =0 {No result} =1 {1 result} other {{count} results}}`,
  "generic.resultsOn": `on {count}`,
  "generic.revoke.PENDING": `Pending revoke`,
  "generic.rogueswifi": `Rogue Wi-Fi`,
  "generic.rogueSsids.none.withFilter": `No Wi-Fi scan matches this combination of filters.`,
  "generic.routers": `{count, plural, =0 {No router} =1 {Router} other {Routers}}`,
  "generic.started": `Started`,
  "generic.statistics": `Statistics`,
  "generic.statistics.none": `No statistic yet`,
  "generic.scans": `Scans`,
  "generic.schedule": `Schedule`,
  "generic.scheduled": `Scheduled`,
  "generic.scheduled.long": `Update scheduled`,
  "generic.scheduledOn": `Scheduled on`,
  "generic.scheduledOn.fem": `Scheduled on`,
  "generic.seconds": `{seconds, plural, =0 {0 second} one {1 second} other {{seconds} seconds}}`,
  "generic.security": `Security`,
  "generic.security.filterBy": `Security criterias`,
  "generic.security.HAS_OPENPORTS": `Has open port(s)`,
  "generic.security.HAS_IPEVENTS": `Has IP event(s)`,
  "generic.sendMeAnEmail": `Send me an email`,
  "generic.settings": `Settings`,
  "generic.settings.advanced": `Advanced settings`,
  "generic.settings.advancedMode": `Simplified mode`,
  "generic.settings.homeLanding": `Home admin`,
  "generic.settings.darkMode": `Dark mode`,
  "generic.settings.user.profile": `Edit profile`,
  "generic.settings.user.password": `Modify password`,
  "generic.see": `View`,
  "generic.seeDetails": `View details`,
  "generic.seeLess": `See less`,
  "generic.seeMore": `See more`,
  "generic.services.none.withFilter": `No service matches this combination of filters.`,
  "generic.since": `since`,
  "generic.size": `Size`,
  "generic.starttime": `Start time`,
  "generic.state": `State`,
  "generic.stop": `Stop`,
  "generic.stoppedState": `Stopped`,
  "generic.stoppedBySchedule": `Stopped by schedule`,
  "generic.stopped": `{count, plural, =0 {None stopped} one {1 stopped} other {{count} stopped}}`,
  "generic.summary": `Summary`,
  "generic.supportedArchi": `Supported architectures`,
  "generic.wifiSummary": `Wi-Fi summary`,
  "generic.synchronized.not": `Not synchronized`,
  "generic.system": `System`,
  "generic.taskId": `Task ID`,
  "generic.task": `Task`,
  "generic.task.id": `Task {id} {status}`,
  "generic.tasks": `Task(s)`,
  "generic.tasks.count": `{count, plural, =0 {0 task} one {1 task} other {{count} tasks}}`,
  "generic.tasks.count.error": `{count, plural, =0 {No task concerned} one {1 task concerned} other{{count} tasks concerned}}`,
  "generic.tasks.count.success": `{count, plural, =0 {No task involved} one {Applies to an task} other{Applies to {count} tasks}}`,
  "generic.tasks.none.withFilter": `No task matches this combination of filters.`,
  "generic.technicalInformations": `Technical informations`,
  "generic.technicalGuides": `Technical guides`,
  "generic.terminal": `Terminal`,
  "generic.time": `Time`,
  "generic.timestamp": `Timestamp`,
  "generic.thisMonth": `This month`,
  "generic.thisWeek": `Last 7 days`,
  "generic.today": `Today`,
  "generic.timezone": `Timezone`,
  "generic.timezone.placeHolder": `Select the timezone of the location`,
  "generic.toSearch": `Search`,
  "generic.total": `Total`,
  "generic.type": `Type`,
  "generic.types": `Types`,
  "generic.boardFirmware": `Board firmware (BIOS)`,
  "generic.boardRevision": `Board revision`,
  "generic.releaseChannels": `Release`,
  "generic.releaseChannels.filterBy": `By release`,
  "generic.releaseNotes": `Release notes`,
  "generic.value": `Value`,
  "generic.warning": `Warning`,
  "generic.warningLevelReached": `Regular failures`,
  "generic.wifi": `Wi-Fi`,
  "generic.wifi.quality.good": `Signal strength of -70 dBm or higher`,
  "generic.wifi.quality.average": `Signal strength between -71 dBm and -80 dBm`,
  "generic.wifi.quality.low": `Signal strength between -81 dBm and -90 dBm`,
  "generic.wifi.quality.bad": `Signal strength of -91 dBm or lower`,
  "generic.wifi.quality.unknown": `Unknown, the client is no longer connected`,
  "generic.wifis.count": `{count, plural, =0 {no Wi-Fi} one {1 Wi-Fi} other {{count} Wi-Fis}}`,
  "generic.rogueSsids.count": `{count, plural, =0 {no Wi-Fi} one {1 Wi-Fi} other {{count} Wi-Fis}}`,
  "generic.rogueSsids.count.error": `{count, plural, =0 {No Wi-Fi concerned} one {1 Wi-Fi concerned} other{{count} Wi-Fis concerned}}`,
  "generic.rogueSsids.count.success": `{count, plural, =0 {No Wi-Fi involved} one {Applies to an Wi-Fi} other{Applies to {count} Wi-Fis}}`,
  "generic.wifiRadios": `Wi-Fi radios`,
  "generic.wifiRadios.count": `{count, plural, =0 {No Wi-Fi radio} one {1 Wi-Fi radio} other {{count} Wi-Fi radios}}`,
  "generic.networking": `Networking`,
  "generic.networking.CNI": `Confined`,
  "generic.networking.CNI.desc": `This service is limited to the network configuration seen below.`,
  "generic.networking.HOST": `Unconfined`,
  "generic.networking.HOST.desc": `This service has full network access and can see, alter and inject network traffic on all ports of the host appliance.`,
  "generic.networkPorts": `Network ports`,
  "generic.desktop": `desktop`,
  "generic.mobile": `mobile device`,
  "generic.unblock": `Unblock`,
  "generic.unchanged": `Unchanged`,
  "generic.unknown": `Unknown`,
  "generic.unknown.fem": `Unknown`,
  "generic.unknown.masc": `Unknown`,
  "generic.unauthorized": `Unauthorized`,
  "generic.unavailable": `Unavailable`,
  "generic.up": `Up`,
  "generic.accounts.up": `Up`,
  "generic.accounts.up.explanation": `All appliances of the account are online`,
  "generic.lines": `{count, plural, =0 {No line} one {1 line} other {{count} lines}}`,
  "generic.locations.up": `Up`,
  "generic.locations.up.explanation": `All appliances of the location are online`,
  "generic.devices.up": `Online`,
  "generic.updated": `Updated`,
  "generic.updatedBy": `Updated by`,
  "generic.updatedOn": `Updated on`,
  "generic.user": `{count, plural, =0 {No user} one {1 user} other {{count} users}}`,
  "generic.userGuides": `User guides`,
  "generic.vendor": `Vendor`,
  "generic.version": `Version`,
  "generic.viewAllStatus": `View all status`,
  "generic.viewList": `List view`,
  "generic.viewGrid": `Grid view`,
  "generic.viewGrid.tooMany": `Too many appliances to show the grid view`,
  "generic.virtual": `Virtual`,
  "generic.volumes": `Volumes`,
  "generic.volumes.count": `{count, plural, =0 {No volume} one {1 volume} other {{count} volumes}}`,
  "generic.volumes.count.error": `{count, plural, =0 {No volume concerned} one {1 volume concerned} other{{count} volumes concerned}}`,
  "generic.volumes.count.success": `{count, plural, =0 {No volume involved} one {Applies to an volume} other{Applies to {count} volumes}}`,
  "generic.volumes.none.withFilter": `No volume matches this combination of filters.`,
  "generic.AllStatusOk": `All status are ok.`,
  "generic.SomeStatusNok": `Some status must be checked.`,
  "generic.yes": `Yes`,
  "generic.wifiState": `Wi-Fi state`,
  "generic.zone": `Zone`,
  "generic.zoneType.NONE": `Open`,
  "generic.zoneType.MAC_WHITELIST": `Restricted`,
  "generic.zones": `Zones`,

  "generic.colors.black": `Black`,
  "generic.colors.blue": `Blue`,
  "generic.colors.green": `Green`,
  "generic.colors.red": `Red`,

  "generic.data.invalid": `Invalid data`,
  "generic.data.transfer": `Data transfer`,
  "generic.data.b": `b`,
  "generic.data.b.infoTitle": `Byte(s)`,
  "generic.data.kb": `kb`,
  "generic.data.kb.infoTitle": `Kilo-byte(s)`,
  "generic.data.mb": `Mb`,
  "generic.data.mb.infoTitle": `Mega-byte(s)`,
  "generic.data.gb": `Gb`,
  "generic.data.gb.infoTitle": `Giga-byte(s)`,
  "generic.data.tb": `Tb`,
  "generic.data.tb.infoTitle": `Tera-byte(s)`,
  "generic.data.pb": `Pb`,
  "generic.data.pb.infoTitle": `Peta-byte(s)`,
  "generic.time.datetime": `Date/Time`,
  "generic.time.from": `From`,
  "generic.time.to": `to`,
  "generic.time.ss": `{count, plural, =0 {second} one {second} other {seconds}}`,
  "generic.time.mm": `{count, plural, =0 {minute} one {minute} other {minutes}}`,
  "generic.time.HH": `{count, plural, =0 {hour} one {hour} other {hours}}`,
  "generic.time.DD": `{count, plural, =0 {day} one {day} other {days}}`,

  "generic.errors.message.unloading": `Page is unloading...`,
  "generic.errors.message.short": `An unexpected error has occured`,
  "generic.errors.message.long": `Our team has been warned and is correcting the problem. Come back in a moment or click on "Reload" to try to reload the current page.`,
  "generic.errors.message.thx": `Thank you for your comprehension and patience.`,
  "generic.errors.message": `An unexpected error has occured, please try later.`,
  "generic.errors.errorOccured.title": `An error occured, please retry.`,
  "generic.errors.errorOccured.detail": `Your last action may not have been able to complete.`,
  "generic.errors.networkErrorOccured.title": `A network error occured, please retry.`,
  "generic.errors.docusaurusSerchFile": `The documentation results can't been reached, please retry later.`,
  "generic.errors.unauthorized": `<p>An access problem occurred unexpectedly. Your session has been disconnected. Please try to log in again.</p>
  `,
  "generic.errors.connectionLost": `
    <p>Connection lost, please verify your internet connection.</p>
    <p>When the connection will be back up, the application will be reconnected automatically.</p>
  `,
  "generic.errors.tokenExpired.btn": `Reconnect`,
  "generic.errors.tokenExpired": `
    Your authentication token has expired.{linebreak}
    For security reason click on reconnect. 
  `,

  "generic.errors.inputFile.serverRejection": `Your file has been rejected by the server. Please be sure it's a valid file.`,
  "generic.errors.inputFile.serverRejection.withSize": `Your file has been rejected by the server. Please be sure it's a valid file and the size does not exceed {size}.`,

  "generic.buttons.accept": `Accept`,
  "generic.buttons.add": `Add`,
  "generic.buttons.addComponent": `Add a component`,
  "generic.buttons.allow": `Allow`,
  "generic.buttons.allow.disabledReason": `Already allowed`,
  "generic.buttons.apply": `Apply`,
  "generic.buttons.back": `Back`,
  "generic.buttons.block": `Block`,
  "generic.buttons.cancel": `Cancel`,
  "generic.buttons.change": `Change`,
  "generic.buttons.close": `Close`,
  "generic.buttons.clear": `Clear`,
  "generic.buttons.clearAllFilter": `Clear all filters`,
  "generic.buttons.clearAllValues": `Clear all values`,
  "generic.buttons.clearFilter": `clear filter`,
  "generic.buttons.choose": `Choose`,
  "generic.buttons.confirm": `Confirm`,
  "generic.buttons.connexion": `Log-in`,
  "generic.buttons.continue": `Continue`,
  "generic.buttons.copy": `Copy`,
  "generic.buttons.createAccount": `Create account`,
  "generic.buttons.createLocation": `Create location`,
  "generic.buttons.delete": `Delete`,
  "generic.buttons.deleteUserAccount": `Revoke account access`,
  "generic.buttons.download": `Download`,
  "generic.buttons.deny": `Deny`,
  "generic.buttons.deny.disabledReason": `Already denied`,
  "generic.buttons.edit": `Edit`,
  "generic.buttons.erase": `Erase`,
  "generic.buttons.finish": `Finish`,
  "generic.buttons.format": `Format`,
  "generic.buttons.getMyKey": `Show my key`,
  "generic.buttons.getAKey": `Get a key`,
  "generic.buttons.ignore": `Ignore`,
  "generic.buttons.import": `Import`,
  "generic.buttons.install": `Install`,
  "generic.buttons.login": `Login`,
  get "generic.buttons.manage"() {return this["generic.manage"]},
  "generic.buttons.modifyAccount": `Modify account`,
  "generic.buttons.modifyLocation": `Modify location`,
  "generic.buttons.next": `Next`,
  get "generic.buttons.no"() {return this["generic.no"]},
  "generic.buttons.ok": `Ok`,
  "generic.buttons.previous": `Previous`,
  "generic.buttons.reboot": `Reboot`,
  "generic.buttons.updateFirmwareNow": `Update firmware now`,
  "generic.buttons.rebootDevice": `Reboot appliance`,
  "generic.buttons.remove": `Remove`,
  "generic.buttons.removeDevice": `Remove appliance`,
  "generic.buttons.removeLocation": `Remove location`,
  "generic.buttons.rescan": `Rescan`,
  "generic.buttons.resendToken": `Send again the token`,
  "generic.buttons.reset": `Reset`,
  "generic.buttons.retry": `Retry`,
  "generic.buttons.save": `Save`,
  "generic.buttons.sendToken": `Send token`,
  "generic.buttons.start": `Start`,
  "generic.buttons.stop": `Stop`,
  "generic.buttons.update": `Update`,
  "generic.buttons.upload": `Upload`,
  "generic.buttons.view": `View`,
  "generic.buttons.viewAll": `View all`,
  get "generic.buttons.yes"() {return this["generic.yes"]},

  "faq": `FAQ`,
  "faq.title": `Help`,
  "faq.noresults": `No question available.`,
  "faq.questions.1.label": `What is SentinelC?`,
  "faq.questions.1.answer": `SentinelC is a wireless router providing security for all of the networks of a small business using the best technologies and practices of wireless communications.`,
  "faq.questions.2.label": `Who can use the SentinelC router?`,
  "faq.questions.2.answer": `The SentinelC router is principaly made for small businesses that want to protect their wireless networks without spending a large amount of money to have Enterprise level security.`,
  "faq.questions.3.label": `How does SentinelC secure wireless networks?`,
  "faq.questions.3.answer": `SentinelC insure than all of your wireless networks are protected by password and provide a full time encryption of data to protect your informations and your Internet navigation.`,
  "faq.questions.4.label": `Does SentinelC provide Enterprise level security?`,
  "faq.questions.4.answer": `Yes. SentinelC offers wireless networking with WPA2-Enterprise protection. This means that you can specifically give people access so that they can connect to your secure network by providing their email and a password set by them.`,
  "faq.questions.5.label": `What types of networks can I create with a SentinelC router?`,
  "faq.questions.5.answer": `
    The SentinelC router allows you to create up to three (3) wireless networks, including:
    <ul>
      <li>a secured private network for your employees and vip users</li>
      <li>a secured guest network for your customers or guests</li>
      <li>a public (open) portal network allowing your clients/guests to obtain a key to access the secured guest network</li>
    </ul>
  `,

  "user.add": `Create admin profile`,
  "user.add.all": `Add all users`,
  "user.deletion.explanation": `
    <p>
      You are about to delete the profile of <strong>{user}</strong>. This action is irreversible and will remove all access and data for this user.
    </p>
    <p>
      Are you sure you want to remove access to this account from this user profile?
    </p>
  `,
  "user.inexistant": `This user has been deleted or doesn't exist.`,
  "user.account": `Admin profile`,
  "user.password": `Modify password`,
  "user.password.info": `Please note that a password change will applied to all your accounts.`,
  "user.form.password": `To modify your password account, click on the button below.`,
  "user.form.password.twoFactor": `Two-factor authentication`,
  "user.form.password.twoFactor.btn": `Manage two-factor authentication`,
  "user.form.password.btn": `Modify password`,
  "user.manage.account.date": `Profile created on {date}`,
  "user.manage.account.block": `Block all access to {location}`,
  "user.manage.support.privileges": `Support administrator`,
  "user.creation.password": `User will be invited to set his password in the confirmation email`,
  "user.updateGeneric.success.content":`The profile of <strong>{firstname} {lastname}</strong> has been updated.`,
  "user.updateYours.success.content":`Your profile has been updated.`,
  "user.updatePassword.success.content":`Your password has been modified successfully!`,
  "user.addGeneric.success.content":`The user <strong>{firstname} {lastname}</strong> has been successfully added to your SentinelC account.`,
  "user.remove.success":`The user {firstname} {lastname} has been successfully removed from your SentinelC account.`,

  "users.online.guest": `{count, plural, =0 {No online guest} one {1 online guest} other {{count} online guests}}`,
  "users.search.placeholder": `Search an email or name (3 characters min.)`,
  "users.search.notfound": `No user found`,
  "users.title": `Admins`,

  "organization.edit": `Edit account`,
  "organization.inexistant": `Inexistant account`,
  "organization.map": `Account quickview`,
  "organization.profile": `SentinelC account`,
  "organization.profile.edit": `Edit SentinelC account`,
  "organization.profile.update.success": `<strong>{organization}</strong> has been updated!`,
  "organization.owner": `Contact`,
  "organization.owner.small": `Those informations will be useful to communicate with you to afford an adequat support in case of need`,
  "organization.details": `Details`,
  "organization.networks.state.offlinedevices": `
    {count, plural,
      =0 {No appliance is <strong>offline</strong>}
      one {One appliance is <strong>offline</strong>}
      other {{count} appliances are <strong>offline</strong>}
    }
  `,
  "organization.networks.state.partial": `Availability is <strong>partial</strong>`,
  "organization.networks.state.noSsid": `<strong>No</strong> Wi-Fi network defined.`,
  "organization.networks.state.allok": `Everything works <strong>well</strong>`,
  "organization.noDevice": `<strong>No</strong> SentinelC appliance`,
  "organization.noLocation": `You don't have any location, to add one, please click on`,
  "organization.addLocation": `add a SentinelC`,

  "location.activity": `Location activity`,
  "location.addCamera": `Add a video camera`,
  "location.addFastConnect": `Add a FactConnect!`,
  "location.addNetworks": `Add a Wi-Fi`,
  "location.addNetworks.explanation": `Adding Wi-Fi is done at the location level to allow it to be broadcaster by other appliances on the location if necessary.`,

  "location.addDrone": `Add a surveillance appliance`,
  "location.addDrone.content": `The purpose of this feature is to add a SentinelC surveillance appliance.`,
  "location.addDrone.update.success": `Surveillance appliance {deviceLocation} added with success`,
  "location.addDrone.offline.warning.title": `Your appliance appears to be offline.`,
  "location.addDrone.offline.warning.content": `
    <p>
      You can still proceed with the installation even if your new surveillance appliance is not already connected or the internet connection is not available.
    </p>
    <p>
      The configuration will be applied as soon as your device will be connected to the internet.
    </p>
  `,

  "location.addRouter": `Add a router`,
  "location.addRouter.update.success": `Router {deviceLocation} added with success`,
  "location.addRouter.content": `The purpose of this feature is to add a SentinelC router that will allow you to use the location.`,
  "location.addRouter.online.content": `
    <p>Your appliance is online</p>
    <p>Click the Confirm button to proceed with the installation of your router.</p>
  `,
  "location.addRouter.offline.content": `
    <p>Your appliance appears to be offline.</p>
    <p>
      You can still proceed with the installation, the configuration will be applied as soon as your device will be connected to the Internet.
    </p>
  `,

  "location.addAccessPoint": `Add an access point`,
  "location.addAccessPoint.update.success": `Access point {deviceLocation} added with success`,
  "location.addAccessPoint.content": `The purpose of this feature is to add a SentinelC appliance that will allow you to extend the signal of some or all of your Wi-Fi networks to provide greater coverage of the Wi-Fi signal when required.`,
  "location.addAccessPoint.networksToExtend": `Wi-Fi networks you want to extend`,

  "location.addAccessPoint.online.inTrunk.message": `We have detected your access point in a valid port (port {port}) on your appliance {location}.`,
  "location.addAccessPoint.online.inTrunk.content": `
    <p>Click the Confirm button to proceed with the installation of your access point.</p>
    <p>No other actions are required.</p>
  `,

  "location.addAccessPoint.online.outTrunk.message": `We have detected your access point plugged into an invalid port type (port {port}) on your appliance {location}.`,
  "location.addAccessPoint.online.outTrunk.content": `
    <p>
      You can still proceed with the installation by clicking the Confirm button.
    </p>
    <p>
      However, after you confirmed the installtion, you must plug your access point into an <strong>ACCESS POINT</strong> type port. You can either modify the actual port type or select an ACCESS POINT type port available.
    </p>
  `,

  "location.addAccessPoint.offline.warning.title": `Your appliance appears to be offline.`,
  "location.addAccessPoint.offline.warning.content": `
    <p>
      You can still proceed with the installation even if your new access point is not already connected into your SentinelC device or the internet connection is not available.
    </p>
    <p>
      The configuration will be applied as soon as your device will be connected into your SentinelC device and to the internet.
    </p>
  `,
  "location.addAccessPoint.offline.noAvailablePort.content": `
    <p>
      {count, plural,
        =0 {You currently don't have any SentinelC appliance and obviously there is <strong>no port available</strong>.}
        one {You currently have 1 SentinelC appliance but there is <strong>no port available</strong>.}
        other {You currently have {count} SentinelC appliances but there is <strong>no port available</strong>.}
      }
    </p>
    <p>
      If you still wish to proceed adding this new access point, you will have to reorganize your ports in a way that you can free one of them and configure it as an ACCESS POINT type port.
    </p>
  `,
  "location.addAccessPoint.offline.hasAvailablePort.content": `
    <p>
      {count, plural,
        =0 {You currently don't have any SentinelC appliance and obviously there is <strong>no port available</strong>.}
        one {You currently have 1 SentinelC appliance in which you could plug this new access point. }
        other {You currently have {count} SentinelC appliances in which you could plug this new access point. }
      }
    </p>
    <p>
      Be aware that you must plug this appliance into an <strong>ACCESS POINT</strong> type port OR modify an <strong>AVAILABLE</strong> port to configure it as an <strong>ACCESS POINT</strong> type port.
    </p>
    <p>
      <strong>Access point and available ports by appliance:</strong>
    </p>
  `,
  "location.map": `Network map`,
  "location.map.label": `To see the complete network map*`,
  "location.map.label.noRouter": `Network map is not available when no appliance is in router mode.`,
  "location.map.sublabel": `Minimal resolution required (min. 768px non-tactile device)`,
  "location.map.smallScreen": `This feature is optimized for bigger display, it may don't work on small devices.`,
  "location.map.hasLoop": `There are loops in the equipment connections, this can lead to visual anomalies of the connections on this graph.`,
  "location.noNetworks": `You don't have any Wi-Fi network. {linebreak}To add one, please click on`,
  "location.maximumNetworksReached": `You have reach the maximum number of Wi-Fi networks possible on your appliances.`,
  "location.noRadioOnDevices": `{count,plural, =0 {No appliance} one {Your appliance can not broadcast Wi-Fi network} other {Your appliances can not broadcast Wi-Fi network}}`,

  "location.addModal.explanation": `Select the Wi-Fi network* corresponding to your needs**:`,
  "location.addModal.note": `* All types of Wi-Fi networks are secured by a shared key among users (devices)..`,
  "location.addModal.note2": `** You can add as many Wi-Fi as you need, but be aware that too many Wi-Fi could be confusing and difficult to manage.`,

  "location.addModal.addPortal.title": `Portal Wi-Fi Enterprise`,
  "location.addModal.addPortal.content": `You want to allow your employees to reinitialize their password for the enterprise network.`,

  "location.addModal.addPSK.title": `<strong>Private</strong> Wi-Fi network`,
  "location.addModal.addPSK.content": `<p>this type of network enables all devices connected <strong>to share ressources securely</strong>.</p>`,

  "location.addModal.addGuest.title": `<strong>Guest</strong> Wi-Fi network`,
  "location.addModal.addGuest.content": `<p>This type of network enables <strong>internet access only</strong> to your guest or visitors.</p>`,

  "location.addDevice": `Add a location`,
  "location.addDevice.success": `{networkLocation} location successfully added`,
  "location.addDevice.content": `This function allows you to add a new Master router that will enable you to define distinct and separate Wi-Fi networks linked to your SentinelC account.`,
  "location.addDevice.warning.content": `Not intended to be used as a range extender.`,

  "location.addPortal.title": `Add a Portal Wi-Fi Enterprise`,
  "location.addPortal.content": `
    <p>
      This type of Wi-Fi network offers a home page for your guest to provide them an access to your public Wi-Fi network .
    </p>
    <p>
      <strong>Not recommended for a home use</strong>, because it opens your network to undesired connections.
    </p>
  `,

  "location.addGuestWifi.title": `Add a Guest Wi-Fi`,
  "location.addGuestWifi.content": `
    <p>
      This type of Wi-Fi network is suitable to offer Internet access to guests or devices you want to isolate from your private network.
    </p>
  `,
  "location.addGuestWifi.content2": `
    <p>
      The operating mode differs if it is a network intended for use at home or in the office.
    </p>
  `,

  "location.updateschedule.modes.listAppliances": ` { count, plural,
    =0 {No appliance}
    one {The following appliance will inherit this configuration change:}
    other {The following appliances will inherit this configuration change:}
  }
  `,
  "location.updateschedule.label": `Schedule of router's updates`,
  "location.updateschedule.modes.label": `Select the schedule that suits you best and does not affect your operations at this location.`,
  "location.updateschedule.modes.placeholder": `Select a schedule period`,
  "location.updateschedule.modes.AUTO": `Automatic`,
  "location.updateschedule.modes.AUTO.sentence": `Updates will be applied between 2:00 and 3:00 am.`,
  "location.updateschedule.modes.IMMEDIATE": `Immediate`,
  "location.updateschedule.modes.IMMEDIATE.sentence": `Updates will be applied as soon as they are released.`,
  "location.updateschedule.modes.CUSTOM": `At specific time`,
  "location.updateschedule.modes.CUSTOM.sentence": `Updates will be applied within an hour following {hour}.`,
  "location.updateschedule.modes.CUSTOM.sentence.withoutHour": `Updates will be applied within an hour following start time.`,
  "location.updateschedule.interval.label": `Start time`,
  "location.updateschedule.interval.endTime": `End time`,

  "location.currentFirmware.version": `Current firmware release`,
  "location.currentFirmware.installed": `Firmware installed on`,
  "location.availableFirmware.version": `Latest firmware version`,
  "location.availableFirmware.uptodate": `Up to date`,
  "location.addPskWifi.title": `Add a Wi-Fi with shared key`,
  "location.addPskWifi.content": `
    <p>
      This type of private network is designed for both <strong>home</strong> and <strong>enterprise</strong>. It's the most generally used type of network.
    </p>
    <p>
      In this case, access to the network is providing by a key (password).
    </p>
  `,
  "location.edit": `Edit location`,
  "location.inexistant": `Location doesn't exist or has been removed.`,
  "location.isOffline": `{count, plural,
    =0 {No appliance offline or unavailable}
    one {
      {
        total, plural,
        one {The appliance is offline or unavailable}
        other {An appliance is offline or unavailable}
      }
    }
    other {{count} appliances are offline or unavailable}
  }`,

  "location.configuredWiFiNetwork.noNetworks": `This location can not broadcast any Wi-Fi network.`,
  "location.firmware": `Firmware version`,
  "location.versionStatus": `Firmware status`,
  "location.ip": `IP Address`,
  "location.address": `Address`,
  "location.address.none": `No address saved`,
  "location.address.edit": `Edit address of the location`,
  "location.address.address": `Address`,
  "location.address.apt": `Apartment, suite, etc.`,
  "location.address.zipcode": `ZIP code`,
  "location.address.country": `Country`,
  "location.address.city": `City`,
  "location.address.state": `State/Province`,
  "location.address.GPS": `GPS coordinates`,
  "location.address.GPS.lat": `Lat.`,
  "location.address.GPS.lat.long": `Latitude`,
  "location.address.GPS.lon": `Lon.`,
  "location.address.GPS.lon.long": `Longitude`,
  "location.location": `Location`,
  "location.location.placeholder": `Enter a location (ex. Head office, Home, etc.)`,
  "location.mac": `MAC address`,
  "location.model": `Model`,
  "location.owner": `Contact`,
  "location.product": `Product key`,
  "location.remove": `Remove {location}`,
  "location.remove.success": `Your location {location} is removing, this operation can take a few minutes.`,
  "location.appliances": `{count, plural, =0 {Appliance} one {Appliance} other {Appliances}}`,
  "location.appliances.operations": `Understand operations on equipements`,
  "location.routeurs": `{count, plural, =0 {Router} one {Router} other {Routers}}`,
  "location.remove.modal.title": `Remove {location}`,
  "location.remove.modal.messageAP": `You must remove first all your SentinelC appliances to be able to remove this location`,
  "location.remove.modal.content": `
    <p>This action will remove <strong>{location}</strong> location and Wi-Fi networks configured at this location. Any configuration associated with this location will be lost.</p>
    <p><strong>Please note that this action is irreversible</strong>{linebreak} Are you sure you want to remove this location?</p>
  `,
  "location.sentinelCDevice": `SentinelC appliance in this location. {linebreak}Authentication successful.`,
  "location.sentinelCDevices.routersandAP": `Routers, access points and surveillance appliances`,
  "location.sentinelCDevices.videoCameras": `Video cameras`,
  "location.sentinelCDevices.diskDrives": `disk drives`,
  "location.sentinelCDevices.IOT": `IoT`,
  "location.serial": `Serial number`,
  "location.update": `Last update`,

  "location.update.success": `Location <strong>{location}</strong> updated.`,
  "location.delete.success": `Location <strong>{location}</strong> deleted.`,
  "location.modal.devices": `Appliances summary status`,
  "location.modal.ssids": `Wi-Fi summary status`,
  "location.modal.hosts": `Devices summary status`,

  "locations.title": `Locations`,

  "video.liveFeed": `Watch live`,
  "video.noVideoCamera": `No surveillance camera installed.`,

  "host.connectionDetails": `Connection details`,
  "host.inexistant": `The device you ask for doesn't exist`,
  "host.info": `Device infos`,
  "host.name": `Device name`,
  "host.lastSeen": `Last seen`,
  "host.hostname": `Device hostname`,
  "host.hostname.short": `Device hostname`,
  "host.mac": `MAC Address`,
  "host.category": `Category`,
  "host.category.filterBy": `By category`,
  "host.category.placeholder": `Select a category`,
  "host.category.MOBILE_PHONE": `Mobile phone`,
  "host.category.MOBILE_TABLET": `Tablet`,
  "host.category.DESKTOP": `Desktop computer`,
  "host.category.LAPTOP": `Laptop computer`,
  "host.category.VOIP_PHONE": `Voice over IP phone`,
  "host.category.AUDIO": `Audio system`,
  "host.category.CAMERA": `IP Camera`,
  "host.category.SECURITY": `Security system`,
  "host.category.SIGNAGE": `Digital signage / Screen`,
  "host.category.HVAC": `HVAC (heating, ventilation, and air conditioning system)`,
  "host.category.SENSOR": `Environment sensor`,
  "host.category.TV": `TV, Smart TV, Casting device`,
  "host.category.NETWORKING": `Network appliance (router, switch, firewall, access point)`,
  "host.category.WEARABLE": `Wearable`,
  "host.category.SERVER": `Server`,
  "host.category.PRINTER": `Printer`,
  "host.category.GAMING_CONSOLE": `Gaming system or console`,
  "host.category.IOT_HUB": `IOT hub`,
  "host.category.KIOSK": `Interactive kiosk`,
  "host.category.LIGHT": `Light or lightning system`,
  "host.category.APPLIANCE": `Appliance`,
  get "host.category.OTHER"() {return this["generic.others"]},
  "host.category.OTHER.simple": `Other (describe)`,
  "host.categoryOther": `Device category description`,
  "host.manufacturer": `Provider/Manufacturer`,
  "host.provider": `Provider`,
  "host.manufacturer.placeholder": `Ex. Bell, HP, Apple, Sony,...`,
  "host.use": `Intended use`,
  "host.vlan": `Zone`,
  "host.vlan.ip": `IP address`,
  "host.use.placeholder": `Describe the intended use of the device.`,

  "host.isApplianceLocation": `
    <strong>This device is a SentinelC Network appliance of your organization.</strong>{linebreak}
    To edit its properties as an appliance, please click on the link:
  `,
  "host.isApplianceOfAnotherLocation.goto": `
    Go to appliance
  `,
  "host.whitelisting.addVlanPermission": `Grant permission to a restricted zone`,
  "host.whitelisting.authorization": `Permissions on restricted zones by location`,
  "host.whitelisting.authorization.vlan.none": ` No permission, please click on edit button to update permissions`,
  "host.whitelisting.authorization.vlan.none.short": `No permission`,
  "host.whitelisting.connected.on.wifi.label": `Connected to Wi-Fi`,
  "host.whitelisting.connected.on.wire.label": `Connected on wired`,
  "host.whitelisting.connected.on.virtual.label": `Connected virtually`,
  "host.whitelisting.connected.toAppliance": `Connected to appliance`,
  "host.whitelisting.connectionAttempt": `Connection attempt`,
  "host.whitelisting.connectionAttempt.number": `# of authentication failures`,
  "host.whitelisting.authorizedOn": `Authorized on`,
  "host.whitelisting.authorizedOnVlan": `Authorized on zone`,
  "host.whitelisting.lastAttemptOn": `Last attempt to connect on`,
  "host.whitelisting.deniedOn": `Denied on`,
  "host.whitelisting.ignoredOn": `Ignored on`,
  "host.whitelisting.manageAttemptRequired": `You must set all permissions (Accept or Deny) for attempted connections.`,
  "host.whitelisting.selectVlanPermission": `Select a restricted zone`,
  "host.whitelisting.suggestBlock": `You have denied any access attempts for this device. If you want to block this device, you can click the Cancel button of this window and block this device from the list of unauthorized devices.`,
  "host.whitelisting.ignored": `The attempt has been ignored.`,
  "host.whitelisting.failure.attemptCount": `Number of attempts`,
  "host.whitelisting.failure.explanation": `Devices with several failed attempts without completing a successful connection.`,
  "host.whitelisting.failure": `Last authentication failure on `,
  "host.whitelisting.neverSuccedToConnect": `Never succeeded to connect`,
  "host.whitelisting.neverSuccedToConnect.label": `First seen`,

  "hosts.filterby.network": `Filter by location`,
  "hosts.filterby.appliance": `Filter by appliance`,
  "hosts.filterby.connectionStatus": `Filter by connection status`,
  "hosts.filterby.search": `Filter by type`,

  "vlans.open.count": `{count, plural, =0 {0 open} one {1 open} other {{count} open}}`,
  "vlans.restricted.count": `{count, plural, =0 {0 restricted} one {1 restricted} other {{count} restricted}}`,

  "vlans.manage.addVlan": `Add a new zone`,
  "vlans.manage.editVlan": `Edit zone`,
  "vlans.manage.editVlan.nonEditableForInternal": `Non editable for a system zone.`,
  "vlans.noOne": `You don't have any zone defined. A zone constitutes a logical grouping which makes it possible to segment and securise a local network into a virtual sub-network.`,
  "vlans.noOne.add.sentencePart1": `To add one, please click on`,

  "vlan.manage": `Manage authorizations to restricted zones`,
  "vlan.manage.modal.content1": `
    {count, plural,
      =0 {
        <p>The zone is not affected to an appliance.</p>
      }
      one {
        <p>To apply the requested change, the following appliance will be reconfigured:</p>
      }
      other {
        <p>To apply the requested change, the following appliances will be reconfigured:</p>
      }
    }
  `,
  "vlan.manage.modal.content2": `
    <p>This reconfiguration will cause a brief service interruption (about 30 seconds per appliance).</p>
    <p>If you modified the ports be sure your physical connections match to your ports modification.</p>
  `,
  "vlan.delete.modal.box": `
    <p>You will deleted the zone <strong>{zone}</strong>.</p>
    <p>This action will delete all related Wi-Fi networks defined there as well as all access to the resources (wired ports) associated with this zone.</p>
  `,
  "vlan.edit.title": `Edit zone «{zone}»`,
  "vlan.edit.title.noName": `Edit zone`,
  "vlan.edit": `Edit zone`,
  "vlan.delete": `Delete zone`,
  "vlan.delete.named": `Deletion of the zone {zone}`,
  "vlan.noOne.onAppliance": `No zones are in use on this appliance.`,
  "vlan.manage.noneAttempted": `Never attempted to connect`,
  "vlan.manage.attempted.waitingForPermission": `Waiting for permission`,
  "vlan.manage.attempted.denied": `Access denied`,
  "vlan.restricted.tooltip": `Devices accessing this zone <strong>must be explicitly authorized</strong> to gain access to network resources.`,
  "vlan.open.tooltip": `Devices accessing this zone <strong>do not need to be explicitly authorized</strong> to gain access to network resources.`,

  "vlan.mode.title": `Network mode`,
  "vlan.mode.routed": `Routed`,
  "vlan.mode.routed.sublabel": `In routed mode, client devices connected inside this zone will be routed to the internet. Network Address Translation (NAT) and core network services (DHCP and DNS) will be provided.`,
  "vlan.mode.passthrough": `Passthrough`,
  //"vlan.mode.passthrough.disabled": `This option is not available because a passthrough zone already exists at this location. Only one passthrough zone is allowed.`,
  "vlan.mode.passthrough.sublabel": `In a passthrough zone, client devices are bridged directly to the internet port (WAN). Only one passthrough zone can be configured per network location.`,

  "vlan.accessPolicy.title": `Access policy`,
  "vlan.accessPolicy.management": `Allow management (web and ssh) of SentinelC appliances when connected to this zone.`,
  "vlan.accessPolicy.placeholder": `Select an access policy`,
  "vlan.accessPolicy.NONE": `Open`,
  "vlan.accessPolicy.NONE.sublabel": `Any device is allowed to connect to this zone`,
  "vlan.accessPolicy.MAC_WHITELIST": `Restricted`,
  "vlan.accessPolicy.MAC_WHITELIST.sublabel": `Devices must be authorized to access this zone`,
  "vlan.accessPolicy.null.sublabel": `Access policy defines wheter this zone will be open to connections or restricted`,

  "vlan.id": `Vlan ID`,
  "vlan.gatewayIp": `Gateway IP`,
  "vlan.securityProfile.explanation": `A security profile allows you to define and activate specific security features at the network level of the location such as the detection and blocking of malicious IPs, enforcing use of the DNS server of the location, etc. Security profile can be overwrite at the zone/vlan level for specific needs required.`,
  "vlan.securityProfile.templateVersion": `Profile version`,
  "vlan.securityProfile.ipBlacklist": `IP blacklist group`,
  "vlan.securityProfile.ipBlacklist.sublabel": `IP blacklist group is a mean to monitor and intercept malicious IP connections.`,
  "vlan.securityProfile.status.deprecated": `Profile is deprecated. Must be changed.`,
  "vlan.securityProfile.status.needUpdate": `Profile needs to be updated.`,
  "vlan.securityProfile.status.lowLevel": `Profile has a low level of security.`,
  "vlan.securityProfile.status.upToDate": `Profile is up to date.`,
  "vlan.securityProfile.status.allOverride": `All zones are overriding the location security profile.`,
  "vlan.securityProfile.status.noOverride": `All zones inherit the location security profile.`,
  "vlan.securityProfile.status.partialOverride": `The following zones are overriding the location security profile`,
  "vlan.securityProfile": `Security profile`,
  "vlan.securityProfile.override": `Security profile override`,
  "vlan.securityProfile.filterBy": `By security profile`,
  "vlan.securityProfile.profile": `Profile`,
  "vlan.securityProfile.onLocation": `Actual zone/vlan security profile`,
  "vlan.securityProfile.onLocation.note": `Only these zones/vlans will inherit the location's security profile.`,
  "vlan.securityProfile.edit": `Edit security profile`,
  "vlan.securityProfile.inherit": `Inherit from location`,  
  "vlan.securityProfile.inherit.select": `Chose "Inherit from location"`,
  "vlan.securityProfile.inherited": `Inherited from location`,
  "vlan.securityProfile.placeholder": `Select a security profile`,
  "vlan.dnsConfiguration": `DNS settings`,
  "vlan.dnsConfiguration.placeholder": `Select DNS settings`,
  "vlan.dnsConfiguration.sublabel": `This setting, if enabled, indicates that DNS requests will be managed by a custom configuration.`,
  "vlan.dnsProvider": `DNS service`,
  "vlan.dnsProvider.sublabel": `Allow you to configure various DNS attributes that a virtual server or DNS listener object applies to DNS traffic (ie. Web content filtering, malware & phishing enhancement protection, etc.). IP : 9.9.9.9, 149.112.112.112`,
  "vlan.dnsProvider.placeholder": `Select DNS service`,
  "vlan.description.CUSTOM": `Use custom configurations using the following form.`,
  "vlan.description.DISABLE": `No DNS services will be offered in the zone.`,
  "vlan.enforceDNS": `Enforce DNS`,
  "vlan.enforceDNS.sublabel": `Prevent clients from using another DNS server by intercepting requests. This has no effect if the client is using an encrypted DNS protocol.`,
  "vlan.monitorDns": `DNS monitoring`,
  "vlan.monitorDns.sublabel": `Collect data related to DNS usage.`,
  "vlan.enableDot": `DNS over TLS`,
  "vlan.enableDot.sublabel": `DNS over TLS (DoT) secures DNS queries by encrypting them via the TLS protocol.`,
  "vlan.dns": `DNS`,
  "vlan.dns.disabled": `Disabled DNS`,
  "vlan.dnsProfile": `DNS profile`,
  "vlan.dnsProfile.placeholder": `Select a DNS profile`,
  "vlan.dnsProfile.sublabel": `Allows you to configure various DNS attributes that a virtual server or DNS listener object applies to DNS traffic (ie. Web content filtering, malware & phishing enhancement protection, etc).`,
  "vlan.internal.deletionDisabled.explanation": `It is not possible to remove the internal zone.`,
  "vlan.maxDownloadRate": `Max download speed`,
  "vlan.guaranteedDownloadRate": `Guaranteed download rate`,
  "vlan.maxUploadRate": `Max upload rate`,
  "vlan.guaranteedUploadRate": `Guaranteed upload rate`,
  "vlan.vid.value.sublabel": `The ID must be between 1 and 4094.`,
  "vlan.vid.reserved.sublabel": `This zone ID is reserved and can't be modified`,
  "vlan.vid.existing.sublabel": `View existing vlan IDs`,
  "vlan.subnet": `Subnet`,
  "vlan.subnet.sublabel": `Subnet must be unique accross your entire location.`,
  "vlan.subnet.sublabel.existingSubnet": `Show existing subnet`,
  "vlan.subnet.sublabel.creation": ` By default here is an available subnet.`,
  "vlan.internetAccess.title": `Internet access`,
  "vlan.internetAccess.yes.sublabel": `Allow Internet access.`,
  "vlan.internetAccess.no.sublabel": `Doesn't allow Internet access.`,
  "vlan.internetAccess.limited": `Limited`,
  "vlan.internetAccess.limited.sublabel": `Allow limited Internet access.`,
  "vlan.zoneType": `Zone type`,
  "vlan.zoneType.PUBLIC.title": `Public`,
  "vlan.zoneType.PUBLIC.sublabel": `Clients are isolated from one another.`,
  "vlan.zoneType.PORTAL.title": `Portal`,
  "vlan.zoneType.PORTAL.sublabel": `Clients access are limited to public Wi-Fi portal.`,
  "vlan.zoneType.PRIVATE.title": `Private`,
  "vlan.zoneType.PRIVATE.sublabel": `Allow clients to communicate with each other.`,
  "vlan.zoneType.null.sublabel": `Zone type enables whether devices can communicate with each other or not.`,

  "vlan.AccessportsAllocated": `Allocated access ports` ,

  "vlan.updateGeneric.success.content":`The zone <strong>{name}</strong> has been updated.`,
  "vlan.addGeneric.success.content":`The zone <strong>{name}</strong> has been successfully added to your location.`,
  "vlan.remove.success":`The zone {name} has been successfully removed from your location.`,

  "vlan.wifis.none": `No Wi-Fi`,
  "vlan.wifis.noneConfigured": `No configured Wi-Fi`,
  "vlan.wifis.actives": `{countActives, plural,
    =0 {
      No <strong>active</strong> Wi-Fi out of {count}
    }
    one {
      1 <strong>active</strong> Wi-Fi out of {count}
    }
    other {
      {countActives} <strong>active</strong> Wi-Fi out of {count}
    }
  }`,

  "vlan.configuredPorts": `{count, plural, =0 {No configured access port} one {1 configured access port} other {{count} configured access ports}}`,

  "device.identification": `Device identification`,
  "device.identification.placeholder": `Enter a name, identification or specific location`,
  "device.machineType": `Machine type`,
  "device.model": `Model`,
  "device.editHardware": `Edit hardware properties (overwrite)`,
  "device.editHardware.systemValue": `System value`,
  "device.editHardware.resetValues": `Reset properties to default system values`,
  "device.cpu": `CPU`,
  "device.cpuModel": `CPU model`,
  "device.cpuDetails": `CPU details`,
  "device.cores": `core(s)`,
  "device.threads": `thread(s)`,
  "device.operatingMode.short": `Oper. mode`,
  "device.configurationTemplate": `Configuration`,
  "device.outOfNetwork": `SentinelC appliance as a client device in this location`,
  "device.type.BRIDGE": `Access point`,
  "device.type.ROUTER": `Master router`,
  "device.type.DRONE": `Surveillance`,
  "device.location.BRIDGE": `Access point name`,
  "device.location.ROUTER": `Master router name`,
  "device.location.DRONE": `Name`,
  "device.summary.wifiBroadcasted": `{count, plural, =0 {Wi-Fi broadcasted} =1 {Wi-Fi broadcasted} other {Wi-Fi broadcasted}}`,
  "device.summary.wiredConnected": `{count, plural, =0 {Wired connected} =1 {1 wired connected} other {{count} wired connected}}`,
  "device.wifi.enabled.ROUTER": `Exposes {enabled} Wi-Fi out of {total}`,
  "device.wifi.enabled.BRIDGE": `Exposes {enabled} Wi-Fi out of {total}`,
  "device.wifi.enabled.DRONE": `Exposes {enabled} Wi-Fi out of {total}`,
  "device.wifi.exposed": `{count, plural, other {Exposed}}`,
  "device.wifi.notExposed": `{count, plural, other {Not exposed}}`,
  "device.wifi.none": `No Wi-Fi exposed`,
  "device.wifi.nowifi": `No Wi-Fi radio available`,
  "device.wifi.nowifi.short": `No Wi-Fi radio`,
  "device.wifi.nowifi.filtered": `No Wi-Fi network with this filter.`,
  "device.wifi.nowifi.configured": `No Wi-Fi network configured`,
  "device.wifi.nowifi.broadcasted": `No broadcasted Wi-Fi network`,
  "device.wifi.availability": `Broadcast {countAllSsids} Wi-Fi on a capacity of {maxSsids}.`,
  "device.wifi.mustSelectOne": `You must select at least an appliance to broadcast this Wi-Fi network.`,
  "device.wifi.signalStrength": `Signal strength`,
  "device.wifi.frequency": `Frequency`,
  "device.wifi.potentialRogueWifi": `Potential rogue Wi-Fi`,
  "device.offlineSince": `Offline since`,
  "device.connectedSince": `Connected since`,
  "device.firstConnection": `First connection`,
  "device.activeConnection": `Active connection`,
  "device.failureConnection": `Authentication failure`,
  "device.detectedOnPort": `Detected on port`,
  "device.pastConnections": `Past connection(s)`,

  "device.badWiredConnection": `Bad wired connection`,
  "device.VerifyPortDetails": `Verify port details`,
    "device.awaitingRemoval": `This appliance is in the process of being removed from this location because a factory reset has been ordered on {date}.`,


  "device.edit": `Edit appliance`,
  "device.inexistant": `The appliance you asked for doesn't exist or has been deleted.`,
  "device.alias.label": `Name this device`,
  "device.alias.disabled": `You can not edit the name of a device in the inventory`,

  "device.updateGeneric.success.content": `Device updated.`,
  "device.block": `Block device`,
  "device.block.modal.content": `This action will block all access to <strong>{organization}</strong> network resources to this device, including Internet access.`,
  "device.block.modal.content3": `Are you sure you want to block this device?`,
  "device.block.modal.field.label": `Reason to block this device`,
  "device.block.modal.field.placeholder": `Enter a reason`,

  "device.block.success.content": `The device has been blocked.`,
  "device.block.error.content": `An error occured. The device hasn't been blocked.`,
  "device.unblock": `Unblock device`,
  "device.unblock.modal.content": `
    <p>
      Are you sure you want to give this devive access to networks and resources of <strong>{organization}</strong>?
    </p>
  `,
  "device.unblock.whitelistingOn.modal.message": `
    <p>
      This device can not be unblocked until all its mandatory properties are completed.
    </p>
  `,
  "device.unblock.whitelistingOn.modal.content": `
    <p>
      You can edit these properties by clicking the Edit button on this page.
    </p>
  `,
  "device.unblock.success.content": `The device has been unblocked.`,
  "device.unblock.error.content": `An error occured. The device hasn't been unblocked.`,
  "device.noname": `<No name>`,
  "device.extendedWiFiNetwork": `Extended Wi-Fi networks`,
  "device.configuredWiFiNetwork": `Broadcasted Wi-Fi networks`,
  "device.configuredWiFiNetwork.noNetworks": `This appliance can not broadcast any Wi-Fi network.`,
  "device.configuredWiFiNetwork.maxNetworks": `This appliance can broadcast up to {count, plural, =0 {0 Wi-Fi network} =1 {1 Wi-Fi network} other {{count} Wi-Fi networks}}`,
  "device.configuredWiFiNetwork.maxNetworks.info": `If a Wi-Fi is <strong>ONLY</strong> broadcasted by this appliance, it can not be removed from this configuration. {linebreak}If needed, you can delete it by going to the Wi-Fi page of its zone.`,
  "device.ssids.deactivateWithDependencies.message": `
    You have unchecked broadcasting of portal Wi-Fi network <strong>«{ssid}»</strong> on this appliance.
  `,
  "device.ssids.deactivateWithDependencies.content": `
    <p>
      The access to
      {count, plural, =0 {public network} one {public network} other {public networks}}
      <strong>«{ssids}»</strong>
      {count, plural, =0 {associated} one {associated} other {associated}}
      to this portal for users next to this appliance.
    </p>
    <p>
      However, users actually connected to
      {count, plural, =0 {this public network} one {this public network} other {this public networks}}
      will stay connected.
    </p>
  `,

  "device.ssids.deactivateGuest.message": `
    You have unchecked broadcasting of public Wi-Fi <strong>«{ssid}»</strong> on this appliance.
  `,
  "device.ssids.deactivateGuest.content": `
    <p>
      Devices connecting to the portal Wi-Fi network
      <strong>«{parent}»</strong>
      from this appliance may not be able to connect to your public network
      <strong>«{child}»</strong>.
    </p>
  `,

  "device.ssids.deactivateSsids.message": `
   You have unchecked broadcasting of following Wi-Fi networks: <strong>{ssids}</strong> on this appliance.
  `,
  "device.ssids.deactivateSsids.content": `
    <p>
      This action will force the deconnection of all devices connected to this networks from this appliance.
    </p>
  `,

  "device.status.dataNotLive": `Note: Data is updated every minute.`,

  "device.statusLegend": `Other status`,
  "device.statusLegend.certified": `Certified SentinelC appliance`,
  "device.statusLegend.forbidden": `Prohibited device`,
  "device.statusLegend.restrictedZone": `Restricted zone`,
  "device.statusLegend.internalZone": `System zone`,
  "device.statusLegend.unrestrictedZone": `Unrestricted zone`,
  "device.statusLegend.disabledZone": `Disabled zone`,

  "device.port.statusLegend": `Port status legend`,
  "device.port.ACCESS": `Available `,
  "device.port.ACCESS.open": `Unrestricted`,
  "device.port.NONE": `Disable`,
  get "device.port.DISABLED"() {return this["generic.disabled"]},
  "device.port.TRUNK": `Internal use only`,
  "device.port.TRUNK.noTrunk": `No port of type access point in the parent appliance`,
  "device.port.WAN": `Internal use only`,

  "device.port.zone.noName": `Please enter a zone name`,

  "device.port.zone.ACCESS": `Zone`,
  "device.port.zone.CUSTOM": "Custom",
  "device.port.zone.DISABLED": `Not configured`,
  "device.port.zone.NONE": `Disable`,
  get "device.port.zone.PASSTHROUGH"() {return this["vlan.mode.passthrough"]},
  "device.port.zone.POD": `Service`,
  "device.port.zone.TRUNK.forChildren": `Network`,
  "device.port.zone.TRUNK": `Access point`,
  "device.port.zone.TRUNK.forParent": `Access point`,
  "device.port.zone.WAN": `Internet`,

  "device.port.modal.ROUTER": `
    <p>
      Please note that the changes made will cause a complete reconfiguration of your main router <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.DRONE": `
    <p>
      Please note that the changes made will cause a complete reconfiguration of your surveillance appliance <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.BRIDGE": `
    <p>
      Please note that the changes made will cause a complete reconfiguration of your main access point <strong>{device}</strong>.
    </p>
  `,
  "device.port.modal.generic": `
    <p>
      In addition, this situation will cause temporary disconnection of all devices connected to this equipment.
    </p>
    <p>
      Make sure that your connections correspond to the configuration changes of the physical access ports.
    </p>
  `,
    "device.port.modal.alert.accessPoint1": `
    <p>
      {count, plural,
        =0 {You have modified no port of <strong>Access point</strong> type.}
        one {You have modified a port of <strong>Access point</strong> type in which this equipment is actually plugged in:}
        other {You have modified several ports of <strong>Access point</strong> type in which those equipments are actually plugged in:}
      }
    </p>
  `,
  "device.port.modal.alert.accessPoint2": `
    <p>
      {count, plural,
        =0 {}
        one {This operation could cause the access point to become inoperative.}
        other{This operation could cause access points to become inoperatives.}
      }
    </p>
  `,

  "device.unappliedChanges":`{count, plural,
    =0 {No stanby change}
    one {You have a standby configuration change}
    other {You have {count} standby configuration changes}
  }`,
  "device.unappliedChanges.onDevice": `{count, plural, =0 {No appliance to modify} one {This change will be applied on appliance:} other {This change will be applied on appliances:}}`,
  "device.unappliedChanges.info": `Updates will be applied as soon as your appliance will be available or online.`,
  "device.unappliedChanges.DEVICE_ADD": `Initial configuration of appliance <em>{name}</em>.`,
  "device.unappliedChanges.DEVICE_REPLACE": `Configuration of appliance <em>{name_old}</em> has been transferred to <em>{name_new}</em>.`,
  "device.unappliedChanges.VLAN_ADD": `The zone <em>{name}</em> has been created.`,
  "device.unappliedChanges.VLAN_UPDATE": `The zone <em>{name}</em> has been modified.`,
  "device.unappliedChanges.VLAN_DELETE": `The zone <em>{name}</em> has been deleted.`,
  "device.unappliedChanges.SSID_ADD": `Wi-Fi network <em>{name}</em> has been configured`,
  "device.unappliedChanges.SSID_DEL": `Wi-Fi network <em>{name}</em> has been removed.`,
  "device.unappliedChanges.SSID_UPDATE_SCHEDULE": `Wi-Fi network <em>{name}</em> schedule has been changed.`,
  "device.unappliedChanges.SSID_UPDATE_NAME": `Wi-Fi network name changed from <em>{previous_name}</em> to <em>{new_name}</em>`,
  "device.unappliedChanges.SSID_UPDATE_PSK": `Wi-Fi network <em>{name}</em> key has been changed.`,
  "device.unappliedChanges.SSID_ENABLE": `Wi-Fi network <em>{name}</em> has been restart.`,
  "device.unappliedChanges.SSID_DISABLE": `Wi-Fi network <em>{name}</em> has been stopped.`,
  "device.unappliedChanges.CHANGE_OPERATING_MODE": `Reconfiguration of the appliance <strong>{name}</strong>.`,
  "device.unappliedChanges.WHITELIST_UPDATE": `A device access to a restricted zone has been modified.`,
  "device.unappliedChanges.NETWORK_UPDATE": `The network configuration of the location has been updated.`,
  "device.unappliedChanges.FACTORY_RESET": `Factory reset of the appliance <strong>{name}</strong>.`,
  "device.unappliedChanges.CHANGE_PORT_CONFIG": `{count, plural,
    =0 {No modification on appliance ports}
    one {Modification of the configuration on port {ports}.}
    other {Modification of the configuration on ports ({ports}).}
  }`,
  "device.unappliedChanges.CHANNEL_AUTO_SELECTION": `
    A channel change has been made on radio {radio} ({frequency} GHz) of the <strong>{name}</strong> appliance to improve performance.
    {linebreak}
    - Old channel: {oldChannelID, plural, =-1 {None} other {{oldChannelID} - {oldChannelBandwidth} MHz bandwidth}}
    {linebreak}
    - New channel: {newChannelID, plural, =-1 {None} other {{newChannelID} - {newChannelBandwidth} MHz bandwidth}}
  `,

  "device.replace": `Click confirm to finalize the replacement.`,
  "device.replace.noWarning": `The new equipment is fully compatible with your old router. The current configuration will be transferred to your new router.`,
  "device.replace.warning.intro": `The following configuration item(s) will not be migrated to your new appliance because of hardware limitations:`,
  "device.replace.success": `The appliance has been successfully replaced.`,
  "device.update.uptodate": `Up to date`,
  "device.update.outtodate": `Out to date`,
  "device.update.success": `Appliance <strong>{location}</strong> updated.`,
  "device.reboot": `This action will restart the appliance with the actual configuration. All users connected will lost their connections and will have to reconnect again.`,
  "device.reboot.modal.title": `Reboot appliance {name}.`,
  "device.reboot.success": `The appliance is rebooting.`,
  "device.reboot.forbidden": `You are not authorize to do this action.`,
  "device.reboot.failed": `The rebooting has failed, maybe your appliance is offline or already rebooting.`,
  "device.remove.note": `
    <p>Are you sure you want to remove this appliance?</p>
  `,
  "device.remove.BRIDGE": `Your access point {location} has been remove successfully.`,
  "device.remove.BRIDGE.content": `This action will remove the access point <strong>{location}</strong> from your configuration, and therefore, removing any broadcasted Wi-Fi networks from that device. Also, this will reset this device to manufacturing default settings.`,

  "device.remove.DRONE": `Your surveillance appliance {location} has been remove successfully.`,
  "device.remove.DRONE.content": `This action will remove the surveillance appliance <strong>{location}</strong> from your configuration. Also, this will reset this device to manufacturing default settings.`,

  "device.remove.ROUTER": `Your master router <strong>{location}</strong> has been remove successfully.`,
  "device.remove.ROUTER.hasChildren.content": `
    <strong>ATTENTION!</strong>
    <p>
      <strong>This appliance is a master router and cannot be removed</strong> since other appliances are linked to it, for example access points or any other SentinelC appliances.
    </p>
    <p>
      You must first remove all other appliances one-by-one before removing your Master Routeur.
    </p>
  `,
  "device.remove.ROUTER.content": `
    <p>
      This action will send a <strong>factory reset</strong> task to the appliance. Once the task completes successfully, the appliance will be removed from this location.
    </p>
    <p>
      The location, including all network settings and history will be kept. You may remove it afterwards using the <strong>Remove</strong> location operation.
    </p>
    <p>
      The appliance will remain claimed in your account and can be re-added to this location or another one. Use the <strong>Return to operator</strong> operation to return it.
    </p>
  `,

  "device.updateFirmware": `Appliances firmware update`,
  "device.updateFirmware.content": `The appliances' update schedule establish the moment at which an upate to a router can be installed. Installing an update would temporarly stop all network and Wi-Fi configured (less than 2 minutes).`,
  "device.updateFirmware.success": `Appliances' update in progress.`,
  "device.updateFirmware.error": `Appliances' update has failed.`,
  "device.updateFirmware.forbidden": `You are not authorized to do this action.`,

  "device.whitelisting.isUnknownAP": `
    This device is an unknown SentinelC Network appliance.{linebreak}
    Click Manage to add this appliance to your network.
  `,
  "device.whitelisting.isExternalAPOnTrunk": `
    <strong>This SentinelC Network appliance is not allowed on your network.</strong>{linebreak}
    Click <strong>Ignore</strong> and unplug this appliance.
  `,
  "device.whitelisting.isInternalAPOutOfTrunk": `
    <strong>This appliance is a one of your SentinelC Access point.</strong>{linebreak}
    Click <strong>Ignore</strong> and plug the appliance into an <strong>Access point port</strong> ({ports}).
  `,
  "device.whitelisting.isExternalAPOutOfTrunk": `
    <strong>This device is a SentinelC Network Appliance.</strong>{linebreak}
    If you intend to install an Access Point (AP), click <strong>Ignore</strong> and plug the appliance into an <strong>Access point port</strong> ({ports}).{linebreak}
    Otherwise, click <strong>Authorize</strong>.
  `,
  "device.whitelisting.isExternalAPOutOfTrunk.form.warning": `
   If you intend to add this <strong>access point</strong> to your network, please <strong>Cancel</strong> this operation and plug the equipment into an <strong>Access point</strong> type port.
  `,
  "device.whitelisting.isExternalAPOutOfTrunk.form.text": `
    By authorizing this equipment, it will be recognized as an independent master router which could either be associated with your SentinelC account or another account.
  `,
  "device.whitelisting.isHostInTrunk": `
    This device is <strong>NOT a SentinelC Network appliance</strong>.{linebreak}
    It cannot be authorized in an Access point type port.{linebreak}
    Click <strong>Ignore</strong>.
  `,
  "device.whitelisting.deletedWifi": `The Wi-Fi network that this device tried to connect to no longer exists. Click on <strong>Manage</strong> if you want to authorize it anyway on the <strong>{zone}</strong> zone. Otherwise, click <strong>Ignore</strong>`,
  "device.whitelisting.deletedWifi.short": `The Wi-Fi network that this device tried to connect to no longer exists.`,

  "device.isAPOutOfTrunk": `
    <strong>Verification of the connection with the main router in progress...</strong>{linebreak}
    Verify your appliance is plugged into an <strong>ACCESS POINT</strong> type port to enable full functionality.
  `,

  "device.tile.isAPOutOfTrunk": `
    Verification of the connection with the main router in progress...
  `,

  "device.whitelisting.connexionAttempt": `{count, plural, =0 {No connection attempt to an existing network} one {Connection attempt to a network} other {Connection attempt to {count} networks}}`,
  "device.whitelisting.modal.title": `Block access`,

  "device.contact.useOrganizationContact": `Use SentinelC account contact`,
  "device.contact.title": `Contact update`,
  "device.contact.content": `Use SentinelC account contact will delete all fields of the current contact.`,

  "device.replaceRouter.title": `Replace master router`,
  "device.replaceRouter.content": `This feature allows you to replace your main router without loss of configuration or data in case of failure or hardware problem.`,
  "device.replaceRouter.validDevice.message": `You are about to replace your actual master router <strong>{actualDevice}</strong> by a new device named <strong>{newDevice}</strong>.`,
  "device.replaceRouter.validDevice.message.withnotInUseAvailable": `You can choose one of the available appliances in the account or enter a valid registration key above:`,
  "device.replaceRouter.validDevice.content": `Your actual configuration will be restored in the new device. All past data acquired by the actual router will be saved and be available in the Activity menu.`,
  "device.replaceRouter.renameLocation.info": `If you want, you can replace the name of the new device.`,
  "device.replaceRouter.renameLocation.label": `New device identification`,
  "device.replaceRouter.complete.message": `Please note that the configuration transfer has been scheduled and may take a minute to complete as long as the new master router is plugged in.`,
  "device.replaceRouter.complete.content": `
    <p>
      If your old master router is still online, you can then unplug this equipment and plug in the new router if its not already plugged in.
    </p>
    <p>
      You also need to reconnect any wired devices that were plugged into the old master router.
    </p>
    <p>
      If hardware limitations mean that all old wired connections cannot be made to the new master router,
      then you will need to identify which SentinelC equipment these connections can be reconnected,
      unless these connections are no longer required.
    </p>
  `,

  "devices.title": `Devices`,
  "devices.unauthorized.title": `Unauthorized devices`,
  "devices.manage": `Manage devices`,
  "devices.last.connection": `Last connection`,
  "devices.last.attempt.deletedWifi": `Last attempt to connect on {date} at location <strong>{location}</strong> on restricted zone <strong>{zone}</strong>`,
  "devices.search.placeholder": `Enter a device name (3 characters min.)`,
  "devices.search.notfound": `No device found`,
  "devices.online": `{count, plural, =0 {No connected device} one {1 device online} other {{count} devices online}}`,
  "devices.onlineLong": `{count, plural, =0 {No connected device} one {1 device is actually online} other {{count} devices are actually online}}`,
  "devices.waiting": `{count, plural, =0 {No device waiting to be authorized} one {1 device waiting to be authorized} other {{count} devices waiting to be authorized}}`,
  "devices.waitingLong": `{count, plural, =0 {No device waiting to be authorized} one {1 device is actually waiting to be authorized} other {{count} devices are actually waiting to be authorized}}`,
  "devices.blocked": `{count, plural, =0 {No blocked device} one {1 device blocked} other {{count} devices blocked}}`,
  "devices.blockedLong": `{count, plural, =0 {No blocked device} one {1 device is actually blocked} other {{count} devices are actually blocked}}`,
  "devices.used.by": `Used by`,
  "devices.online.static": `Connected devices`,

  "devices.whitelisting.needAuthorization.transitionOff": `{count, plural,
    =0 {
      No device unauthorized
    } one {
      1 device unauthorized
    } other {
      {count} devices unauthorized
  }}`,
  "devices.whitelisting.message.newasking.transitionOff": `{count, plural,
    =0 {
      No device need permission to get access to your network.
    } one {
      1 device need permission to get access to your network.
    } other {
      {count} devices need permission to get access to your network.
  }}`,

  "devices.unauthorizedDevice.message.newasking.transitionOff": `{count, plural,
    =0 {
      No device need permission to connect to your network.
    } one {
      You must grant permission to this device to connect to your network.
    } other {
      You must grant permission to these devices to connect to your network.
  }}`,

  "surveillance.title": `Surveillance`,

  "surveillance.task.cancel": `Cancel this task`,
  "surveillance.task.cancelled.by": `Cancel by`,
  "surveillance.task.cancelled.on": `Cancel on`,
  "surveillance.task.designatedAppliance": `Designated appliance`,
  "surveillance.task.parameters": `Task parameters`,
  "surveillance.task.results": `Task results`,
  "surveillance.task.results.context": `Appliance context at execution time - firmware version`,

  "surveillance.tasks.title": `Tasks`,
  "surveillance.tasks.title.forAYear": `Volume of tasks in the last 12 months`,
  "surveillance.tasks.emptyMessage": `No task with the status "Pending" or "Started`,
  "surveillance.tasks.none": `No task`,
  "surveillance.tasks.none.filtered": `No task matches this combination of filters.`,

  "surveillance.ssids.none": `No Wi-Fi network`,
  "surveillance.ssids.none.filtered": `No Wi-Fi network matches this combination of filters.`,

  "surveillance.wifiSurveys.title": `Wi-Fi scans`,
  "surveillance.wifiSurveys.newWifiSurvey": `New Wi-Fi scan`,
  "surveillance.wifiSurveys.none": `No Wi-Fi scan`,
  "surveillance.wifiSurveys.none.filtered": `No Wi-Fi scan matches this combination of filters.`,
  "surveillance.wifiSurveys.requestedTime": `Scan performed on`,
  "surveillance.wifiSurveys.endTime": `End time`,
  "surveillance.wifiSurveys.ssidReported": `BSSID reported`,
  "surveillance.wifiSurveys.potentialRogue": `Potential rogue`,
  "surveillance.wifiSurveys.potentialRogue.linkTo": `View rogue Wi-Fi report`,

  "surveillance.rogueWifis.title": `Potential rogue Wi-Fi`,
  "surveillance.rogueWifis.bssid": `Bssid`,

  "surveillance.addWifiSurveys.title": `Wi-Fi scan form`,
  "surveillance.addWifiSurveys.subtitle": `Please note that it is possible to pre-populate this form by selecting an appliance from the Appliances menu of the administrative portal and selecting the <strong>Wi-Fi Survey action</strong> from the Action dropdown.`,
  "surveillance.addWifiSurvey.message.wifiSurveyStart": `A new Wi-Fi scan has been successfully created.`,

  "surveillance.releaseChannels.title": `# Appliances by release channels`,
  "surveillance.releaseChannels.legend.upd": `Up to date`,
  "surveillance.releaseChannels.legend.otd": `Obsolete`,

  "surveillance.ips.statsWeekly": `# IP events for the last 7 days`,
  "surveillance.ips.statsWeekly.length": `{count, plural, =0 {no IP event} one {1 IP event} other {{count} IP events}}`,
  "surveillance.ips.statsWeekly.none": `No IP event for the last 7 days`,
  "surveillance.dns.statsWeekly": `# DNS requests for the last 7 days`,
  "surveillance.dns.statsWeekly.length": `{count, plural, =0 {no request} one {1 request} other {{count} requests}}`,

  "surveillance.dns.title": `DNS logs`,
  "surveillance.dns.title.mostRecent": `Most recent DNS requests`,
  "surveillance.dns.title.subtitle": `Total DNS requests by MAC Address`,
  "surveillance.dns.filtered.noRequest": `No DNS request matches this combination of filters.`,
  "surveillance.dns.noRequest": `No DNS request.`,
  "surveillance.dns.stats.desc": `Aggregate statistics of all DNS queries made by devices.`,
  "surveillance.dns.ranking": `Ranking`,
  "surveillance.dns.ranking.desc": `Ranking DNS requests by domain name and client devices MAC addresses.`,
  "surveillance.dns.requests": `Requests`,
  "surveillance.dns.requests.desc": `True DNS requests made by client devices MAC addresses.`,
  "surveillance.dns.requests.type": `Request type`,
  "surveillance.dns.requests.code": `Response code`,
  "surveillance.dns.requests.summary": `Response summary`,
  "surveillance.dns.queryType.unsupported": `Unsupported type`,
  "surveillance.dns.queryType.error": `Invalid value`,
  "surveillance.dns.domainName": `Domain name`,
  "surveillance.dns.nbOfRequest": `# of request`,
  "surveillance.dns.filters.period.subtitle": `A request has been done during the selected period between the first seen and last seen.`,
  "surveillance.dns.filters.domain": `Domain`,
  "surveillance.dns.filters.domain.filterBy": `By domain level`,
  "surveillance.dns.filters.domain.highestLevel": `Highest level`,
  "surveillance.dns.filters.domain.requestedName": `Requested name`,
  "surveillance.dns.filters.responseCodes": `Response code`,
  "surveillance.dns.filters.responseCodes.filterBy": `By cesponse code`,
  "surveillance.dns.filters.responseCodes.NOERROR": `DNS Query completed successfully`,
  "surveillance.dns.filters.responseCodes.FORMERR": `DNS Query Format Error`,
  "surveillance.dns.filters.responseCodes.SERVFAIL": `Server failed to complete the DNS request`,
  "surveillance.dns.filters.responseCodes.NXDOMAIN": `Domain name does not exist`,
  "surveillance.dns.filters.responseCodes.NOTIMPL": `Function not implemented`,
  "surveillance.dns.filters.responseCodes.REFUSED": `The server refused to answer for the query`,
  "surveillance.dns.filters.responseCodes.YXDOMAIN": `Name that should not exist, does exist`,
  "surveillance.dns.filters.responseCodes.XRRSET": `RRset that should not exist, does exist`,
  "surveillance.dns.filters.responseCodes.NOTAUTH": `Server not authoritative for the zone`,
  "surveillance.dns.filters.responseCodes.NOTZONE": `Name not in zone`,
  "surveillance.dns.filters.queryTypes": `Request type`,
  "surveillance.dns.filters.queryTypes.filterBy": `By request type`,
  "surveillance.dns.filters.queryTypes.A": `<strong>Address record :</strong>{linebreak} Translates domain names to IPv4 addresses. Example: Web browsers like Chrome or Firefox perform A record requests to resolve domain names to IP addresses.`,
  "surveillance.dns.filters.queryTypes.AAAA": `<strong>IPv6 Address record :</strong>{linebreak} Resolves domain names to IPv6 addresses. Example: Modern web browsers and operating systems perform AAAA record requests for websites supporting IPv6.`,
  "surveillance.dns.filters.queryTypes.CNAME": `<strong>Canonical Name record :</strong>{linebreak} Frequently used for aliases, mapping one domain to another. .`,
  "surveillance.dns.filters.queryTypes.HTTPS": `<strong>HTTPS (HTTP Secure) DNS request type :</strong>{linebreak} Indicates support for secure communication over HTTP. Example: Browsers and applications, like Chrome or Firefox, use HTTPS DNS requests to determine if a domain supports encrypted connections via HTTPS, enabling secure data transmission between the client and server.`,
  "surveillance.dns.filters.queryTypes.NS": `<strong>Name Server record :</strong>{linebreak} Identifies authoritative DNS servers for a domain. Example: DNS resolvers query NS records to locate the authoritative name servers for a given domain.`,
  "surveillance.dns.filters.queryTypes.PTR": `<strong>Pointer record :</strong>{linebreak} Resolves an IP address to a domain name. Example: Reverse DNS lookups use PTR records to associate an IP address with a domain, often used in email server authentication.`,
  "surveillance.dns.filters.queryTypes.SRV": `<strong>Service record :</strong>{linebreak} Specifies information about available services in the network. Example: Used by applications like VoIP or instant messaging clients to discover service locations in the domain.`,
  "surveillance.dns.filters.queryTypes.TXT": `<strong>Text record :</strong>{linebreak} Carries human-readable information and is often used for various purposes. Example: SPF (Sender Policy Framework) records in DNS TXT format help prevent email spoofing.`,
  "surveillance.dns.filters.queryTypes.MX": `<strong>Mail Exchange record :</strong>{linebreak} Specifies mail servers for a domain. Example: Email servers use MX records to determine where to deliver emails for a particular domain.`,
  "surveillance.dns.filters.queryTypes.DNSKEY": `<strong>DNS Key record :</strong>{linebreak} Stores cryptographic keys for DNS Security Extensions (DNSSEC). Example: DNS resolvers use DNSKEY records to validate the authenticity of DNS data through DNSSEC.`,
  "surveillance.dns.filters.queryTypes.SOA": `<strong>Start of Authority record :</strong>{linebreak} Contains administrative information about the domain name, including the primary DNS server, the email of the domain administrator, the domain serial number, and timers for refreshing the zone. Example: The SOA record is crucial for maintaining the integrity of the DNS zone and coordinating updates between DNS servers.`,
  "surveillance.dns.filters.queryTypes.SVCB": `<strong>Service Binding record :</strong>{linebreak} Provides additional information about services offered by a domain, improving web performance and security. Example: Browsers like Chrome can use SVCB records to optimize connection establishment and resource retrieval.`,

  "surveillance.ports.title": `Ports under surveillance`,
  "surveillance.ports.desc": `This list displays ports that have been oppened recently or have been opened in the past.`,
  "surveillance.ports.lastScan": `Last scan`,
  "surveillance.ports.lastScanOn": `Last scan on`,
  "surveillance.ports.scanOn": `Scan on`,
  "surveillance.ports.state.message": `On the last port scan.`,
  "surveillance.hostPorts.history": `Detailed port history`,
  "surveillance.hostPorts.history.return": `Unselect this port`,
  "surveillance.hostPorts.none": `No scanned port`,
  "surveillance.hostPorts.none.filtered": `No scanned port matches this combination of filters.`,

  "surveillance.portScans.title": `Port scans`,
  "surveillance.portScans.title.project": `Port scans (for this project only)`,
  "surveillance.portScans.hostIsUp": `Device status`,
  "surveillance.portScans.button.disabled": `Impossible to scan ports of this device.`,
  "surveillance.portScans.none": `No port scan`,
  "surveillance.portScans.none.filtered": `No port scan matches this combination of filters.`,
  "surveillance.portScans.newPortScan": `New port scan`,  
  "surveillance.portScans.capturedBy": `Captured by`,
  "surveillance.portScans.openPorts": `Open ports`,
  "surveillance.portScans.nbOpenPort": `# open port(s)`,
  "surveillance.portScans.osDetectionReport": `OS detection report`,
  "surveillance.portScans.osDetectionReport.noResult": `The target device responded, but no information about its operating system could be detected.`,
  "surveillance.portScans.countMonitoredPorts": `Port(s) under surveillance`,
  "surveillance.portScans.closedPortsReport": `Closed port(s) report`,
  "surveillance.portScans.openPortsReport": `Interest port(s) report`,
  "surveillance.portScans.requestedTime": `Execution time`,
  "surveillance.portScans.requester": `Requester`,
  "surveillance.portScans.settings": `Port scan settings`,
  "surveillance.portScans.settings.tcp": `TCP port scan`,
  "surveillance.portScans.settings.udp": `UDP port scan`,
  "surveillance.portScans.osDetection": `OS detection`,
  "surveillance.portScans.tcp.CUSTOM": `Custom TCP scan port`,
  "surveillance.portScans.tcp.TOP_100": `Top 100 TCP ports`,
  "surveillance.portScans.tcp.TOP_1000": `Top 1000 TCP ports`,
  "surveillance.portScans.tcp.ALL": `All TCP ports`,
  "surveillance.portScans.udp.CUSTOM": `Custom UDP scan port`,
  "surveillance.portScans.udp.TOP_100": `Top 100 UDP ports`,
  "surveillance.portScans.udp.TOP_1000": `Top 1000 UDP ports`,
  "surveillance.portScans.udp.ALL": `All UDP ports`,
  "surveillance.portScans.viewPorts": `{count, plural, =0 {No port} one {View the port} other {View the {count} ports}}`,
  "surveillance.portScans.reschedulte": `Rescheduled this task`,
  "surveillance.portScans.productNVersion": `Product / version`,
  "surveillance.portScans.accuracy": `Accuracy %`,
  "surveillance.portScans.cpe": `Common platform enumeration (CPE)`,
  "surveillance.portScans.osFamily": `OS Family`,
  "surveillance.portScans.osGeneration": `OS Generation`,

  "surveillance.addPortScans.title": `New port scan`,
  "surveillance.addPortScans.subtitle": `Please note that it is possible to automatically load the data for this form by selecting a device from the <strong>Devices</strong> page.`,
  "surveillance.addPortScan.message.startPortScan": `New port scan created successfully.`,
  "surveillance.addPortScan.message.startPortScanAgain": `The port scan has been successfully rescheduled.`,
  "surveillance.addPortScan.message.portScanResultBulk": `The scan of ports has been successfully rescheduled.`,

  "surveillance.packetCaptures.title": `Packet captures`,
  "surveillance.packetCaptures.title.project": `Packet captures (for this project only)`,
  "surveillance.packetCaptures.none": `No packet captures`,
  "surveillance.packetCaptures.none.filtered": `No packet captures matches this combination of filters.`,
  "surveillance.packetCaptures.newPacketCapture": `New packet capture`,
  "surveillance.packetCaptures.capturedBy": `Captured by`,
  "surveillance.packetCaptures.requester": `Requester`,
  "surveillance.packetCaptures.requestedTime": `Execution time`,
  "surveillance.packetCaptures.cancelTime": `Cancel on`,
  "surveillance.packetCaptures.requestedDuration": `Requested duration (sec.)`,
  "surveillance.packetCaptures.nbMaxPackets": `Requested max # packets`,
  "surveillance.packetCaptures.nbPackets": `Number of captured packets`,
  "surveillance.packetCaptures.appliedFilters": `Applied filters`,
  "surveillance.packetCaptures.reschedulte": `Rescheduled this task`,
  "surveillance.packetCaptures.capturePackets": `Capture packets`,
  "surveillance.packetCaptures.capturePackets.disabled": `Impossible to capture. The account, location, appliance, or zone no longer exists.`,
  "surveillance.packetCaptures.capturePackets.disabledforInternalZone": `Impossible to capture the internal zone.`,

  "surveillance.addPacketCaptures.title": `Packet capture form`,
  "surveillance.addPacketCaptures.subtitle": `Please note that it is possible to automatically load the data for this form from the <strong>Devices</strong> and <strong>Connections</strong> pages.`,
  "surveillance.addPacketCaptures.tooMuchResultSearch": `Too much results, please enter some caracters`,
  "surveillance.addPacketCaptures.durationTime": `Duration time (sec.)`,
  "surveillance.addPacketCaptures.durationTime.subtitle": `max. 24h`,
  "surveillance.addPacketCaptures.nbPackets.subtitle": `max. 100000`,
  "surveillance.addPacketCaptures.filter.label": `Wireshark capture filter`,
  "surveillance.addPacketCaptures.filter.placeholder": `Ex.: host 192.168.8.15`,
  "surveillance.addPacketCaptures.filter.link": `See the Wireshark documentation to feed this field`,
  "surveillance.addPacketCaptures.message.updateCapture": `Packet capture has been successfully updated.`,
  "surveillance.addPacketCaptures.message.startCapture": `A new packet capture has been successfully created.`,
  "surveillance.addPacketCaptures.message.startCaptureAgain": `A new packet capture has been successfully rescheduled.`,
  "surveillance.addPacketCaptures.message.bulkAction": `Cancelation action successfully opered`,
  "surveillance.addPacketCaptures.radios.vlan.subtitle": `Lists only zones in use on this appliance.`,
  "surveillance.addPacketCaptures.radios.port.subtitle": `Lists only ports actually configured (excluding available ports).`,

  "incidents.title": `Incidents`,
  "incident.summary": `Incident summary`,
  "incident.task": `Associated task`,
  "incident.category": `Incident category`,
  "incident.toDoc": `To learn more about this incident and how to resolve it, please refer to the`,
  "incident.toDocLink": `related documentation`,
  "incident.supportNotes": `Support notes`,
  "incident.supportNotes.add": `Add a note`,
  "incident.supportNotes.add.placeholder": `Note for the person in charge of the incident.`,
  "incident.note.updatedOnBy": `Updated on {date} by {name}`,
  "incident.inexistant": `This incident has been deleted or doesn't exist.`,
  "incident.update.success":`Incident successfully updated.`,
  "incident.note.add.success":`Note added.`,
  "incident.note.update.success":`Note modified.`,
  "incident.note.remove.success":`Note deleted.`,

  "network.inexistant": `This network has been deleted, doesn't exist, or is removing.`,
  "network.activity": `Wi-Fi activity`,
  "network.PARTIALLYONLINE": `Partially online`,
  "network.OFFLINEDEVICES": `All broadcasting appliances are offline.`,
  "network.NO_AVAILABLE_RADIO": `None of the broadcasting appliances have a radio enabled or installed.`,
  "network.OFFLINE_NO_RADIO": `Some of the broadcasting appliances are in an offline state while others do not have transmitting radios.`,
  "network.broadcastedOn": `Broadcasted on`,
  "network.edit": `Edit Wi-Fi`,
  "network.updateStatus.stop.success.content": `The Wi-Fi network <strong>{network}</strong> has been successfully stopped!`,
  "network.updateStatus.start.success.content": `The Wi-Fi network <strong>{network}</strong> has been successfully started!`,
  "network.updateGeneric.success.content": `The configuration of the Wi-Fi network <strong>{network}</strong> has been successfully updated!`,
  "network.updateGeneric.pending.content": `The appliance is actually offline. {linebreak}The Wi-Fi network will be updated on your appliance when it will be back online !`,
  "network.addGeneric.success.content": `The Wi-Fi network <strong>{network}</strong> has been successfully added!`,
  "network.addGuestAndPortal.success.content": `the guest Wi-Fi <strong>{network}</strong> and public portal <strong>{portalnetwork}</strong> have been successfully added!`,
  "network.addGuest.success.content": `The guest Wi-Fi <strong>{network}</strong> has been successfully added!`,
  "network.addPortal.success.content": `The public portal <strong>{network}</strong> has been successfully added!`,
  "network.addPsk.success.content": `Secured Wi-Fi <strong>{network}</strong> has been successfully added!`,
  "network.pause.title": `Stop the {name} network`,
  "network.pause.content": `All of the devices connected to this network will lost their connection.`,
  "network.remove.title": `Deletion of network {name}`,
  "network.remove.content": `
    <p>You will delete a network. All of the devices connected to this network will lost their connection.</p>

    {count, plural,
      =0 {}
      other {
        <p>
          {count, plural,
            one {If your access point extends this network, its configuration}
            other {If one or several of your access points extend this network, their configuration}
          }
          will be modified and users connected to
          {count, plural,
            one {this access point}
            other {one of thoses access points}
          }
          will be disconnected.
        </p>
      }
    }

  `,
   "network.remove.content.dependencies": `
    <p>
      {count, plural,
        one {The linked network below will be deleted:}
        other {The linked networks below will be deleted:}
      }
    </p>
  `,
   "network.remove.content.irreversible": `
    <p>This action is irreversible!</p>
  `,
  "network.remove.content.irreversible.noMarkup": `This action is irreversible!`,
  "network.remove.content.content1": `You have requested a <strong>factory reset</strong> for the following appliance(s):`,

  "network.create.title": `Creation of {name} network`,
  "network.update.title": `Update of {name} network`,
  "network.update.content": `
    This operation could temporarily interrupt the connection of all devices connected to the appliances affected by this update.
   `,
  "network.update.rogues.part1": `We have identified exposed Wi-Fi network(s) in your perimeter having similarities with the name of your Wi-Fi <strong>{name}</strong>:`,
  "network.update.rogues.part2": `
    <p>Wi-Fi network names that are too similar could result in creating  confusion among your users.</p>
    <p>You can cancel this operation to change the name of your Wi-Fi network to make distinct or unique, otherwise click <strong>Continue</strong> to complete the operation.</p>
  `,
  "network.remove.success": `The <strong>{network}</strong> network has been successfully added!.`,
  "network.remove.error.portal": `You have to delete the public network <strong>{network}</strong> before deleting the portal.`,
  "network.name": `Wi-Fi name`,
  "network.security.OPEN": `None (OPEN)`,
  "network.security.PSK": `Private key (WPA2-PSK)`,
  "network.security.RADIUS_USERPSK": `Private key (WPA2-PSK)`,
  "network.type.securedPrivate.psk": `Secured private network - Key authentication`,
  get "network.type.PRIVATE.PSK"() {return this["network.type.securedPrivate.psk"]},
  get "network.type.private.psk"() {return this["network.type.securedPrivate.psk"]},
  "network.type.securedPublic": `Secured guest network - Access via portal`,
  "network.type.securedPublic.psk": `Secured guest network - Key authentication`,
  get "network.type.PUBLIC.PSK"() {return this["network.type.securedPublic.psk"]},
  get "network.type.PUBLIC.RADIUS_USERPSK"() {return this["network.type.securedPublic.psk"]},
  "network.type.portal": `Public portal network`,
  get "network.type.PORTAL.OPEN"() {return this["network.type.portal"]},
  get "network.offline"() {return this["generic.offline"]},
  get "network.DISABLED"() {return this["generic.stoppedState"]},
  "network.NOT_CONFIGURED":`Not configured`,
  get "network.OFFLINE"() {return this["generic.offline"]},
  get "network.PARTIAL"() {return this["generic.partial"]},
  get "network.ONLINE"() {return this["generic.online"]},

  "network.scheduledShutdown.closed.allDay": `all day`,
  "network.scheduledShutdown.closed.fromTo": `from {from} to {to} on {day}`,

  "network.psk": `Wi-Fi password`,
  "network.psk.copied": `Wi-Fi password copied in the clipboard!`,
  "network.psk.addToANewDeviceByQR": `QR code connection`,
  "network.psk.addToANewDeviceByQR.content": `Use this QR code with your mobile device, if QR code scanning is supported (out of the box or by an application).`,

  "network.add.vlan.restricted.title": `You have selected a restricted area`,
  "network.add.vlan.restricted.content": `In a restricted area, all connected devices need to be authorized.`,
  "network.add.vlan.noVlan": `No available zone.`,
  "network.add.vlan.noVlan.message": `The creation of Wi-Fi with shared key requires at least one available zone.{linebreak}You can create a zone by clicking on the following link:`,

  "network.psk.rotate.title": `Summary`,
  "network.psk.rotate.confirmBox.title": `Password confirmation`,
  "network.psk.rotate.confirmBox.content1": `The generated password of your Wi-Fi network is:`,
  "network.psk.rotate.confirmBox.content2": `
    You can access this password at any times by going to the <strong>{location}</strong> page and opening the Wi-Fi tab.  Then click on the
  `,
  "network.psk.rotate.confirmBox.content3": `
    menu for that network.
  `,

  "network.tile.offlineAppliances": `Normally broadcasted by {
    count, plural,
    =0 {no appliance}
    one {this offline appliance}
    other {theses offline appliances}
  }.`,

  "network.schedule.title": `Wi-Fi schedule`,
  "network.schedule.clickOnDay": `Click on a day to update the schedule`,
  "network.schedule.hasScheduleModale.header.start": `You can not start this Wi-Fi network because it is managed by an activity schedule.`,
  "network.schedule.hasScheduleModale.content.start": `
    <p>To be able to start it this way, you will need to disable the schedule for this network first.</p>
    <p>You can also change the schedule accordingly so that the startup is done immediately.</p>
  `,
  "network.schedule.hasScheduleModale.header.stop": `You can not stop this Wi-Fi network because it is managed by an activity schedule.`,
  "network.schedule.hasScheduleModale.content.stop": `
    <p>To be able to stop it this way, you will need to disable the schedule for this network first.</p>
    <p>You can also change the schedule accordingly so that it stops immediately.</p>
  `,
  "network.schedule.planifiedStop": `Scheduled shutdown`,
  "network.schedule.on": `Schedule is on`,
  "network.schedule.off": `Schedule is off`,
  "network.schedule.update": `Update schedule`,
  "network.schedule.modal.message.title": `You are about to disable your network schedule.`,
  "network.schedule.modal.message.content": `This could affect network availability for your users.`,
  "network.schedule.modal.content": `After disabling this schedule, do you want this network to be:`,
  "network.schedule.modal.content.noRadio": `Be advised that there is no appliance currently able to broadcast this Wi-Fi. {linebreak}What state do you want this Wi-Fi to be in when it will be possible to broadcast it?`,
  "network.schedule.toggle": `Schedule management`,
  "network.schedule.toggle.infos": `Allows management of Wi-Fi network activity schedules. It is possible to set schedules per day and define up to 5 activity ranges during which users can connect to them.`,
  "network.schedule.warning": `Users connected to this network will be disconnected if you apply a schedule considering this current time as closed.`,
  "network.schedule.day.OPEN": `Open 24 hours`,
  "network.schedule.day.CLOSED": `Closed all day`,
  "network.schedule.day.VARIES": `Schedule`,
  "network.schedule.day.copy": `Same as ...`,
  "network.schedule.day.copy.placeholder": `Select a day schedule to copy`,
  "network.schedule.day.error": `Error: Intervals can't include each others!`,
  "network.schedule.day.addInterval": `Add a line`,

  // Stats ============================================
  "stats.sunburst.button.show": `View global operational status`,
  "stats.sunburst.button.hide": `close details`,
  "stats.sunburst.legend.up": `Fully functional`,
  "stats.sunburst.legend.down": `Not functional`,
  "stats.sunburst.legend.partial": `Partially functional`,
  "stats.sunburst.subtitle": `Click on pie chart to display specific operational status`,
  "stats.sunburst.subtitle.generic": `Click on pie chart to go to details page`,
  "stats.sunburst.mention": `* with at least one appliance.`,
  "stats.sunburst.noAvailableData": `No data available.`,
  "stats.sunburst.pie.noAccount": `No account with at least one appliance.`,
  "stats.sunburst.pie.noLocation": `No location with at least one appliance.`,
  "stats.sunburst.pie.noAppliance": `No appliance.`,
  "stats.sunburst.spread.null": `Operational status`,
  "stats.sunburst.spread.accounts": `Operational status of accounts`,
  "stats.sunburst.spread.locations": `Operational status of locations`,
  "stats.sunburst.spread.devices": `Operational status of appliances`,

  "stats.map": `Map of locations with at least one offline appliance`,
  "stats.map.desc": `Click on location for details.`,
  "stats.map.locations.allGood": `All locations are fully operational`,
  
  "stats.noData": `No data available on the period.`,
  "stats.noDataToday": `No data available today.`,
  "stats.dataToday": `Today's data`,
  "stats.activity.bandwidth.usage": `Bandwidth Usage`,
  "stats.activity.bandwidth.upload": `Upload`,
  "stats.activity.bandwidth.download": `Download`,
  "stats.activity.average": `Average is`,
  "stats.activity.usage": `usage during the selected period.`,
  "stats.activity.timeInterval": `Time interval`,
  "stats.activity.average.last7": `--- The average of the last 7 days is {data}`,
  "stats.activity.average.above.noPercent": `above average`,
  "stats.activity.average.below.noPercent": `below average`,
  "stats.activity.average.current": `Current average`,
  "stats.activity.average.previous": `Previous average`,
  "stats.activity.average.prevPeriod": `Current average compared to the average of the previous period.`,
  "stats.activity.unique.connection": `Unique Connections`,
  "stats.activity.devices.top": `Top devices activity`,
  "stats.activity.active.connection": `Active connections`,
  "stats.activity.connection.total": `{count, plural, =0 {Total{linebreak} connection} one {Total{linebreak} connection} other {Total{linebreak} connections}}`,

  "stats.populations.title": `Population data`,
  "stats.populations.devicesByLocation": `Appliances by location`,
  "stats.populations.hostsByLocation": `Hosts by location`,
  "stats.populations.locationsByAccount": `Locations by account`,

  "stats.dns.title": `DNS statistics`,
  "stats.dns.topdomains": `DNS top domains`,
  "stats.dns.overview.breakdown": `DNS requets breakdown for {domain}`,
  "stats.dns.overview.byHost": `Domain {domain} requested by MAC address`,

  // FORMS ============================================
  "forms.generic.modalUpdatePending.title": `Updates pending`,
  "forms.generic.modalUpdatePending.content": `
    <p>You are about too leave this page while there are updates pending.</p>
    <p>All changes will be lost.</p>
  `,

  "forms.generic.token": `Token`,
  "forms.generic.token.placeholder": `123456`,
  "forms.generic.firstName": `Firstname`,
  "forms.generic.lastName": `Lastname`,
  "forms.generic.phoneNumber": `Phone number`,
  "forms.generic.phoneNumber.placeholder": `+1 514 123 4567 or +15141234567`,
  "forms.generic.password": `Password`,
  "forms.generic.email": `Email`,
  "forms.generic.email.placeholder": `Email address`,
  "forms.generic.productKey": `Product key`,
  "forms.generic.optionalMention": `(optional)`,
  "forms.generic.checkboxterms": `I read and agree to the terms and conditions`,
  "forms.generic.search": `Search...`,
  "forms.generic.search.appliances.results": `{count, plural, =0 {No appliance found} one {1 appliance found} other {{count} appliances found}}`,
  "forms.generic.search.appliances.viewAll": `{count, plural, =0 {No appliance found} one {View the appliance found} other {View the {count} appliances found...}}`,
  "forms.generic.search.accounts.results": `{count, plural, =0 {No accounts found} one {1 accounts found} other {{count} accounts found}}`,
  "forms.generic.search.accounts.viewAll": `{count, plural, =0 {No accounts found} one {View the account found} other {View the {count} accounts found...}}`,
  "forms.generic.search.locations.results": `{count, plural, =0 {No locations found} one {1 location found} other {{count} locations found}}`,
  "forms.generic.search.locations.viewAll": `{count, plural, =0 {No locations found} one {View the location found} other {View the {count} locations found...}}`,
  "forms.generic.search.incidents.results": `{count, plural, =0 {No incident found} one {1 incident found} other {{count} incidents found}}`,
  "forms.generic.search.incidents.viewAll": `{count, plural, =0 {No incident found} one {View the incident found...} other {View the {count} incidents found...}}`,
  "forms.generic.search.hosts.results": `{count, plural, =0 {No host found} one {1 host found} other {{count} hosts found}}`,
  "forms.generic.search.hosts.viewAll": `{count, plural, =0 {No host found} one {View the host found...} other {View the {count} hosts found...}}`,
  "forms.generic.search.connections.results": `{count, plural, =0 {No connection found} one {1 connection found} other {{count} connections found}}`,
  "forms.generic.search.connections.viewAll": `{count, plural, =0 {No connection found} one {View the connection found...} other {View the {count} connections found...}}`,
  "forms.generic.selectLanguage": `Select your language`,
  "forms.generic.selectMode": `Select configuration`,
  "forms.generic.warning": `ATTENTION! This action is irreversible.`,
  "forms.generic.warning2": `CAREFUL - This action is irreversible!`,
  "forms.generic.warning.reset": `This action will also reset the SentinelC appliance to manufacturing default settings.`,
  "forms.generic.warning.remove": `It will remove {location} and all Wi-Fi points configured at this location.`,

  // User form
  "forms.user.photo": `Photo`,
  "forms.user.enterActualPassword": `Enter actual password`,
  "forms.user.enterPassword": `Enter new password`,
  "forms.user.confirmPassword": `Confirm new password`,
  "forms.user.password.validationBox.title": `Your password needs to:`,
  "forms.user.password.validationBox.rule1": `be at least 12 characters long.`,
  "forms.user.password.validationBox.rule2": `include both lower and upper case characters.`,
  "forms.user.password.validationBox.rule3": `include both number and symbol.`,

  // Organization form
  "forms.organization.name": `Account name (company, family, group, team, etc.)`,
  "forms.organization.name.placeholder": `Ex.: Company Inc., Home, etc.`,
  "forms.organization.tagline": `Tagline`,
  "forms.organization.tagline.placeholder": `Tagline`,
  "forms.organization.logo": `Logo`,
  "forms.organization.logo.optional": `(optional, useful for public portal Wi-Fi network)`,
  "forms.organization.ownerFirstName": `Firstname`,
  "forms.organization.ownerLastName": `Lastname`,
  "forms.organization.ownerEmail": `Email`,
  "forms.organization.ownerEmail.placeholder": `owner@domain.com`,

  // Setup networks form
  "forms.networks.selectVlan": `You must add this Wi-Fi network in one of the zones associated with your account.`,
  "forms.networks.addedToVlan": `This network will be added to the following restricted zone:`,
  "forms.networks.addedToVlan.generic": `The network will be added to the following zone:`,
  "forms.networks.selectVlan.label": `Select the appropriate zone for this Wi-Fi`,
  "forms.networks.selectDevices.label": `{count, plural,
    =0 {Select an appliance to broadcast this Wi-Fi}
    one {Select an appliance to broadcast this Wi-Fi}
    other {Select appliances to broadcast this Wi-Fi}

  }`,

  "forms.networks.addPortal.vlan.content": `Special zone reserved to the portal Wi-Fi network.`,
  "forms.networks.addPublic.vlan.content": `Special zone reserved to public Wi-Fi networks.`,

  "forms.networks.deviceLocation": `Appliance location`,
  "forms.networks.deviceLocation.placeholder": `First floor, Waiting room, ...`,
  "forms.networks.privateNetworkNote": `Notice than all of the {exist, plural, =0 {} other {following }}networks are part of the same private zone that communicates with all the devices and resources connected to it.`,

  "forms.networks.privateNetworkName.sublabel": `Letters, digits, hyphen (-) and underscore (_)`,
  "forms.networks.portalNetworkName": `Name of the public Wi-Fi Portal (Open)`,
  "forms.networks.pskNetworkName": `Name of the shared key network (WPA2)`,
  "forms.networks.pskNetworkName.placeholder": `Ex.: Living, Hall, Basement, Garage, Office, etc.`,

  "forms.networks.pskNetworkPwd": `Network password`,
  "forms.networks.pskNetworkPwd.info": `The password of your network will be automatically generated and displayed after creation.`,
  "forms.networks.pskNetworkPwd.rotate": `
    <strong>Regenerate a new password</strong>{linebreak}
    This action will disconnect any device from this network.
  `,
  "forms.networks.pskNetworkPwd.sublabel": `The password of your network will be automatically generated.`,

  "forms.networks.publicNetwork.use": `Usage`,
  "forms.networks.publicNetwork.use.home": `At home`,
  "forms.networks.publicNetwork.use.home.tooltip": `Your guests will need an access key to connect this network. You must share the key (passzord) you defined for this network.`,
  "forms.networks.publicNetwork.use.enterprise": `Enterprise`,
  "forms.networks.publicNetwork.use.enterprise.unavailable": `1 available network, 2 required`,
  "forms.networks.publicNetwork.use.enterprise.tooltip": `Your guests will need a personnal access key to connect this network. They must obtain a key by connecting to your Portal network, wich is required to create an Enterprise Guest Wi-Fi. Limited to one Enterprise Guest network.`,
  "forms.networks.publicNetworkName": `Name of the Guest Wi-Fi network (WPA2)`,
  "forms.networks.publicNetworkName.placeholder": `Guest Wi-Fi`,
  "forms.networks.networkUser.manage": `Manage user access`,
  "forms.networks.networkManager.label": `Network administrator(s)`,
  "forms.networks.networkManager.add": `Manage network administrators`,
  "forms.networks.networkManager.modal.title": `Manage network administrators`,
  "forms.networks.networkManager.modal.text": `
    <p>
      Select any user that you want to give/remove network administrator privileges. Please note that theses privileges allow full access to administer networks.
    </p>
  `,

  // /FORMS ===========================================

  // ONBOARDING =======================================

  // Step 0
  "onboarding.step0.header.title": `Installation for a secured access point`,
  "onboarding.step0.header.subtitle": `We protect your networks`,
  "onboarding.step0.title": `Installation procedure`,
  "onboarding.step0.content": `
    <p>You are now ready to proceed to the installation of your SentinelC access point.</p>
    <p>The configuration and activation process of your secure networks will only take a few minutes.</p>
    <p><small>You can interrupt the process at any time and resume it later.*</small></p>
    <p>Have a nice installation!</p>
    <em><small>* Your data will be saved for 24 hours.</small></em>
  `,

  // Step 1
  "onboarding.step1.title": `Step 1 - Register your appliance`,
  "onboarding.step1.updateDevice.title": `Appliance update`,
  "onboarding.step1.updateDevice.retry": `Retry appliance update`,
  "onboarding.step1.updateDevice.message": `Your appliance must be updated`,
  "onboarding.step1.updateDevice.desc": `
    <p>
      Installation can take up a couple of minutes depending the speed of your Internet connection.{linebreak}You will redirected to the next step after the update.
    </p>
    <p><strong>
      Please don't shut down your SentinelC appliance during the update.
    </strong></p>
  `,
  "onboarding.step1.updateDevice.error": `This installation update has failed !{linebreak} Please retry.`,
  "onboarding.step1.content": `
    <p>Please enter the product key that is printed inside the Quick Setup Guide.</p>
  `,

  // Step 1b
  "onboarding.step1.noCompleted.title": `Your registration is not completed`,
  "onboarding.step1.noCompleted.content":`
    <p>The registration was started but not finished for your appliance ({deviceLocation}), we saved your progression and informations already set.</p>
  `,
  "onboarding.step1.noCompleted.button": `Continue registration`,

  // Step 1c
  "onboarding.step1.waitingActivation.title": `Your registration is waiting for activation`,
  "onboarding.step1.waitingActivation.content":`
    <p>Please click on the activation link sent at the email address: <strong>{emailAddress}</strong></p>
  `,
  "onboarding.step1.waitingActivation.list.intro": `If you didn’t receive the email:`,
  "onboarding.step1.waitingActivation.list.item1": `Check on the spam box`,
  "onboarding.step1.waitingActivation.list.item2": `Send another email`,
  "onboarding.step1.waitingActivation.messages.sendSuccess": `Activation link sent to {emailAddress}`,

  // Step 2
  "onboarding.step2.title": `Step 2 - Register your SentinelC account`,
  "onboarding.step2.content":`
    <p>This step allows you to create a SentinelC account. Your router, networks, users and authorized devices will be associated to this account.</p>
  `,

  // Step 3
  "onboarding.step3.title": `Step 3 - Create an administrator profile`,
  "onboarding.step3.content":`
    <p>This step allows you to create a user profile to manage the security of your networks security and manage access to them in detail.</p>
  `,
  "onboarding.step3.useOwnerUser": `Use the SentinelC account's contact information for creating the administrator profile.`,
  "onboarding.step3.password.info": `You will be invited to set your password when activating your profile from a link you received at your email address.`,

  // Step 4
  "onboarding.step4.title": `Step 4 - Create a secured network`,
  "onboarding.step4.content":`
    <p>This step allows you to configure your first private and secured Wi-Fi network.</p>
  `,
  "onboarding.step4.network.wantAWifi": `Do you want to create a Wi-Fi network now?`,
  "onboarding.step4.network.wantAWifi.note": `You will be able to create Wi-Fi networks later from the application.`,
  "onboarding.step4.network.update": `Updates schedule of appliances`,
  "onboarding.step4.network.noRadio.title": `This appliance can not broadcast Wi-Fi network`,
  "onboarding.step4.network.noRadio.content": `This appliance has no radio to broadcast Wi-Fi networks.`,

  // Step 5
  "onboarding.step5.1.title": `Step 5 - Configuration summary`,
  "onboarding.step5.1.organizationInformations": `SentinelC account`,
  "onboarding.step5.1.ownerInformations": `Contact`,
  "onboarding.step5.2.adminInformations": `Administration profile`,
  "onboarding.step5.3.networksInformations": `Networks`,
  // Step 6 - waiting for activation / reactivation
  "onboarding.step6.waitingActivation.title": `Congratulation you finished the onboarding process!`,
  "onboarding.step6.waitingActivation.content":`
    <p>You will receive soon an activation email at <strong>{emailAddress}</strong>, your network will be available after the activation.</p>
  `,
  "onboarding.step6.waitingActivation.list.intro": `If you didn’t receive the email after a couple of minutes:`,

  "onboarding.step6bis.waitingActivation.content":`
    <p>The activation email has not been accepted for email address <strong>{emailAddress}</strong>, your network won't be available until your click on the activation link in this email.</p>
  `,
  "onboarding.step6bis.waitingActivation.list.intro": `Please check if your inbox is not full.`,
  
  "onboarding.step6bis.reactivated.content": `<p>Your email account had been deactivated, but it will be reactivated.</p><p>Please log in by clicking the button below.</p>`,


  // Installation process
  "installation.plugInstructions.expansionButton": `How to plug the appliance`,
  "onboarding.step7.installationProcess.title": `Configuration of the SentinelC appliance`,
  "onboarding.step7.installationProcess.waitingForDevice": `Please wait, installation in progress `,
  "onboarding.step7.installationProcess.update.redirectIn": `You will be redirect to the finalization page in {time}`,
  "onboarding.step7.installationProcess.onSuccess.title": `The installation is completed.`,
  "onboarding.step7.installationProcess.onSuccess.info.PSK": `
    <p><strong>Installation successfully completed</strong></p>
    <p>
      You can now connect to your network «<strong>{ssid}</strong>» with the password you defined previously.
    </p>
  `,

  "onboarding.step7.installationProcess.onSuccess.accessToApp": `Go to application`,
  "onboarding.step7.installationProcess.onSuccess.valid": `Your account is activated!`,
  "onboarding.step7.installationProcess.onError.title": `An error occured during the installation:`,
  "onboarding.step7.installationProcess.onError.offlineDevice": `Your appliance seems offline, please verify the Internet connection to the appliance.`,
  "onboarding.step7.installationProcess.onError.offlineDeviceLong": `Your appliance seems offline, please verify the Internet connection to the appliance to complete its configuration.`,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions1": `
    <p>It's now time to plug your SentinelC to the Internet.</p>
    <p><em>If you already have a router, you must disconnected it of the modem. If you have a combo modem-router, You must deactivate the router function from the appliance configuration panel (call your provider if you don't know how to proceed).</em></p>
    <p>Verify the Internet cable is plugged between your modem and the Internet plug behind your appliance SentinelC</p>
  `,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions2": `<p>Please check the lights on the front of your appliance, they must be on as describe just below:</p>`,
  "onboarding.step7.installationProcess.onError.offlineDevice.instructions3": `<p>After thoses verifications are done you can start to apply the configuration.</p>`,
  "onboarding.step7.installationProcess.onError.offlineDevice.startConfiguration":`Start the configuration`,

  // /ONBOARDING ======================================

  // BACKOFFICE =======================================
  "backOffice.404": `
    <p>
      This page doesn't exist or you don't access to it.
    </p>
  `,
  "backOffice.backToDashboard": `Exit admin console`,
  "backOffice.adminPortal": `Admin console`,
  "backOffice.sinceTheBeginning": `Since the beginning`,
  "backOffice.pendingTasks": `Pending tasks`,
  "backOffice.failedTasks": `Failed tasks`,
  "backOffice.lastConnectionValue": `Last connection value.`,
  "backOffice.lastPortScanValue": `Last port scan value.`,
  "backOffice.hasOpenIncidents": `{count, plural, =0 {No open incident} one {1 open incident} other {{count} open incidents}}.`,
  "backOffice.isPrivileged": `Your user profile has <strong>Privileged</strong> permission.`,
  "backOffice.isPrivileged.long": `This level of permissions give you full access to all available data and actions on this website.`,
  "backOffice.isAuditor": `Your user profile has <strong>Auditor</strong> permissions.`,
  "backOffice.isAuditor.long": `This level of permissions give you a read only access to data on this website.`,
  
  get "backOffice.components.packetCaptures"() {return this["surveillance.packetCaptures.title"]},
  get "backOffice.components.portScan"() {return this["surveillance.portScans.title"]},
  "backOffice.components.disks": `Disks`,
    "backOffice.components.disks.complete": `Full documentation on disks`,
  "backOffice.components.disks.statuses": `Understand disk statuses`,
  "backOffice.components.disks.usages": `Understand disk usages`,
  "backOffice.components.wifi": `Wi-Fi information`,
  get "backOffice.components.incidents"() {return this["backOffice.home.recentOpenIncidents"]},
  "backOffice.components.removeComponent": `Remove component`,
  get "backOffice.components.appliances"() {return this["generic.appliances"]},
  get "backOffice.components.monitoringEvents"() {return this["backOffice.services.monitoringEvents"]},
  get "backOffice.components.ports"() {return this["generic.networkPorts"]},
  get "backOffice.components.logs"() {return this["generic.logs"]},
  get "backOffice.components.stats"() {return this["generic.statistics"]},
  get "backOffice.components.services"() {return this["generic.services"]},
  get "backOffice.components.tasks"() {return this["generic.tasks"]},

  "backOffice.logs.unavailable": `Unavailable data for this appliance.`,
  "backOffice.logs.unavailable.pod": `Unavailable data for this occurence`,
  
  "backOffice.pod.serviceId": `Service ID`,
  "backOffice.pod.startOption": `Start option`,
  "backOffice.pod.startOption.filterBy": `By start option`,
  "backOffice.pod.startOption.onDemand": `On demand`,
  "backOffice.pod.startOption.restart": `Restart by default`,
  "backOffice.pod.deployedOn": `Deployed on appliance`,
  "backOffice.pod.applianceType": `Appliance type`,

  "backOffice.disks.title": `Disks and partitions`,
  "backOffice.disks.component.subtitle": `Disks space level`,
  "generic.disks.none.withFilter": `No disk matches this combination of filters.`,
  "backOffice.disks.status": `Disk status`,
  "backOffice.disks.status.filterBy": `By disk status`,

  "backOffice.service.inexistant": `This service has been deleted, doesn't exist.`,
  "backOffice.service.inexistant.return": `Return to the list of embedded services`,
  "backOffice.service.advancedParameters": `Advanced service parameters`,
  "backOffice.service.parameters": `Parameters`,
  "backOffice.service.gotoUI": `Link to web based interface`,
  "backOffice.service.networking.ports": `N# of required ports`,
  "backOffice.service.networking.vlan": `N# of required zones`,
  "backOffice.service.port": `Physical port`,
  "backOffice.service.ports": `Physical ports`,
  "backOffice.service.ports.cloud": `Allow proxy cloud access`,
  "backOffice.service.ports.vlan": `Allow local access in the defined zone`,
  "backOffice.service.forceStopAll": `Stop all`,
  "backOffice.service.viewAllManage": `View all and manage`,
  "backOffice.service.minimumMemoryRequired": `Minimum memory required`,
  "backOffice.service.minimumStorageRequired": `Minimum storage required`,
  "backOffice.service.message.bulk": `Service(s) successfully updated`,
  "backOffice.service.message.update": `Service successfully updated`,
  "backOffice.service.message.bulkpods": `Service(s) successfully updated`,
  "backOffice.service.message.parametersForm": `Parameters form`,
  "backOffice.service.message.add": `Service installation has been initiated. Please note it could take several minutes to complete. You can track the progress by viewing the service status on this appliance.`,
  "backOffice.service.message.add.secrets": `ATTENTION: Parameters highlighted in yellow are secret values that will not appear again. Please take note of them.`,
  "backOffice.service.addAnotherService": `Do you want install another service?`,
  "backOffice.service.uninstalled": `Service uninstalled`,

  "backOffice.services.monitoringEvents": `Monitoring events`,
  "backOffice.services.monitoringEvents.metadata": `Metadata Yaml`,
  "backOffice.services.monitoringEvents.metadata.none": `No metadata`,
  "backOffice.services.monitoringEvents.none": `No event`,
  "backOffice.services.monitoringEvents.none.withDates": `No event between {from} and {to}.`,
  "backOffice.services.monitoringEvents.none.withFilter": `No event with this combination of filters.`,
  "backOffice.services.monitoringEvents.filters.subtitle": `The selected period must not exceed 15 days`,
  "backOffice.services.monitoringEvents.filters.errorPeriodSup60": `The selected period exceeds 15 days`,
  "backOffice.services.monitoringEvents.filters.errorMissingDate": `All fields are mandatory`,

  "backOffice.services.projectRelated": `Project related`,
  "backOffice.services.embeddedService": `Embedded service`,
  "backOffice.services.embeddedService.instanceOf": `An instance of library service`,
  "backOffice.services.embeddedService.instanceDeployed": `Instances of {name} deployed on appliance(s)`,
  "backOffice.services.embeddedServiceDetails": `Parameters`,
  "backOffice.services.embeddedServices": `Embedded services`,
  "backOffice.services.embeddedServices.long": `Embedded services by appliances`,
  "backOffice.services.embeddedServices.viewDetails": `Voir les détails du service`,
  "backOffice.services.embeddedServices.viewAll": `{count, plural, =0 {No services} one {See the details of the deployed service} other {See the details of the {count} deployed services}}`,

  "backOffice.services.title": `Services library`,
  "backOffice.services.source": `Source`,
  "backOffice.services.source.filterBy": `By source`,
  "backOffice.services.serviceSource": `Service source`,
  "backOffice.services.serviceSource.filterBy": `By source`,
  "backOffice.services.serviceState": `Service state`,
  "backOffice.services.serviceState.filterBy": `By state`,
  "backOffice.services.homepage": `Homepage`,
  "backOffice.services.installAService": `Install a service`,
  "backOffice.services.installAService.selectAService": `Select a service to install on this appliance`,
  "backOffice.services.installAService.selectAZone": `Select a zone where to install this service`,
  "backOffice.services.documentation": `Documentation`,
  "backOffice.services.upToDate": `Up to date`,
  "backOffice.services.upToDate.filterBy": `By up to date status`,
  "backOffice.services.upToDate.YES": `Yes`,
  "backOffice.services.upToDate.NO": `No`,
  "backOffice.services.upToDate.NO.popup": `Latest version is {version}`,

  "backOffice.ssid.noSsid": `The Wi-Fi network doesn't exist or has been deleted.`,
  "backOffice.ssid.edit": `Edit a Wi-Fi network`,
  "backOffice.ssid.type": `Wi-Fi network type`,
  "backOffice.ssid.type.private.psk": `Private`,
  "backOffice.ssid.type.private.psk.explanation": `This type of network enables all devices connected to share network ressources securely.`,
  "backOffice.ssid.type.public.psk": `Guest`,
  "backOffice.ssid.type.public.psk.explanation": `This type of network enables secured internet access only to your guest or visitors without sharing network ressources.`,
  "backOffice.ssid.start": `Start Wi-Fi network`,
  "backOffice.ssid.stop": `Stop Wi-Fi network`,
  "backOffice.ssid.specifications": `Wi-Fi specifications`,
  "backOffice.ssid.selectALocation": `Select a location...`,
  "backOffice.ssid.noAppliance": `No appliance, Wi-fi will be created but it won't be emitted.`,
  "backOffice.ssid.noApplianceSelected": `No appliance selected, Wi-fi won't be emitted anymore.`,  
  "backOffice.ssid.noApplianceSelected.create": `No appliance selected, Wi-fi will be created but it won't be emitted.`,

  "backOffice.ip.title": `IP events`,
  "backOffice.ip.summary.title": `Event analysis`,
  "backOffice.interceptions.interceptions": `Interception(s)`,
  "backOffice.interceptions.reportList": `Blacklisted by`,
  "backOffice.interceptions.nbPackets": `Total # of packet(s)`,
  "backOffice.interceptions.nbPackets.source": `Packet(s) from source`,
  "backOffice.interceptions.nbPackets.destination": `Packet(s) from destination`,
  "backOffice.interceptions.date": `Interception date`,
  "backOffice.interceptions.none": `No interception.`,
  "backOffice.interceptions.none.filtered": `No interception matches this combination of filters.`,
  "backOffice.interceptions.noInterception": `No interception.`,
  "backOffice.interceptions.filtered.noInterception": `No interception matches this combination of filters.`,

  "backOffice.interceptions.title": `IP events`,
  "backOffice.interceptions.desc": `List of IPs requested identified in one or more blacklists OR related to a port forward configuration.`,
  "backOffice.interceptions.title.mostRecent": `Most recent IP events`,
  "backOffice.interceptions.relatedIPEvents": `Related IP events`,
  "backOffice.interceptions.relatedIPEvents.link": `View events`,
  "backOffice.interceptions.name": `IP / Domain name`,
  "backOffice.interceptions.direction": `Direction`,
  "backOffice.interceptions.thresdhold": `Thresdhold`,
  "backOffice.interceptions.packetSize": `Packet size`,
  "backOffice.interceptions.source": `Source`,
  "backOffice.interceptions.sourcePort": `Source port`,
  "backOffice.interceptions.destination": `Destination`,
  "backOffice.interceptions.destPort": `Destination port`,
  "backOffice.interceptions.nbDetections": `# of detections`,
  "backOffice.interceptions.reaction": `Reaction`,
  "backOffice.interceptions.nbEvents": `# events`,
  "backOffice.interceptions.firstSeenEver": `First seen ever`,
  "backOffice.interceptions.location": `Geolocation`,
  "backOffice.interceptions.organization": `Organization`,
  "backOffice.interceptions.firstSeenOnMAC": `First seen on MAC address`,
  "backOffice.interceptions.nbEventsRecorded": `# of events recorded`,
  "backOffice.interceptions.associatedDNSRequest": `DNS request(s) associated`,
  "backOffice.interceptions.associatedDNSRequest.count": `{count, plural, =0 {No request} one {1 request} other{{count} requests}}`,
  "backOffice.interceptions.tableTitle": `This IP has been recorded in the following account(s) and location(s)`,

  "backOffice.appliance.services.currentState": `Current state`,
  "backOffice.appliance.services.currentState.crashed.desc": `Be aware that this service was running before being stopped without any administrator intervention.`,
  "backOffice.appliance.services.currentState.crashed.retries": `{count, plural, =0 {no automatic restart} one {1 automatic restart} other{{count} automatic restarts}}`,
  "backOffice.appliance.services.deployedZone": `Deployed zone`,
  "backOffice.appliance.services.fullyOperational": `Fully operational`,
  "backOffice.appliance.services.notOperational": `Not operational`,
  "backOffice.appliance.services.none": `There is no service installed yet.`,
  "backOffice.appliance.services.noneAvailable": `No service available.`,
  "backOffice.appliance.services.startBtn": `Start service`,
  "backOffice.appliance.services.stopBtn": `Stop service`,
  "backOffice.appliance.services.logsBtn": `Show logs`,
  "backOffice.appliance.services.removeBtn": `Uninstall service`,
  "backOffice.appliance.services.UNINSTALL": `Uninstall service`,  
  "backOffice.appliance.services.UNINSTALL.count": `{count, plural, =0 {No service to uninstall} one {Uninstall the service} other {Uninstall services}}`,
  "backOffice.appliance.services.UNINSTALL.text": `Are you sure to uninstall {count, plural, =0 {this service} one {this service} other {these services}}?`,
  "backOffice.appliance.services.UNINSTALL.askVolumeDeletion": `Remove volumes containing all {count, plural, =0 {service} one {service} other {services}} data`,
  "backOffice.appliance.services.paramsBtn": `Show parameters`,
  "backOffice.appliance.services.paramsBtn.none": `No parameters`,
  "backOffice.appliance.services.podSystem.fullyOperational": `Fully operational`,
  "backOffice.appliance.services.podSystem.notOperational": `Not operational`,
  "backOffice.appliance.services.podSystem.DISK_FULL1": `The service engine has been stopped due to limited disk space below the safe threshold. Hence, no service can be started.`,
  "backOffice.appliance.services.podSystem.DISK_FULL": ` The service disk must be freed up or replaced`,
  "backOffice.appliance.services.podSystem.NO_CONTAINER_STORAGE": `No disk configured for services`,
  "backOffice.appliance.services.podSystem.OK": `Service engine is working.`,
  "backOffice.appliance.services.podSystem.UNKNOWN_ERROR": `Service management has failed.`,

  "backOffice.location.accountName": `Account name`,
  "backOffice.location.accountName.new": `Select an account where to create this new location`,
  "backOffice.location.addAppliances": `Add appliance(s)`,
  "backOffice.location.addAppliances.claim": `You may claim a new appliance to be added at your location (optional)`,
  "backOffice.location.addAppliances.select": `Select any appliance(s) you want to add and specify their configuration mode`,
  "backOffice.location.addAppliances.unselectedOperatingMode": `Please specify the configuration mode for every selected appliance.`,
  "backOffice.location.addAppliances.enterKey.label": `Please enter a valid registration key. Click the Claim button to add the appliance in the list below.`,
  "backOffice.location.backto": `Back to location`,
  "backOffice.location.details": `Location details`,
  "backOffice.location.noLocation": `No location`,
  "backOffice.location.noLocation.toShow": `No location to show`,
  "backOffice.location.create": `Create a new location`,
  "backOffice.location.create.success": `The location <strong>{name}</strong> has been successfully created.`,
  "backOffice.location.update.success": `The location <strong>{name}</strong> has been updated.`,
  "backOffice.location.updateQos.success": `The QoS has been successfully updated`,
  "backOffice.location.updateSecurityProfile.success": `The security profile has been successfully updated`,
  "backOffice.location.qos": `QoS`,
  "backOffice.location.qos.long": `Quality of Service (QoS)`,
  "backOffice.location.qos.internetConnection": `Internet connection`,
  "backOffice.location.qos.zonesConfig": `Zones configuration`,
  "backOffice.location.exportConfig": `Export configuration`,
  "backOffice.location.exportConfig.desc": `This YAML export of the configuration can be use to configure a new location from the <strong>Import a location</strong> button in the detail page of an account.`,
  "backOffice.location.importLoc": `Import a location`,
  "backOffice.location.importLoc.fail.delete": `Import has partially failed. What do you want to do?`,
  "backOffice.location.importLoc.fail.import": `Import has failed`,
  "backOffice.location.importLoc.fail.editYaml": `Edit YAML`,
  "backOffice.location.importLoc.desc": `Paste in the blue box the YAML configuration YAML of the location you want to create, zones and their Wi-Fis can be included.`,
  "backOffice.location.importLoc.errorConfig": `Invalid configuration`,
  "backOffice.location.edit": `Edit location`,
  "backOffice.location.editInfo": `Edit location info`,
  "backOffice.location.filtered.noLocation": `No location matches this combination of filters.`,
  "backOffice.location.configuredAccessPorts": `Configured access ports`,
  "backOffice.location.activeWiredConnections": `Online wired clients`,
  "backOffice.location.activeWiFiConnections": `Online Wi-Fi clients`,
  "backOffice.location.showActiveWiFiConnections": `Show online Wi-Fi clients`,
  "backOffice.location.showVlans": `Show zones/vlans`,
  "backOffice.location.columns.appliances": `Appliances offline/total`,
  "backOffice.location.columns.devices": `# Total devices`,
  "backOffice.location.columns.wifi": `Wi-Fis`,
  "backOffice.location.columns.pendingHosts": `Pending auth.`,
  "backOffice.location.columns.blockedHosts": `Blocked devices`,
  "backOffice.location.vlanzone": `Zone/Vlan`,
  "backOffice.location.vlanszones": `Zones/Vlans`,
  "backOffice.location.vlanConfig": `Zone/Vlan configuration`,
  "backOffice.location.map": `View network map`,

  "backOffice.appliance.noAppliance": `No appliance`,
  "backOffice.appliance.addedOn": `Appliance added on {date}`,
  "backOffice.appliance.addedToNetwork": `Add to location`,
  "backOffice.appliance.removed": `Removed appliance`,
  "backOffice.appliance.removed.message": `The appliance has been removed on {date} from this project, you can not modify it or create operations on it.`,
  "backOffice.appliance.filtered.noAppliance": `No appliance matches this combination of filters.`,
  "backOffice.appliance.tasks.tooltip.explanation": `Number of tasks <strong>Pending</strong> or <strong>In progress</strong> for an appliance.`,
  "backOffice.appliance.tasks.tooltip.pendingRemoval": `Pending removal of this appliance.`,

  "backOffice.appliance.manageInventory": `Manage inventory`,
  "backOffice.appliance.addToInventory": `You must add the appliance to inventory to use it.`,
  "backOffice.appliance.claimByRK": `Claim by registration key`,
  "backOffice.appliance.current": `Current appliance`,
  "backOffice.appliance.selectAccountToClaim": `Select an account where to claim`,
  "backOffice.appliance.enterRK": `Enter one or more registration key (one per row)`,
  get "backOffice.appliance.returnTo"() {return this["generic.actions.RETURN_TO_OPERATOR"]},
  "backOffice.appliance.returnTo.message": `You are about to return {count, plural,
    =0 {0 appliance}
    one {an appliance}
    other {{count} appliances}
  } {list} to your SentinelC operator.`,
      "backOffice.appliance.returnTo.messageForData": `Please note that {count, plural,
    =0 {no appliance}
    one {the following appliance has}
    other {the following appliances have}
  } might contain sensible data`,
  "backOffice.appliance.returnTo.messageForData.desc": `You will no longer be able to delete the data once the return is made. It is recommended to uninstall the embedded services and to erase the disks before returning the appliances.`,
  "backOffice.appliance.returnTo.label": `Reason why you are returning appliance(s) to your SentinelC operator`,
  "backOffice.appliance.returnTo.content": `You appliance(s) will be returned to your SentinelC operator. Make sure you send the appliance(s) the appropriate way following this operation.`,
  "backOffice.appliance.returnTo.placeholder.DEFECTIVE": `Please describe the problem...`,
  "backOffice.appliance.returnTo.placeholder.OTHER": `Please explain the reason for the return...`,

 get "backOffice.appliance.discard"() {return this["generic.actions.DISCARD_DEFINITIVELY"]},
  "backOffice.appliance.discard.message":  `You are about to discard {count, plural,
    =0 {0 appliance}
    one {an appliance}
    other {{count} appliances}
  } {list} of the inventory.`,
  "backOffice.appliance.discard.messageSensitive": `Some of the appliances you are about to discard have the status "New", "Returned" ou "In stock".`,
  "backOffice.appliance.discard.label": `Reason why you want to discard theses appliances`,
  "backOffice.appliance.discard.content": `Theses appliances will no longer exist and will be visible in the list only for history purpose. They won't be able to be added if they try to reconnect to the platform .`,
  "backOffice.appliance.discard.placeholder.DEFECTIVE": `Please describe the problem...`,
  "backOffice.appliance.discard.placeholder.OBSOLETE": `Please put the versions of discarded appliances...`,
  "backOffice.appliance.discard.placeholder.OTHER": `Please explain the reason for the discard...`,
  
  "backOffice.appliance.factoryreset.content": `This operation will delete all configuration and will remove definitively appliances from their location but they will stay associated to their account.`,

  "backOffice.appliance.selectAccountToTransferTo.transferTo.title.singular": `Transfer appliance to...`,
  "backOffice.appliance.selectAccountToTransferTo.transferTo.title": `Transfer appliances to...`,
  "backOffice.appliance.selectAccountToTransferTo.account": `Select an account where to transfer the appliance(s)`,
  "backOffice.appliance.selectAccountToTransferTo.location": `Select a location from account`,
  "backOffice.appliance.selectAccountToTransferTo.location.placeholder": `Select a location (optional)`,
  "backOffice.appliance.selectAccountToTransferTo.location.noChoice": `Do not select a location`,
  "backOffice.appliance.selectAccountToTransferTo.location.noChoice.desc": `If no location is selected, the appliance will be added to the account with the state`,
  "backOffice.appliance.selectAccountToTransferTo.message": `You are about to transfer {count, plural,
    =0 {0 appliance}
    one {an appliance}
    other {{count} appliances}
  } {list}.`,
  "backOffice.appliance.selectAccountToTransferTo.config": `Select a configuration for your appliance(s)`,
  "backOffice.appliance.selectAccountToTransferTo.config.subtitle": `Only one appliance can be Master router at this location`,
  "backOffice.appliance.selectAccountToTransferTo.config.subtitle.networkHasRouter": `This location already has a Master router.`,

  "backOffice.appliance.addTo.message": `You are about to add {count, plural,
    =0 {0 appliance}
    one {an appliance}
    other {{count} appliances}
  } {list}.`,
  "backOffice.appliance.addTo.location": `Appliance(s) must be added to a location`,

  get "backOffice.appliance.switchEndpoint"() {return this["generic.actions.SWITCH_ENVIRONMENT"]},
  "backOffice.appliance.switchEndpoint.message": `Modifications to this setting would cause permanent unavailability of the following {count, plural,
    =0 {appliance}
    one {appliance}
    other {appliances}
  } {list}.`,
  "backOffice.appliance.switchEndpoint.endpoint.label": `API endpoint where this appliance connect to a SentinelC server`,
  "backOffice.appliance.switchEndpoint.description.label": `Describe the reason for the transfer of another environment`,
  "backOffice.appliance.switchEndpoint.content.subtitle.actualEndpoint": `This API endpoint is valid for this environment.`,
  "backOffice.appliance.switchEndpoint.content.subtitle.externalEndpoint": `This API endpoint refers to an external environment. This appliance will be <strong>Unavailable</strong> in your inventory.`,
  
  "backOffice.appliance.disk.name": `Disk name`,
  "backOffice.appliance.disk.editSuccess": `Modification of the disk has been scheduled successfully!`,
  "backOffice.appliance.disk.formatSubmitted": `A task will start at any moment to apply modifications to your disk.{linebreak}This operation can take several minutes depending on the size of your disk. Progress status will be shown in the disk component or you may check the task linked to this status.`,
  "backOffice.appliance.disk.formatDesc": `This action will quickly format the disk and assign it to services.`,
  "backOffice.appliance.disk.reinitDesc": `This action will quickly format the disk and remove use for services.`,
  "backOffice.appliance.disk.eraseDesc": `This action will securely erase the disk.`,
  "backOffice.appliance.disk.eraseOption": `Securely erase the disk.`,
  "backOffice.appliance.disk.eraseSubmitted": `A task will start at any moment to erase your disk.{linebreak}This operation can take several minutes depending on the size of your disk.`,
  

  "backOffice.appliance.disk.edit": `Edit disk`,
  "backOffice.appliance.disk.configure": `Configure service disk`,
  "backOffice.appliance.disk.change": `Change service disk`,
  "backOffice.appliance.disk.edit.chooseAPrep": `You must choose a preparation for the disk`,
  "backOffice.appliance.disk.select": `Select disk`,
  "backOffice.appliance.disk.selectUsage": `Select disk usage`,
  "backOffice.appliance.disk.selectUsage.NONE": `None - The disk will be ignored and unused by the system.`,
  "backOffice.appliance.disk.selectUsage.CONTAINER_STORAGE": `Services - Use the disk as the primary storage for embedded services.`,
  "backOffice.appliance.disk.selectUsage.STORAGE": `Storage - Allows accessing the disk content for backup or archive purposes.`,
  "backOffice.appliance.disk.diskPreparation": `Disk preparation`,
  "backOffice.appliance.disk.diskPreparation.AS_IS": `As-is`,
  "backOffice.appliance.disk.diskPreparation.AS_IS.tooltip": `Option unavailable. The disk has no previous formatting.`,
  "backOffice.appliance.disk.diskPreparation.ERASE": `Erase`,
  "backOffice.appliance.disk.diskPreparation.ERASE_AND_FORMAT": `Erase and format`,
  "backOffice.appliance.disk.diskPreparation.FORMAT": `Format`,
  "backOffice.appliance.disk.edit.actualDisk.clear": `Actual service disk <strong>{disk}</strong> is missing. It will be cleared from the list.`,
  "backOffice.appliance.disk.edit.actualDisk.reconfiguration": `Actual service disk <strong>{disk}</strong> will be reconfigured to usage <strong>Storage</strong> <small><em>(No data will be deleted)</em></small>.`,

  "backOffice.appliance.disks.view": `Show disks`,
  "backOffice.appliance.disks.currentState": `Current state`,
  "backOffice.appliance.disks.serviceDisk.change": `The disk currently used for services is <strong>{disk}</strong>.`,
  "backOffice.appliance.disks.serviceDisk.change.btn": `Change disk`,
  "backOffice.appliance.disks.serviceDisk.change.btn.disabled": `No other disk available`,
  "backOffice.appliance.disks.serviceDisk.notSelected": `No disk used for services.`,
  "backOffice.appliance.disks.serviceDisk.notSelected.btn": `Select a disk`,
  "backOffice.appliance.disks.serviceDisk.none": `No disk available for services.`,
  "backOffice.appliance.disks.size": `Size`,
  "backOffice.appliance.disks.selectDiskUsage": `Select disk usage`,
  "backOffice.appliance.disks.diskUsage.assignToService": `Assign to Services`,
  "backOffice.appliance.disks.selectFormatType": `Select format type`,
  "backOffice.appliance.disks.lastKnownData": `Last known values`,
  "backOffice.appliance.disks.freeSpace": `Free space`,
  "backOffice.appliance.disks.usedSpace": `Used space`,
  "backOffice.appliance.disks.usedSpace.filterBy": `By used space`,
  "backOffice.appliance.disks.usage": `Usage`,
  "backOffice.appliance.disks.onPartitionOnly": `On data partition only`,
  "backOffice.appliance.disks.modal.clear.content": `This disk is no longer available, it will be cleared from this list.`,
  "backOffice.appliance.disks.modal.clear.hasServices": `Installed services will be removed.`,
  "backOffice.appliance.disks.message.update.clear": `Disk successfully cleared`,
  "backOffice.appliance.disks.message.update.format": `Format successfully started`,
  "backOffice.appliance.disks.message.update.unmount": `Disk successfully unmount`,

  "backOffice.appliance.info": `Configuration and general status`,
  "backOffice.appliance.info.connectionStatus": `Connection status`,
  "backOffice.appliance.info.setup": `Appliance setup`,
  "backOffice.appliance.info.heartBeat": `Heart-beat`,
  "backOffice.appliance.info.vpnStatus": `VPN status`,
  "backOffice.appliance.info.vpnStatus.UP": `The automated VPN connection is functional.`,
  "backOffice.appliance.info.vpnStatus.DOWN": `Appliance is currently not polling for its configuration using the heart-beat system. The automated VPN connection cannot be established.`,
  "backOffice.appliance.info.vpnStatus.CONFIGURING": `Waiting for the automated VPN connection to be established.`,
  "backOffice.appliance.info.vpnStatus.DISABLED": `The inventory status of the appliance does not require a VPN connection.`,
  "backOffice.appliance.info.internetConnectivity": `Internet connectivity`,
  "backOffice.appliance.info.temperature": `Temperature`,
  "backOffice.appliance.info.memory": `Memory`,
  "backOffice.appliance.info.storage": `Storage`,
  "backOffice.appliance.info.metrics": `Appliance sensors`,
  "backOffice.appliance.info.noMetrics": `Unavailable metrics`,
  "backOffice.appliance.info.currentRelease": `Current release`,
  "backOffice.appliance.info.targetRelease": `Target release`,
  "backOffice.appliance.info.localIpAddress": `Local IP address`,
  "backOffice.appliance.info.publicIpAddress": `Public IP address`,
  "backOffice.appliance.info.internetGateway": `Internet gateway`,
  get "backOffice.appliance.info.apiEndpoint"() {return this["generic.apiEndpoint"]},

  "backOffice.appliance.wifis.broadcastingAppliances": `Broadcasting appliance(s)`,
  "backOffice.appliance.wifis.noLocation": `This appliance must be added to a location to be able to add a Wi-Fi network.`,
  "backOffice.appliance.radios.activation": `Activation`,
  "backOffice.appliance.radios.activeConnections": `Count of active connections on the Wi-Fi for actual appliance, {linebreak}in brackets the count of active connections for all online broadcasting appliances.`,
  "backOffice.appliance.radios.actualChannel": `Actual channel`,
  "backOffice.appliance.radios.actualChannel.error": `Channel not applied - Please wait or try to manually select an other channel`,
  "backOffice.appliance.radios.actualTx": `Actual Tx power`,
  "backOffice.appliance.radios.radioInactivated": `Radio inactivated by the appliance because there is no Wi-Fi emitted on.`,
  "backOffice.appliance.radios.channelSettings": `Channel setting`,
  "backOffice.appliance.radios.bands": `Bands`,
  "backOffice.appliance.radios.bandType": `Band type`,
  "backOffice.appliance.radios.bandType.SINGLE_BAND": `Single band`,
  "backOffice.appliance.radios.bandType.DUAL_BAND": `Dual band`,
  "backOffice.appliance.radios.maxWifi": `Max. Wi-Fi`,
  "backOffice.appliance.radios.driver": `Driver`,
  "backOffice.appliance.radios.interface": `Interface`,
  "backOffice.appliance.radios.maxSsids": `Maximum SSID`,
  "backOffice.appliance.radios.maxVTHBandwidth": `Max. VTH bandwidth`,
  "backOffice.appliance.radios.noRadioEnabled": `This appliance has no enabled radio to emit.`,
  "backOffice.appliance.radios.offlineDevice": `Appliance appears to be offline, last known data is displayed, it may not be accurate.`,
  "backOffice.appliance.radios.psk": `PSK`,
  "backOffice.appliance.radios.ssid": `SSID`,
  "backOffice.appliance.radios.station": `Station`,
  "backOffice.appliance.radios.stationMode": `Station mode`,
  "backOffice.appliance.radios.stationMode.popup": `This option can only be set by a local configuration of the appliance. When enabled, this means that this radio act as a Wi-Fi client to connect to a specific SSID.`,
  "backOffice.appliance.radios.txPower": `Tx power`,
  "backOffice.appliance.radios.txPowerSetting": `Tx power setting`,
  "backOffice.appliance.radios.txPowerSetting.auto": `In order to comply with regulations, this value could be modified following confirmation.`,
  "backOffice.appliance.radios.update.success": `Radios have been successfully updated.`,
  "backOffice.appliance.ports.doc.customPorts": `How to use custom ports`,
  "backOffice.appliance.ports.doc.Qos": `Understand QoS`,
  "backOffice.appliance.ports.configurePorts": `Edit ports`,
  "backOffice.appliance.ports.configurePorts.default": `Select a configuration`,
  "backOffice.appliance.ports.editCustomConfig": `Type in or edit this port configuration`,
  "backOffice.appliance.ports.editPortsConfig": `Edit ports configuration`,
  "backOffice.appliance.ports.configureInternetPort": `Configure QoS`,
  "backOffice.appliance.ports.presetConfig": `Preset configurations`,
  "backOffice.appliance.ports.presetConfig.desc": `Clicking on "Apply" you will override the current configuration of the port.`,
  "backOffice.appliance.ports.portType": `Type/Zone`,
  "backOffice.appliance.ports.applianceDevice": `Appliances/Devices`,
  get "backOffice.appliance.ports.ipAddress"() {return this["location.ip"]},
  "backOffice.appliance.macAddress": `Appliance mac address`,
  "backOffice.appliance.ports.macAddress": `Port mac address`,
  "backOffice.appliance.ports.maxSpeed.subtitle": `According to your provider contract`,
  "backOffice.appliance.ports.guarantedDownloadSpeed": `Guaranteed download speed`,
  "backOffice.appliance.ports.guarantedUploadSpeed": `Guaranteed upload speed`,
  "backOffice.appliance.ports.maxDownloadSpeed": `Max download speed`,
  "backOffice.appliance.ports.maximumDownloadSpeed": `Maximum <strong>download</strong> speed`,
  "backOffice.appliance.ports.maximumDownloadSpeed.subtitle": `Maximum download bandwidth allowed is <strong>{value} Mbps</strong>`,
  "backOffice.appliance.ports.maximumSpeed.info": `Set this value to 0 will set an extremly slow bandwidth (close to 0). An empty field will deactivate the maximum.`,
  "backOffice.appliance.ports.guaranteedDownloadSpeed": `Guaranteed <strong>download</strong> speed`,
  "backOffice.appliance.ports.guaranteedDownloadSpeed.subtitle": `Minimum guaranteed/reserved download bandwidth`,
  "backOffice.appliance.ports.maxUploadSpeed": `Max upload speed`,
  "backOffice.appliance.ports.maximumUploadSpeed": `Maximum <strong>upload</strong> speed`,
  "backOffice.appliance.ports.maximumUploadSpeed.subtitle": `Maximum upload bandwidth allowed is <strong>{value} Mbps</strong>`,
  "backOffice.appliance.ports.guaranteedUploadSpeed": `Guaranteed <strong>upload</strong> speed`,
  "backOffice.appliance.ports.guaranteedUploadSpeed.subtitle": `Minimum guaranteed/reserved upload bandwidth`,
  "backOffice.appliance.ports.guaranteedSpeed.info": `Set this value to 0 or let the field empty will deactivate the QoS in download or upload for this zone/vlan.`,
  "backOffice.appliance.ports.totalGuaranteed": `Total guaranteed`,
  "backOffice.appliance.ports.qosEnabled": `QoS is currently enabled.`,
  "backOffice.appliance.ports.qosDisabled": `QoS is currently disabled.`,
  "backOffice.appliance.ports.qosDesc": `If enabled, it defines bandwidth thresdhold speed capped/guaranteed.`,
  "backOffice.appliance.ports.manageQoS": `Manage QoS`,
  "backOffice.appliance.ports.manageQoS.deactivated": `QoS requiring an equipment in router mode.`,
  "backOffice.appliance.ports.internetConfiguration": `Internet port configuration`,
  "backOffice.appliance.ports.internetConfiguration.message": `Setting up one or all of these parameters will enable configuring QoS on zones/vlans.{linebreak}The data entered must exactly match the bandwidth speeds as specified by your provider, otherwise it may negatively affet the performance of your network.{linebreak}Also, if you reduce the previous speeds configured, it could affect the Quality of Service (QoS) on each of the zones on which it is activated.`,
  "backOffice.appliance.ports.qosConfiguration": `Zones/Vlans QoS configuration`,
  "backOffice.appliance.ports.qosConfiguration.subtitle": `You can configure either capping or guaranting download or upload speeds or both depending if maximum download and upload speeds are configured for the internet port (above).`,
  "backOffice.appliance.ports.qosActivation": `Quality of Service (QoS) activation`,
  "backOffice.appliance.ports.qosActivation.subtitle": `To activate QoS, you must select Activated from the actions below. Otherwise, the entered values will be saved but not applied. Data validation only occurs when QoS is enabled.`,

  "backOffice.appliance.radios.editParams": `Edit radio parameters`,
  "backOffice.appliance.radios.modal.title": `Edit radio parameters - {device}`,

  "backOffice.appliances.history": `Appliances history`,
  "backOffice.appliances.bulk.message.update.REBOOT": `{count, plural, =0 {No appliance restarted} one {An appliance restarted} other{{count} appliances restarted}}.`,
  "backOffice.appliances.bulk.message.update.WIFI_SURVEY": `{count, plural, =0 {No Wi-Fi survey started} one {No Wi-Fi survey started on an appliance} other{No Wi-Fi survey started on {count} appliances}}.`,
  "backOffice.appliances.bulk.message.update.FACTORY_RESET": `{count, plural, =0 {No factory reset applied} one {An appliance has been factory reset} other{{count} appliance has been factory reset}}.`,
  
  "backOffice.applianceHistory.title": `Appliances changes history`,
  "backOffice.applianceInventoryHistory.title": `Changes history for inventory appliances`,

  "backOffice.inventory": `Inventory`,
  "backOffice.inventory.title": `Manage appliances inventory`,
  "backOffice.inventory.statuses": `Understand statuses on inventory`,
  "backOffice.inventory.actions": `Understand actions on appliances in inventory`,
  "backOffice.inventory.message": `You must first configure the legitimate IP addresses from which an appliance can connet to your serveur and appear as New in inventory. Click on Inventory Settings to proceed.`,
  "backOffice.inventory.settings": `Inventory settings`,
  "backOffice.inventory.settings.update.success": `Settings modified with success.`,
  "backOffice.inventory.enterIPAddresses": `Enter one or more IP addresses authorized to connect to Inventory (one per row)`,
  "backOffice.inventory.enterIPAddresses.subtitle": `New SentinelC or returned equipments may connect through theses IP addresses for the first time to be added to the Inventory account.`,
  "backOffice.inventory.transferTo.message": `You are about to transfer {count, plural,
    =0 {0 appliance}
    one {an appliance}
    other {{count} appliances}
  } {list}.`,
  "backOffice.inventory.transferTo.location": `Select a location from account INVENTORY`,

  "backOffice.incident.DEVICE_CONFIGURATION": `Appliance configuration`,
  "backOffice.incident.DEVICE_NAME": `Appliance ID`,
  "backOffice.incident.DEVICE_LOCATION": `Appliance name`,
  "backOffice.incident.DEVICE_OPERATING_MODE": `Appliance operating mode`,
  "backOffice.incident.DEVICE_FIRMWARE_VERSION": `Appliance firmware version`,
  "backOffice.incident.DEVICE_FIRMWARE_UP_TO_DATE": `Is firmware up to date`,
  "backOffice.incident.DISK_NAME": `Disk name`,
  "backOffice.incident.DISK_USAGE": `Disk usage`,
  "backOffice.incident.DISK_SPACE_LEVEL": `Disk space level`,
  "backOffice.incident.DISK_USED_PCT": `Disk used pourcentage`,
  "backOffice.incident.POD_NAME": `Service name`,
  "backOffice.incident.POD_STATUS": `Service status`,
  "backOffice.incident.PODMAN_MESSAGE": `Service system message`,
  "backOffice.incident.PODMAN_STATUS": `Service system status`,
  "backOffice.incident.PODMAN_PODS": `Affected services`,
  "backOffice.incident.ROGUE_SSID": `Rogue SSID name`,
  "backOffice.incident.ROGUE_SSID_DETECTED_BY_RULE": `Rogue SSID detected by rule`,
  "backOffice.incident.ROGUE_BSSID": `Rogue BSSID`,
  "backOffice.incident.ROGUE_FIRST_SEEN_DATE": `Rogue SSID first seen date`,
  "backOffice.incident.ROGUE_FREQUENCY": `Rogue SSID frequency`,
  "backOffice.incident.ROGUE_SIGNAL": `Rogue SSID signal`,
  "backOffice.incident.ROGUE_BAND": `Frequency`,
  "backOffice.incident.ROGUE_REPORTED_BY": `Report by`,
  "backOffice.incident.NETWORK_LOCATION": `Location name`,
  "backOffice.incident.NO_RADIO_SSID_DEVICES": `Devices emitting the Wi-Fi network`,
  "backOffice.incident.NO_RADIO_SSID": `Wi-Fi network without radio`,
  "backOffice.incident.HOST_MAC_ADDRESS": `Device MAC address`,
  "backOffice.incident.HOST_HOSTNAME": `Device hostname`,
  "backOffice.incident.SESSION_PORT_LABEL": `Bad connection port number`,
  "backOffice.incident.BAD_CONNECTION_REASON": `Bad connection reason`,
  "backOffice.incident.BAD_CONNECTION_REASON.HOST_NOT_ALLOWED_IN_TRUNK_PORT": `Device not allowed in a restricted port`,
  "backOffice.incident.BAD_CONNECTION_REASON.DEVICE_NOT_ALLOWED_IN_ACCESS_PORT": `Appliance not allowed in an access port`,
  "backOffice.incident.BAD_CONNECTION_REASON.LOOP_INTO_SELF": `Loop of the appliance on itself`,
  "backOffice.incident.BAD_CONNECTION_DEVICE_NAME": `On appliance `,
  "backOffice.incident.BAD_CONNECTION_DEVICE_LOCATION": `On locatione`,
  "backOffice.incident.BAD_CONNECTION_DEVICE_PORT_LABEL": `On port number`,
  "backOffice.incident.FAILED_UPDATE_TARGET_VERSION": `Target firmware version`,
  "backOffice.incident.VLAN_NAME": `Zone name`,
  "backOffice.incident.VLAN_SUBNET": `Zone subnet`,
  "backOffice.incident.WAN_IP_ADDRESS": `WAN connection IP address`,
  "backOffice.incident.FIRST_EVENT": `First occurrence`,
  "backOffice.incident.LAST_EVENT": `Last occurrence`,
  "backOffice.incident.EVENT_COUNT": `Number of event(s)`,
  "backOffice.incident.EVENT_COUNT.subtitle": `This number could grow while this incident is in the OPEN state`,

  "backOffice.home.downtimeStatus": `Down time status`,
  "backOffice.home.downtime": `Down time`,
  "backOffice.home.downtime.average": `Average down time:`,
  "backOffice.home.recentIncidents": `Most recent incidents`,
  "backOffice.home.openIncidents": `Open incidents`,
  "backOffice.home.recentOpenIncidents": `Recent open incidents`,
  "backOffice.appliance.availability": `Appliance availability`,

  "backOffice.project.addAppliance": `Add an appliance`,
  "backOffice.project.selectAppliance": `Select an appliance to see details.`,
  "backOffice.project.specialRemarks": `Special remarks`,
  "backOffice.project.close": `Close project`,
  "backOffice.project.projectId": `Project ID`,
  "backOffice.project.projectTitle": `Project title`,
  "backOffice.project.portscan.empty": `Add your first ports scan`,
  "backOffice.project.portscan.useRouter": `To add a port scan, use the router of this appliance`,
  "backOffice.project.packetCapture.empty": `Add your first packet capture`,
  "backOffice.project.modify": `Modify project`,
  "backOffice.project.modify.message": `Helps keep organized your monitoring activities on specific appliances.`,

  "backOffice.projects.add": `Create project`,
  "backOffice.projects.add.submit": `Create project`,
  "backOffice.projects.close": `Close projects`,
  "backOffice.projects.close.info": `
    You are about to close {count, plural, =0 {no project} one {a project} other {{count} projects}}
  `,
  "backOffice.projects.close.message": `This action implies projects can no longer be updated and that the services deployed on projects equipments will be uninstalled.{linebreak}
    However, you will be able to view projects and have access to files saved/generated by certain activities.`,
  "backOffice.project.message.addProject": `Project successfully created.`,
  "backOffice.project.message.updateProject": `Project successfully updated.`,
  "backOffice.project.message.addDevice": `Appliance successfully added to the project.`,
  "backOffice.project.message.updateDevice": `Appliance successfully updated.`,
  "backOffice.project.message.removeDevice": `Appliance removed updated.`,

  "backOffice.volume.deletion": `Delete the volume`,
  "backOffice.volume.deletion.text": `Are you sure you want to delete the volume?`,
  "backOffice.volumes.title": `Volumes on disks`,
  "backOffice.volumes.view": `View volumes`,
  "backOffice.volumes.title.deletion.title": `Delete volumes`,
  "backOffice.volumes.deletion.message": `Only volumes with uninstalled service can be deleted`,
  "backOffice.volumes.diskPartition": `Disks/partition`,
  "backOffice.volumes.volumeId": `Volume ID`,
  "backOffice.volumes.volumeName": `Volume name`,
  "backOffice.volumes.files": `Volume files`,  
  "backOffice.volumes.files.remove": `Are you sure you want to delete this element?`,
  "backOffice.volumes.files.remove.timeout": `No response from the server. Please refresh the file list later to check if the operation was successful.`,
  "backOffice.volumes.files.offlineDevice": `Impossible to check files tree, the appliance is offline.`,
  "backOffice.volumes.files.error": `Access to files unavailable.`,  
  "backOffice.volumes.files.error.INCOMPATIBLE": `Firmware version incompatible with this feature.`,  
  "backOffice.volumes.files.error.CONNECTION_FAILED": `Network error: Access to files unavailable.`,  
  "backOffice.volumes.files.error.TIMEOUT": `Network error: Access to files unavailable.`,  
  "backOffice.volumes.files.error.INTERNAL": `Internal error: Access to files unavailable.`,   
  "backOffice.volumes.files.desc": `Explore, download or delete files et folders of the volume.`,
  "backOffice.volumes.files.desc.warning": `Be careful, the service could stop working if you delete some files.`,
  "backOffice.volumes.viewFiles": `View files`,
  "backOffice.volumes.viewFiles.empty": `No file`,
  "backOffice.volumes.filters.status": `Disk status`,
  "backOffice.volumes.filters.status.filterBy": `By disk status`,
  "backOffice.volumes.filters.type": `Disk type`,
  "backOffice.volumes.filters.type.filterBy": `By disk type`,
  "backOffice.volumes.filters.volumeStatus": `Volume status`,
  "backOffice.volumes.filters.volumeStatus.filterBy": `By volume status`,
  "backOffice.volumes.filters.volumeStatus.IN_USE": `In use`,
  "backOffice.volumes.filters.volumeStatus.DELETE_PENDING": `Pending deletion`,
  "backOffice.volumes.filters.volumeStatus.ORPHAN": `Orphan`,

  "backOffice.volumes.popup.orphan": `Service has been <strong>uninstalled</strong> without clearing volume.`,

  "backOffice.incident": `Incident`,
  "backOffice.incidents.title": `Incidents`,
  "backOffice.incidents.user.notPrivileged": `This user has no more support permissions.`,
  "backOffice.incidents.bulk.message.update.OPEN": `{count, plural, =0 {No modified incident} one { One opened incident} other{{count} opened incidents}}.`,
  "backOffice.incidents.bulk.message.update.CLOSE": `{count, plural, =0 {No modified incident} one { One closed incident} other{{count} closed incidents}}.`,
  "backOffice.incidents.bulk.message.update.RESOLVE": `{count, plural, =0 {No modified incident} one { One resolved incident} other{{count} resolved incidents}}.`,

  "backOffice.account.filtered.noAccount": `No account matches this combination of filters`,
  "backOffice.account.create": `Create new account`,
  "backOffice.account.create.success": `The account <strong>{name}</strong> has been successfully created.`, 
  "backOffice.account.update.success": `The account <strong>{name}</strong> has been updated.`,
  "backOffice.account.delete.success": `The account <strong>{name}</strong> has been successfully removed.`, 
  "backOffice.account.details": `Account details`,
  "backOffice.account.edit": `Edit account`, 
  "backOffice.account.locationsMap": `Locations map`,
  "backOffice.account.totalIncident": `Total incident`,
  "backOffice.account.openIncidents": `Open incident(s)`,
  "backOffice.account.adminUsers": `Admin user(s) on this account ({count})`,
  "backOffice.account.viewTasks": `{count, plural, =0 {No task} one {View the task} other {View tasks}}`,
  "backOffice.account.remove.modal.title": `Remove account {account}`,
  "backOffice.account.remove.modal.message": `You are about to delete the account <strong>{account}</strong> definitively. {linebreak}Be aware that any networks, configurations or data related to this account will be deleted permanently.`,
  "backOffice.account.remove.modal.appliances": `Action required!`,
  "backOffice.account.remove.modal.appliances.confirmationStep": `Enter the following account name to confirm his deletion: <strong>{name}</strong>`,
  "backOffice.account.remove.modal.appliances.confirmationStep.title": `Confirmation step`,
  
  "backOffice.account.remove.modal.appliances.RETURN_TO_OPERATOR": `Return to operator`,
  "backOffice.account.remove.modal.appliances.RETURN_TO_OPERATOR.explanation": `{count, plural, one {Your appliance will be returned to SentinelC inventory and you will no longer own it.} other {All your appliances will be returned to SentinelC inventory and you will no longer own them.}}`,
  "backOffice.account.remove.modal.appliances.KEEP": `Keep`,
  "backOffice.account.remove.modal.appliances.KEEP.explanation": `This action enables you to <strong>keep ownership of your {count, plural, one {appliance} other {appliances}}</strong>.{linebreak} However, this implies that your account and associated {count, plural, one {appliance} other {appliances}} will be kept, but all other items will be deleted.`,
  "backOffice.account.remove.modal.appliances.TRANSFER_TO": `Transfer to...`,
  "backOffice.account.remove.modal.appliances.TRANSFER_TO.explanation": `This action allows you to transfer {count, plural, one {your appliance} other {all your appliances}} to another account under your management.`,

  "backOffice.accounts.listOf": `List of accounts`,
  "backOffice.locations.listOf": `List of locations`,
  "backOffice.devices.listOf": `List of appliances`,
  "backOffice.devices.btn": `View the list of appliances`,
  "backOffice.devices.txt.up": `{count, plural, =0 {No appliance online} one {An appliance online} other {{count} appliances online}}`,
  "backOffice.devices.txt.down": `{count, plural, =0 {No appliance offline} one {An appliance offline} other {{count} appliances offline}}`,
  "backOffice.devices.txt.notInUse": `{count, plural, =0 {No appliance not in use} one {An appliance not in use} other {{count} appliances not in use}}`,
  "backOffice.stats.selectOfStatus": `Please select a filter by clicking on one of the above piecharts slices.`,


  "backOffice.hosts.noHost": `No device`,
  "backOffice.hosts.filtered.noHost": `No device matches this combination of filters.`,
  "backOffice.hosts.details.viewAllConnectionsFrom": `View all connections from this device to`,
  "backOffice.hosts.details.viewAllConnections": `{count, plural, =0 {No connection} one {See the connection} other{See the {count} connections}}`,
  "backOffice.hosts.startTime": `Connection start time`,
  "backOffice.hosts.endTime": `Connection end time`,
  "backOffice.hosts.triggers": `Trigger a task for this device`,
  "backOffice.hosts.openPorts.neverScanned": `Never scanned`,
  "backOffice.hosts.openPorts.notResponding": `Not responding`,
  "backOffice.hosts.openPorts.notResponding.explain": `Did not respond to the latest port scan.`,
  "backOffice.hosts.openPorts.notResponding.explain.setlast": `Results are from the last valid scan.`,
  "backOffice.hosts.openPorts.notResponding.view": `View scan`,

  "backOffice.host.deviceDetails": `Device details`,
  "backOffice.host.deviceDetails.service": `This client device is the virtual network interface of an embedded service. For more details`,
  "backOffice.host.deviceDetails.service.view": `view the service`,
  "backOffice.host.deviceDetails.uninstalledService": `This client device is the virtual network interface of an uninstalled embedded service`,
  "backOffice.host.lastConnectionDetails": `Details of the last connection`,
  "backOffice.host.actualStatus": `Actual status`,
  "backOffice.host.connections": `Connections since the beginning`,
  "backOffice.host.edit": `Edit device`,
  "backOffice.host.surveillance": `Surveillance activities of this device`,
  "backOffice.host.incidents": `Incidents of this device`,
  "backOffice.host.ipAddress": `IP address of the target`,
  "backOffice.host.ipAddress.by": `By IP address`,
  "backOffice.host.portForwards.filtered.none": `No port forward found with this combination of filters.`,
  "backOffice.host.portForwards.none": `No port forward configured.`,
  "backOffice.host.portForwarding": `Port forwarding`,
  "backOffice.host.portForwarding.history": `Port forwarding history`,
  "backOffice.host.portForwarding.add": `Add port forward`,
  "backOffice.host.portForwarding.edit": `Edit port forward`,
  "backOffice.host.portForwarding.ipDestination": `IP destination`,
  "backOffice.host.portForwarding.none": `No port forward configured on this device`,
  "backOffice.host.portForwarding.warning": `Warning, this device is accessible on the internet!`,
  "backOffice.host.portForwarding.warning.noRouter": `The current location of this device has no active router, port forward will be saved but not applied until a router is available in this location.`,
  "backOffice.host.portForwarding.lanPort": `LAN port`,
  "backOffice.host.portForwarding.publicPort": `Public port`,
  "backOffice.host.portForwarding.restrictAccess": `Limit access to specified IPs only`,
  "backOffice.host.portForwarding.restrictAccess.warning": `At least one port of this device is exposed on internet with no remote IP restriction.`,
  "backOffice.host.portForwarding.restrictAccess.warning.single": `This port is exposed on internet with no remote IP restriction.`,
  "backOffice.host.portForwarding.allowedFromIp": `Allowed from remote IPs`,
  "backOffice.host.portForwarding.allowedFromIp.explanation.connected": `<strong>Green IPs</strong> means they"ve connected at least once.`,
  "backOffice.host.portForwarding.allowedFromIp.explanation.neverConnected": `<strong>Grey IPs</strong> never connected.`,
  "backOffice.host.portForwarding.allowFromIp": `Allow from remote IPs`,
  "backOffice.host.portForwarding.allowFromI.doc": `Wikipedia documentation`,
  "backOffice.host.portForwarding.allowFromIp.formatError": `Invalid CIDR format.`,
  "backOffice.host.portForwarding.allowFromIp.help": `Press "Enter" to add an IP`,
  "backOffice.host.portForwarding.memo": `Memo`,
  "backOffice.host.portForwarding.ipRestriction": `IP restriction`,
  "backOffice.host.portForwarding.ipRestriction.NO": `No`,
  "backOffice.host.portForwarding.ipRestriction.YES": `Yes`,
  "backOffice.host.portForwarding.ipRestriction.filterBy": `With IP restriction`,
  "backOffice.host.portForwarding.updateOn": `Update on`,
  "backOffice.host.portForwarding.add.success": "Port forward added with success",
  "backOffice.host.portForwarding.add.error": "Creation of the port forward has failed",
  "backOffice.host.portForwarding.update.success": "Port forward edited with success",
  "backOffice.host.portForwarding.update.error": "Edition of the port forward has failed",
  "backOffice.host.portForwarding.delete.success": "Port forward deleted with success",
  "backOffice.host.portForwarding.delete.error": "Deletion of the port forward has failed",

  "backOffice.host.connections.title": `Most recent connection by location`,
  "backOffice.host.otherconnections.title": `Connections in other accounts`,
  "backOffice.host.otherconnections.none": `Never seen in another account.`,

  "backOffice.host.permissions.title": `Permissions on zone(s)/vlan(s)`,
  "backOffice.host.permissions.none": `No permission`,
  "backOffice.host.permissions.showAllVlans": `Show all zones/vlans, including restricted ones to which the device has never been connected.`,
  "backOffice.host.permissions.moreLocations": `Only authorizations on zones of this location are shown.`,
  "backOffice.host.permissions.view": `{count, plural, =0 {No permission} one {View the permission} other {View {count} permissions}}`,

  "backOffice.host.lastPortscan.title": `Most recent port scan by location`,
  "backOffice.host.lastPortscan.viewAll": `View all scans for this location`,
  "backOffice.host.lastOsDetection.title": `Most recent OS detection by location`,
  "backOffice.host.lastOsDetection.viewAll": `View all detections for this location`,
  "backOffice.host.dns.title": `Top DNS requests`,
  "backOffice.host.activities": `Surveillance activities on this device`,
  "backOffice.host.permissions": `Permissions`,

  "backOffice.user": `Administrators`,
  "backOffice.users.title": `Manage administrators`,
  "backOffice.user.automaticInventoryAccess": `Automatically added when Privileged permission is given.`,
  "backOffice.users.privileged": `Privileged`,
  "backOffice.users.systemAdministrator": `System administrator`,
  "backOffice.users.portalPermission": `Permissions`,
  "backOffice.users.portalPermission.ENABLED": `Privileged`,
  "backOffice.users.portalPermission.DISABLED": `Administrator`,
  "backOffice.users.portalPermission.AUDITOR": `Auditor`,
  "backOffice.users.portalPermission.NORMAL": `Administrator`,
  "backOffice.users.portalPermission.PRIVILEGED": `Privileged`,
  "backOffice.users.portalPermission.filterBy": `By support permission`,
  "backOffice.users.localAdminOnAccount": `Local admin on account`,
  "backOffice.users.addNew": `Create new admin profile`,
  "backOffice.users.addToAccount": `Add an access profile`,
  "backOffice.users.addToAccount.disabled": `You must complete firstname and lastname fields before being able to add accesses to this profile`,
  "backOffice.users.editAccount": `Edit account profile`,
  "backOffice.users.btn.updateProfile": `Update profile`,
  "backOffice.users.btn.createProfile": `Create profile`,
  "backOffice.users.remove.pending": `Delete`,
  "backOffice.users.remove.enabled": `Revoke`,
  "backOffice.users.remove.updated": `Update`,
  "backOffice.users.inactive": `Inactive user`,
  "backOffice.users.unauthorized": `Unauthorized user`,
  "backOffice.users.regular": `Home mode`,
  "backOffice.users.regular.desc": `Perfect for an house installation.`,
  "backOffice.users.expert": `Administrator mode`,
  "backOffice.users.expert.desc": `Better to manage a medium and large networks.`,
  "backOffice.users.expandAccess": `View accesses`,
  "backOffice.users.collapseAccess": `Collapse accesses`,
  "backOffice.users.expandNotifs": `Modify notification settings`,
  "backOffice.users.collapseNotifs": `Collapse notification settings`,
  "backOffice.users.descNotifs": `This section is updating automatically when you change a setting.`,

  "backOffice.user.creation.title": `Create new administrator profile`,
  "backOffice.user.inactive": `This user is inactive. To be able to edit this profile, you must first add access profiles to it. Afterwards, you will be able to edit the User Information block.`,
  "backOffice.user.edition.title": `Update administrator profile`,
  "backOffice.user.privileged": `Privileged`,
  "backOffice.user.userInfo": `User info`,
  "backOffice.user.message.addUser": `Administrator created with success`,
  "backOffice.user.message.updateUser": `Administrator updated successfully`,
  "backOffice.user.message.revokeUser": `Administrator {email} has been revoked with success`,
  "backOffice.user.revokeAccess.disabled": `The user must at least have access to an account.`,
  "backOffice.user.revokeAllAccess": `Revoke all access`,
  "backOffice.user.authorizedAccessToAccounts": `Authorized access to accounts`,
  "backOffice.user.created.modal.title": `Create user profile confirmation`,
  "backOffice.user.created.modal.content.existing": `Please note that this user profile has been reactivated.{linebreak}Therefore, the user will not receive an email inviting him to log in, so you must notify him.`,
  "backOffice.user.created.modal.content.message": `User profile has been created successfully for {email}`,
  "backOffice.user.reenabled.modal.title": `Reactivation user profile confirmation`,
  "backOffice.user.reenabled.modal.content.message": `User profile has been re-enabled successfully for {email}`,
  "backOffice.user.revoke.modal.title": `Revoke all accesses`,
  "backOffice.user.revoke.modal.content": `Are you sure to revoke all accesses for {email}?`,
  "backOffice.user.access.accountProfile": `Account Profile`,
  "backOffice.user.access.accountProfile.explanation": `
    The system allows specific profile information that will appear when you are connected to an account.
    {linebreak}
    Which means that for the same email address it is possible to have different contact details at the account level.
  `,
  "backOffice.user.access.accessStatus": `Access status`,
  "backOffice.user.access.accountContactName": `Account contact name`,

  "backOffice.userHistory.title": `Administrator account history`,
  "backOffice.userHistory.viewAll": `View all change history`,

  // /BACKOFFICE ======================================

  "homepage.pref.title": `Welcome on SentinelC`,
  "homepage.pref.desc": `Please make a choice for the homepage preference. You will be able to change it later in the user menu.`,


  // DASHBOARD =======================================

  // Home page
  "homepage.about.title": `About`,
  "homepage.content": `You clicked on <strong>Start the installation</strong> which allows you to create a <strong>new account</strong> and add a first SentinelC appliance.`,
  "homepage.message.connected": `You are connected to the SentinelC app!`,
  "homepage.connected.logoutOut": `<p>If you really want to create a new account, log out first and click the <strong>Start Installation</strong> button again.</p>`,
  "homepage.connected.gotoDashboard": `<p>If you would rather add a SentinelC appliance to a new location in one of your existing accounts, you will first need to select the account of your choice and then click <strong>Add a new SentinelC</strong> from the account menu.</p>`,
  "homepage.notConnected.connect": `
    <p>If you already have one or more SentinelC accounts, you may want to add a SentinelC appliance to a new location in one of your accounts.</p>
    <p>To do this, you will first need to log in and then select the account of your choice. From the menu of the selected account, <strong>click on Add a new SentinelC</strong>.</p>
  `,
  "homepage.notConnected.gotoOnboarding": `<p>If you do not have a SentinelC account or you really want to create a new account, click Continue.</p>`,
  "homepage.bloc1.title": `Wi-Fi networks`,

  // Organization
  "dashboard.addOrganization": `Add a SentinelC account`,
  "dashboard.createOrganization": `Create a SentinelC account`,
  "dashboard.selectOrganization.content": `Select a SentinelC account from the list below`,
  "dashboard.selectOrganization.warning": `Your profile has no access privileges defined.`,
  "dashboard.selectOrganization.nocontent": `<p>If you think this situation is abnormal, please contact the administrator at your SentinelC account.</p>`,
  // Device
  "dashboard.device.state.active": `{count, plural,
    =0 {No appliance}
    one {Your appliance is <strong>active</strong>}
    other {All appliances are <strong>active</strong>}
  }`,
  "dashboard.ssid.state.pause": `{count, plural,
    =0 {No Wi-Fi}
   one {1 Wi-Fi is <strong>unavailable</strong>}
   other {{count} Wi-Fi's are <strong>unavailable</strong>}
  }`,
  "dashboard.ssid.state.active": `{count, plural,
    =0 {No Wi-Fi}
    one {The Wi-Fi is <strong>active</strong>}
    other {All Wi-Fi's are <strong>active</strong>}
  }`,

  // Info
  "dashboard.info.notifications.generic": `{count, plural, =0 {No new notification} one {1 new notification} other {{count} new notifications}}`,
  "dashboard.info.notifications.title": `Notifications`,
  "dashboard.info.notifications.none": `You have no notification`,
  "dashboard.info.notifications.none.period": `You have no notification for this period`,
  "dashboard.info.notifications.none.contentUnread":  `Consult the <strong>Archived</strong> tab (above) to check on past notifications.`,
  "dashboard.info.notifications.atLocation": `at location <strong>{location}</strong>`,
  "dashboard.info.notifications.isOnActualSettings": `This notification is set to OFF in your settings.`,
  "dashboard.info.notifications.recentsExplanation": `This section displays only recent notifications based on your settings. You can consult the <strong>Archived</strong> tab to display all past notifications.`,
  "dashboard.info.notifications.achived.checkbox.uncomplete": `Include all notifications generated regardless of my `,
  "dashboard.info.notifications.unread": `Unread`,
  "dashboard.info.notifications.settings.content": `
    <p>
      By activating an event, this will automatically notify you in the SentinelC application (bell icon on the menu bar).{linebreak}
      You can optionally be notified by email according to your preferences.
    </p>
  `,
  "dashboard.info.notifications.settings.actionRequired": `Action required`,
  "dashboard.info.notifications.settings.actionRequired.subtitle": `Theses events are activated by default.`,
  get "dashboard.info.notifications.settings.information"() {return this["generic.information"]},

  "dashboard.info.notifications.settings.type.NEW_HOST_RESTRICTED_VLAN.title": `Device permission required`,
  "dashboard.info.notifications.settings.type.NEW_HOST_RESTRICTED_VLAN.sublabel": `When a new device connect to a restricted zone`,

  "dashboard.info.notifications.settings.type.DOWNTIME.title": `SentinelC appliance is offline`,
  "dashboard.info.notifications.settings.type.DOWNTIME.sublabel": `Include a notification when back online`,

  "dashboard.info.notifications.settings.type.FW_UPDATE.title": `Scheduled firmware update`,
  "dashboard.info.notifications.settings.type.FW_UPDATE.sublabel": `Inlude a notification after completion`,

  "dashboard.info.notifications.settings.type.NEW_HOST_PRIVATE_VLAN.title": `New device connection on a private zone`,
  "dashboard.info.notifications.settings.type.NEW_HOST_PRIVATE_VLAN.sublabel": `When a device connect for the first time to a private zone`,

  "dashboard.info.notifications.settings.type.NEW_HOST_OTHER_VLAN.title": `New device connection on a public zone`,
  "dashboard.info.notifications.settings.type.NEW_HOST_OTHER_VLAN.sublabel": `When a device connect for the first time to a public zone`,

  "dashboard.info.notifications.settings.type.ROGUE_SSID.title": `«Rogue» Wi-Fi network detection`,
  "dashboard.info.notifications.settings.type.ROGUE_SSID.sublabel": `When a Wi-Fi with the same name as yours is detected`,

  "dashboard.info.notifications.settings.type.BAD_WIRED_CONNECTION.title": `Bad wired connection`,
  "dashboard.info.notifications.settings.type.BAD_WIRED_CONNECTION.sublabel": `When a device/appliance is not connected properly`,

  "dashboard.info.notifications.settings.type.SCHEDULED_IMPORTANT.title": `Important announcements`,
  "dashboard.info.notifications.settings.type.SCHEDULED_IMPORTANT.sublabel": `Alerts and important messages from the support team`,

  "dashboard.info.notifications.settings.type.SCHEDULED_GENERAL.title": `News and general information`,
  "dashboard.info.notifications.settings.type.SCHEDULED_GENERAL.sublabel": `Miscellaneous announcements from the SentinelC team`,

  "dashboard.info.notifications.settings.type.HOST_AUTO_BLOCKED.title": `Device automatically blocked`,
  "dashboard.info.notifications.settings.type.HOST_AUTO_BLOCKED.sublabel": `When a misbehaving device is blocked automatically`,

  "dashboard.info.notifications.settings.type.CONFIGURATION_PROBLEM.title": `Configuration problems`,
  "dashboard.info.notifications.settings.type.CONFIGURATION_PROBLEM.sublabel": `When a configuration problems/errors are detected`,
  "dashboard.info.update.remove": `DISMISS`,
  "dashboard.info.organization.profile": `Account created on {date} by {name}`,

  // 404 Page
  "dashboard.404.title": `404 - Page not found`,
  "dashboard.404.content": `
    <p>
      This page doesn't exist or you don't access to it.
    </p>
    <p>
      Click on the back button on the top of the page to come back to {count, plural, =0 {the homepage} =1 {your SentinelC account} other {your SentinelC accounts}}.
    </p>
  `,

  // /DASHBOARD ======================================


  // PORTAL ==========================================

  "portal.header": `Wireless portal network`,
  "portal.footer": `Network secured by SentinelC`,

  //Home
  "portal.home.guest.title": `Guest`,
  "portal.home.request.error": `An error occured, please try again`,
  "portal.home.guest.content": `
    <p>We are providing a secured guest network, but to insure the security of your data you need a personnal key to connect the secured network.</p>
    <p>With this time limited key you will be able to connect on our secured guest network.</p>
  `,
  "portal.home.employees.content": `
    <p>If you are a registred employee, click on the link below:
      {linebreak}<a href={href}>Connection for employees</a></p>
  `,

  // Get a key
  "portal.getAKey.title": `Get a temporary key`,
  "portal.getAKey.content": `
    <p>Please enter a valid email or phone number, we will send a token to verify your identity.</p>
  `,
  "portal.getAKey.tokenform.content": `
    <p>Enter the token you received to generate your private key.</p>
    <p><em>This key will be available only for this device, you have to do it for each ones you want to connect.</em></p>
  `,

  // Key
  "portal.key.title": `Private key`,
  "portal.key.yourPersonnalKeyIs": `Your personnal key is`,
  "portal.key.content": `
    <p>Copy the key, and reconnect to your Guest Wi-Fi. {linebreak}
    Use the key when your device will ask for.</p>
  `,
  "portal.key.infos": `
    <p>This key will be available only for this device. You have to do it for each ones you want to connect.
    <p>It’s also valid for a 2 hours connection after this delay ask for a new key on this portal (on this network).</p>
  `,

  // /PORTAL =========================================


}
